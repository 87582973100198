import React from 'react';

const Divider = () => {
  return (
    <div className="Divider flex flex-row h-8 pt-6 pb-6 justify-start items-start gap-2.5 ">
      <span className="Line h-0.5 w-full bg-[#F3F5FB]"></span>
    </div>
  );
};

export default Divider;