import React from "react";
import { createRoot } from "react-dom/client";
import { AuthProvider } from "react-auth-kit";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { Provider } from "react-redux";
import store from "store";

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "./context";

import "assets/styles/index.css";
import './index.css';

const container = document.getElementById("root");

const root = createRoot(container);

root.render(
	<AuthProvider
		authType={"cookie"}
		authName={"token"}
		cookieDomain={window.location.hostname}
		cookieSecure={false}
	>
		<Provider store={store}>
		<BrowserRouter basename="/">
			<MaterialUIControllerProvider>
				<App />
			</MaterialUIControllerProvider>
		</BrowserRouter>
		</Provider>
	</AuthProvider>
);

// Hot Module Replacement (HMR) - Remove this snippet to disable HMR
if (module.hot) {
	module.hot.accept(App, () => {
		const NextApp = require(App).default;
		root.render(
			<AuthProvider
				authType={"cookie"}
				authName={"token"}
				cookieDomain={window.location.hostname}
				cookieSecure={false}
			>
				<Provider store={store}>
					<BrowserRouter basename="/">
						<MaterialUIControllerProvider>
							<NextApp />
						</MaterialUIControllerProvider>
					</BrowserRouter>
				</Provider>
			</AuthProvider>
		);
	});
}
