import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ButtonLink from 'components/global/buttonLink';
import { Link } from "react-router-dom";

const Modal = ({ isOpen, onClose }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={(e) => e.target.id === 'modal-background' && onClose()}
      id="modal-background"
    >
      <div className="relative w-full sm:w-[600px] px-6 py-7 flex flex-col items-center gap-4 bg-white rounded-lg shadow-md">
        
        {/* Close Button */}
        <div className="absolute cursor-pointer top-6 right-6" onClick={onClose}>
          <CloseIcon style={{ fontSize: '14px', color: '#283567' }} />
        </div>

        {/* Modal Content */}
        <div className="flex flex-col items-center w-full gap-2">
          <div className="text-[#283567] text-[25px] font-bold leading-loose">Referral Successfully Added</div>
        </div>
        <div className="flex flex-col items-center w-full gap-6">
          <div className="w-full text-[#7e85a3] text-base font-normal leading-relaxed text-center">You can now track the status of this referral under your Active Outbound Referrals.</div>
        </div>
        <div className="flex items-center justify-end w-full gap-4">
          <Link
            to="/referral/active/allactive"
            className="text-[#6471ed] text-sm font-bold leading-tight"
          >
            Outbound Referrals
          </Link>
          <ButtonLink
            text="Add Another"
            href="/add-referral"
            className="text-white text-[14px] font-semibold px-6 py-3 rounded-lg bg-[#434CA0] hover:bg-[#373E82] shadow-md"
          />
        </div>
      </div>
    </div>
  );
};

export default Modal;
