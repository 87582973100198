// react-router-dom components
import { Link } from "react-router-dom";
import { useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Grid from '@mui/material/Grid'
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Autocomplete from "@mui/material/Autocomplete";
import FormHelperText from "@mui/material/FormHelperText";
// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import MDAlert from "components/MDAlert";

// Data
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import AuthService from "../../../../services/auth.service";
import Cookies from "js-cookie";

function AgentHeroSignup() {
	
	const [signupDetails,setDetails] = useState({
		firstname : '',
		lastname : '',
		mobile: '',
		password : '',
		email:'',
		confirmpassword:'',
	});
	const [error, setError] = useState(false);
	const [mobileerror, setmobileError] = useState(false);
	const [passworderror, setPasswordError] = useState(false);
	const [emailerror, setEmailError] = useState(false);
	const [terms, setTerms] = useState(false);
	const [form, setForm] = useState(true);
	const [message, setMessage] = useState("");
	const [AgentheroMilitary, AgentHerosetMilitary] = useState("");	
    const [AgentheroVeterantype, AgentHerosetVeteranType] = useState("");	
    const [AgentheroPCSD, AgentHerosetPCSD] = useState("");	
	const [type, setType] = useState("");
    const [agenthero_data, setAgentHeroData] = useState({});

	const onChangeType = (type) => {
		if (type === "Real Estate Agent") {
			setType("Agent");
		} else if (type === "Real Estate Broker") {
			setType("Broker");
		}else if (type === "Influencer"){
			setType("Influencer");
		}
	}

	const onChangeTypeVeteran = (type) => {
    AgentHerosetVeteranType(type);
	}

	const onChangeTypeBranch = (type) => {
    AgentHerosetMilitary(type);
	}

	const handleTerms = (event) => {
		if(event.target.checked === false){
			setForm(true);
		}
		setTerms(event.target.checked);
		checkform();
	};

	const handlePCSD = (e) => {
		AgentHerosetPCSD(e.target.value);
	}

	const handleChange = (e) =>{
		const {name, value} = e.target;
		setDetails((prev) => {
			return {
				...prev,
				[name]: name === 'email' ? value.toLowerCase() : value
			};
		});
		if(name === 'mobile'){
			const inputPhoneNumber = value;
			let formattedPhoneNumber = inputPhoneNumber.replace(/\D/g, ''); // remove all non-numeric characters
			if (formattedPhoneNumber.length >= 3 && formattedPhoneNumber.length < 6) {
			  formattedPhoneNumber = `(${formattedPhoneNumber.slice(0, 3)}) ${formattedPhoneNumber.slice(3)}`;
			} else if (formattedPhoneNumber.length >= 6) {
			  formattedPhoneNumber = `(${formattedPhoneNumber.slice(0, 3)}) ${formattedPhoneNumber.slice(3, 6)}-${formattedPhoneNumber.slice(6, 10)}`;
			}
			setDetails((prev) => {
				return {...prev,[name]:formattedPhoneNumber}
			})
			const mobileNumberRegex = /^[1-9]\d{9}$/;
			const usmobile = /^(\([0-9]{3}\)\s*|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
			(mobileNumberRegex.test(value) || usmobile.test(value)) ? setmobileError(false): setmobileError(true);
		} else if(name === 'email'){
			const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w)*$/;
			emailRegex.test(value) || value.length ===0 ? setEmailError(false): setEmailError(true);
		} else if(name === 'confirmpassword'){
			(signupDetails.password === value)?setPasswordError(false):setPasswordError(true);
		}
		checkform();
	}

	const checkform =()=>{
		if(!emailerror && !passworderror && !mobileerror && signupDetails.email.length !==0 && signupDetails.mobile.length !==0
        && signupDetails.password.length !==0 && signupDetails.confirmpassword.length !==0){
			setForm(false);
		}
	}

	const handleKeyDown = (e) => {
		if (e.keyCode === 8) {
			const {name} = e.target;
			setDetails((prev) => {
				return {...prev,[name]:''}
			})
		}
	}

	const handleRegister = (e) => {
		e.preventDefault();
		setError(false);
		signupDetails.vendor_type = type;
		signupDetails.mra = terms;
		signupDetails.cell_phone = signupDetails.mobile;
		signupDetails.phone = signupDetails.mobile;
		signupDetails.email = signupDetails.email.toLowerCase();
		signupDetails.agenthero = true;
		agenthero_data.veteran_military_spouse = AgentheroVeterantype;
		agenthero_data.branch_of_military = AgentheroMilitary;
		agenthero_data.pcs = AgentheroPCSD;

		setMessage("");
		AuthService.register(signupDetails).then(
			(response) => {
				const user = response.data.response.user;
					Cookies.set('referral_id', user.session_id);
					Cookies.set('userToken',user.session_id);
					Cookies.set('username', user.username);
					Cookies.set('uuid',user.uuid);
				if (type === "Influencer"){
					window.location.href = "https://boomtownroi.formstack.com/forms/partners_onboarding?field143721390-first="+user.first_name+"&field143721390-last="+user.last_name+"&Email="+user.username+"&GUID="+user.guid;
				}else {
            AuthService.updateCompany(agenthero_data);
					window.location.href = "https://boomtownroi.formstack.com/forms/myagentfinder_account?field134691190-first="+user.first_name+"&field134691190-last="+user.last_name+"&Email="+user.username+"&GUID="+user.guid;
				}
			},(error) => {
				setError(true);
		    const resMessage =error.response.data.response.error
		    setMessage(resMessage);
			}
		);
	};

	return (
		<CoverLayout image="/logo-white-agenthero.png">
			<Card>
				<MDBox
					variant="gradient"
					bgColor="info"
					borderRadius="lg"
					coloredShadow="success"
					mx={2}
					mt={-3}
					p={3}
					mb={1}
					textAlign="center"
					sx={{
						background: `linear-gradient(180deg, #838DF1 0%, #6471ED 100%);`
					}}
				>

					<MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
						Register
					</MDTypography>
					<MDTypography display="block" variant="body2" color="white" my={1}>
						Set up Your MyAgentFinder Agent Account
					</MDTypography>
				</MDBox>
				{error && (<MDAlert color="error" dismissible>{message}</MDAlert>)}
				<MDBox pt={4} pb={3} px={3}>
					<MDBox component="form" role="form">
						<Grid item xs={12}>
							<Grid container spacing={3}>
								<Grid item xs={12} md={12} sm={12}>
									<Autocomplete
										onChange={(event, value) => onChangeType(value)}
										name = "type"
										inputprops={{ 'aria-label': 'controlled' }}
										options={selectData.Agent_type_agenthero}
										renderInput={(params) => (
											<FormField {...params} label="I'm" InputLabelProps={{ shrink: true }} />
										)}
									/>
								</Grid>
							</Grid>
						</Grid>

						<Grid container spacing={3} pt={3}>
							<Grid item xs={12} lg={6} md={6}>
								<MDBox>
									<MDInput type="text" name="firstname" label="First Name" variant="standard" fullWidth  onChange={handleChange} />
								</MDBox>
							</Grid>
							<Grid item xs={12} lg={6} md={6}>
								<MDBox>
									<MDInput type="text" name="lastname" label="Last Name" variant="standard" fullWidth  onChange={handleChange}/>
								</MDBox>
							</Grid>
							<Grid item xs={12} lg={6} md={6}>
								<MDBox>
									<MDInput error={mobileerror} name="mobile" helperText="your mobile number without country code" placeholder="mobile" type="text" label="Cell Phone" variant="standard" value={signupDetails.mobile} fullWidth onChange={handleChange} />
									{mobileerror && <FormHelperText error>Please enter a valid phone number</FormHelperText>}
								</MDBox>
							</Grid>
							<Grid item xs={12} lg={6} md={6}>
								<MDBox>
									<MDInput type="email" error={emailerror} name="email" label="Email Address" variant="standard" fullWidth  onChange={handleChange}/>
									{emailerror && <FormHelperText error>Please enter a valid email address</FormHelperText>}
								</MDBox>
							</Grid>
							<Grid item xs={12} lg={6} md={6}>
								<MDBox>
									<MDInput type="password" name="password" label="Password" variant="standard" fullWidth  onChange={handleChange}/>
								</MDBox>
							</Grid>
							<Grid item xs={12} lg={6} md={6}>
								<MDBox>
									<MDInput type="password" error={passworderror} name = "confirmpassword" label="Confirm Password" variant="standard" fullWidth  onChange={handleChange}/>
									{passworderror && <FormHelperText error>Your passwords have to match</FormHelperText>}
								</MDBox>
							</Grid>
               <Grid item xs={12} lg={6} md={6}>
									<Autocomplete
										onChange={(event, value) => onChangeTypeBranch(value)}
										name = "type"
										inputprops={{ 'aria-label': 'controlled' }}
										options={selectData.Military_branch}
										renderInput={(params) => (
											<FormField {...params} label="Your Military Branch" InputLabelProps={{ shrink: true }} />
										)}
									/>
								</Grid>
								<Grid item xs={12} lg={6} md={6}>
									<Autocomplete
										onChange={(event, value) => onChangeTypeVeteran(value)}
										name = "type"
										inputprops={{ 'aria-label': 'controlled' }}
										options={selectData.Veteran}
										renderInput={(params) => (
											<FormField {...params} label="Are You a Veteran or Spouse" InputLabelProps={{ shrink: true }} />
										)}
									/>
								</Grid>
                <Grid item xs={12} lg={6} md={6}>
								<MDBox>
									<MDInput type="text" name="pcsd" label="How many times have you PCS'd?" variant="standard" fullWidth  onChange={handlePCSD}/>
								</MDBox>
							</Grid>
						</Grid>

						<MDBox alignItems="center" ml={1} mt={4}>
							<Checkbox       
      						onChange={handleTerms}
      						inputProps={{ 'aria-label': 'controlled' }} 
							/>
							<MDTypography
								variant="button"
								fontWeight="regular"
								color="text"
								sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
								display="inline"
							>
								&nbsp;&nbsp;I have read and agree to MyAgentFinder’s&nbsp;
							</MDTypography>
							<MDTypography
								component="a"
								href=""
								variant="button"
								fontWeight="bold"
								color="info"
								textGradient
								display="inline"
							>
								Terms of Service
							</MDTypography>              <MDTypography
								component="a"
								href=""
								variant="button"
								fontWeight="bold"
								color="info"
								textGradient
								display="inline"
							>
								Privacy Policy
							</MDTypography>              <MDTypography
								component="a"
								href=""
								variant="button"
								fontWeight="bold"
								color="info"
								textGradient
								display="inline"
							>
								Consent to the Use of Electronic Communications
							</MDTypography>
						</MDBox>
						<MDBox mt={4} mb={1}>
							<MDButton variant="gradient" color="solidPrimary" disabled={form} fullWidth onClick={handleRegister}>
								Get Started
							</MDButton>
						</MDBox>
						<MDBox mt={3} mb={1} textAlign="center">
							<MDTypography variant="button" color="text">
								Already have an account?{" "}
								<MDTypography
									component={Link}
									to="/"
									variant="button"
									color="info"
									fontWeight="medium"
									textGradient
								>
									Sign In
								</MDTypography>
							</MDTypography>
						</MDBox>
					</MDBox>
				</MDBox>
			</Card>
		</CoverLayout>
	);
}

export default AgentHeroSignup;
