import React from 'react';
import Title from "components/global/title";
import PricingCard from './pricingCard';

const PricingTable = ({ plans, activePlan, activePrice, activeReferralFee, activePlanStatus }) => {

  return (
    <div className="StatisticsCard w-full p-4 bg-white rounded-xl shadow flex-col justify-start items-start gap-2.5 inline-flex">
      <div className="inline-flex items-start self-stretch justify-start Frame71">
        <div className="inline-flex flex-col items-start justify-start gap-2 p-2 Frame45 grow shrink basis-0">
          <div className="Frame89 py-1 justify-start items-start gap-2.5 inline-flex">
            <Title
              text="Our Plans"
              className="text-[16px] sm:text-[25px]"
            />
          </div>
        </div>
      </div>

      <div id="plans" className="Section flex p-[8px] gap-[24px] flex-col lg:gap-[48px] self-stretch">
        <div className="Frame14182 flex w-full max-w-[1140px] flex-col items-center gap-[24px] lg:gap-[48px] self-stretch mx-[auto] my-[0]">
          <div className="flex w-full flex-col lg:flex-row max-w-screen-xl  sm:mx-[auto] sm:my-[0] justify-center items-center gap-[24px]">
            {plans.map((plan, index) => (
              <PricingCard
                key={index}
                {...plan}
                activePlan={activePlan}
                activePrice={activePrice}
                activeReferralFee={activeReferralFee}
                activePlanStatus={activePlanStatus}
                className="flex-[1_0_0]
                min-h-[360px]
                items-center"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingTable;