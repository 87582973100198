// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import Footer from "examples/Footer";

function Contact() {
    return (
        <>
            <Grid container direction="column" style={{ minHeight: '100vh' }}>
                <Grid item xs>
                    <MDBox >
                        <Grid container spacing={3} justifyContent="center">
                            <Grid item xs={12} lg={8} ml={2} mr={3}>
                                <MDTypography align="left" variant="h2" pt={5} pb={4} color="soliddark"><b>Contact Us</b></MDTypography>
                                <Card>
                                    <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                                        <MDTypography variant="h3" color="soliddark">MyAgentFinder Support</MDTypography>
                                    </MDBox>
                                    <Divider orientation="horizontal" sx={{ ml: -2, mr: 1 }} />
                                    <Grid container ml={3}>
                                        <Grid item xs={12} lg={6} mb={3} mt={3}>
                                            <MDTypography variant="h4" color="soliddark">Hours</MDTypography>
                                            <MDTypography variant="subtitle2" color="soliddark">Monday-Friday</MDTypography>
                                            <MDTypography variant="subtitle2" color="soliddark">8:00 AM - 8:00 PM Eastern</MDTypography>
                                            <MDTypography variant="subtitle2" color="soliddark">5:00 AM - 5:00 PM Pacific</MDTypography>
                                        </Grid>
                                        <Grid item xs={12} lg={6} mb={3} mt={3}>
                                            <MDTypography variant="h4" color="soliddark">Contact</MDTypography>
                                            <MDTypography variant="h6" color="soliddark" display="inline">Call: <MDTypography variant="subtitle2" color="solidinfo" display="inline">(855) 908-3506</MDTypography></MDTypography>
                                            <MDTypography variant="h6" color="soliddark">Text: <MDTypography variant="subtitle2" color="solidinfo" display="inline">(619) 314-4053</MDTypography> </MDTypography>
                                            <MDTypography variant="h6" color="soliddark">Email: <MDTypography variant="subtitle2" color="solidinfo" display="inline">referrals@myagentfinder.com</MDTypography></MDTypography>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                            <Grid item xs={12} lg={8} ml={2} mr={3}>
                                <Card>
                                    <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                                        <MDTypography variant="h5" color="soliddark">For any closing-related questions, referral fee payment forms, or MRA please get in touch with us at <br/><br/>escrow@myagentfinder.com</MDTypography>
                                    </MDBox>
                                </Card>
                            </Grid>
                        </Grid>
                    </MDBox>
                </Grid>
                <Grid pb={3} pl={4}item style={{ alignSelf: 'flex-start' }}>
                    <Footer />
                </Grid>
            </Grid>
        </>
    );
}

export default Contact;
