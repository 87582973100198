import { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDAlert from "components/MDAlert";

import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

import AuthService from '../../../services/auth.service';
import { SvgIcon } from "@mui/material";
import { Circles } from "react-loader-spinner";

import Influencer from "./components/Influencer";
import InfluencerWidget from "./components/InfluencerWidget";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';

function Analytics() {
	const [user, setUser] = useState({ agent: { is_influencer: false } });
	const [referrals_accepted, setReferralsAccepted] = useState(0);
	const [sides_closed, setSidesClosed] = useState(0);
	const [days_90_referrals_accepted, set90DaysReferralsAccepted] = useState(0);
	const [days_90_reached_prospect, set90DaysReachedProspect] = useState(0);
	const [referrals_declined, setReferralsDeclined] = useState(0);
	const [status_overdue, setStatusOverdue] = useState(0);
	const [pending, setPending] = useState(0);
	const [high_priority, setPriority] = useState(false);
	const [non_compliant, setNonCompliant] = useState(false);
	const [avg_acceptence, setAvereageAcceptence] = useState(0);
	const [transaction_volume, setTransactionVolume] = useState(0);
	const [loading, setLoading] = useState(true);
	const [count, setCount] = useState(0);
	const [tabValue, setTabValue] = useState(0);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	useEffect(() => {
		AuthService.getUserInfo().then((response) => {
			setUser(response.data.response);
			setLoading(false);
			generateDashboardValues(response.data.response.agent);
		})
	}, [])

	const generateDashboardValues = (agent) => {
		setPriority(agent.high_priority);
		setNonCompliant(agent.non_compliant);
		let notAcceptedOpps = (agent.opportunity.filter((opp) => opp.currentStatus == 'Not Accepted')).length;
		setPending(notAcceptedOpps);
		setTransactionVolume((isNaN(parseInt(agent.total_transaction_volume)) ? 0 : parseInt(agent.total_transaction_volume)) + (isNaN(parseInt(agent.total_team_volume_transaction)) ? 0 : parseInt(agent.total_team_volume_transaction ?? 0)));
		setAvereageAcceptence(parseInt(agent.avg_acceptance_speed ?? 0));
		setReferralsAccepted(parseInt(agent.total_opps ?? 0) + parseInt(agent.team_opps ?? 0));
		setSidesClosed(parseInt(agent.num_sold_opps ?? 0) + parseInt(agent.team_sold_opps ?? 0));
		set90DaysReferralsAccepted(parseInt(agent.last90days ?? 0) + parseInt(agent.team_opps_last_90_days ?? 0));
		// prospects reached in the last 90 days
		(parseInt(agent.last90days ?? 0) + parseInt(agent.team_opps_last_90_days ?? 0)) != 0 ?
			set90DaysReachedProspect(((parseInt(agent.prospect_90_days ?? 0) + parseInt(agent.team_prospects_90_days ?? 0)) / (parseInt(agent.last90days ?? 0) + parseInt(agent.team_opps_last_90_days ?? 0)) * 100).toFixed(2))
			: set90DaysReachedProspect(0);

		//declined referral percentage
		(parseInt(agent.total_opps ?? 0) + parseInt(agent.team_opps ?? 0) + parseInt(agent.declined_opps ?? 0)) != 0 ?
			setReferralsDeclined(((parseInt(agent.declined_opps ?? 0)) / (parseInt(agent.total_opps ?? 0) + parseInt(agent.team_opps ?? 0) + parseInt(agent.declined_opps ?? 0)) * 100).toFixed(2))
			: setReferralsDeclined(0);

		setStatusOverdue(parseInt(agent.overdue_opps ?? 0) + parseInt(agent.team_overdue_opps ?? 0));
	}

	function HomeIcon(props) {
		if (props.icon == 'accepted') {
			return (
				<SvgIcon {...props}>
					<path d="M16.5963 2.9502H8.21625C4.57625 2.9502 2.40625 5.1202 2.40625 8.7602V17.1302C2.40625 20.7802 4.57625 22.9502 8.21625 22.9502H16.5863C20.2263 22.9502 22.3962 20.7802 22.3962 17.1402V8.7602C22.4062 5.1202 20.2363 2.9502 16.5963 2.9502ZM17.1863 10.6502L11.5162 16.3202C11.3762 16.4602 11.1862 16.5402 10.9862 16.5402C10.7862 16.5402 10.5963 16.4602 10.4563 16.3202L7.62625 13.4902C7.33625 13.2002 7.33625 12.7202 7.62625 12.4302C7.91625 12.1402 8.39625 12.1402 8.68625 12.4302L10.9862 14.7302L16.1263 9.5902C16.4163 9.3002 16.8963 9.3002 17.1863 9.5902C17.4763 9.8802 17.4763 10.3502 17.1863 10.6502Z" fill="#375525" />
				</SvgIcon>
			);
		} else if (props.icon == 'minutes') {
			return (
				<SvgIcon {...props}>
					<path d="M12.4063 5.60022C7.62633 5.60022 3.73633 9.49022 3.73633 14.2702C3.73633 19.0502 7.62633 22.9502 12.4063 22.9502C17.1863 22.9502 21.0763 19.0602 21.0763 14.2802C21.0763 9.50022 17.1863 5.60022 12.4063 5.60022ZM13.1563 13.9502C13.1563 14.3602 12.8163 14.7002 12.4063 14.7002C11.9963 14.7002 11.6563 14.3602 11.6563 13.9502V8.95022C11.6563 8.54022 11.9963 8.20022 12.4063 8.20022C12.8163 8.20022 13.1563 8.54022 13.1563 8.95022V13.9502Z" fill="#2E3570" />
					<path d="M15.2959 4.4002H9.5159C9.1159 4.4002 8.7959 4.0802 8.7959 3.6802C8.7959 3.2802 9.1159 2.9502 9.5159 2.9502H15.2959C15.6959 2.9502 16.0159 3.2702 16.0159 3.6702C16.0159 4.0702 15.6959 4.4002 15.2959 4.4002Z" fill="#2E3570" />      </SvgIcon>
			);
		} else if (props.icon == 'prospect') {
			return (
				<SvgIcon {...props}>
					<path d="M5.40625 14.4502C3.19625 14.4502 1.40625 16.2402 1.40625 18.4502C1.40625 19.2002 1.61625 19.9102 1.98625 20.5102C2.67625 21.6702 3.94625 22.4502 5.40625 22.4502C6.86625 22.4502 8.13625 21.6702 8.82625 20.5102C9.19625 19.9102 9.40625 19.2002 9.40625 18.4502C9.40625 16.2402 7.61625 14.4502 5.40625 14.4502ZM7.37625 18.1202L5.24625 20.0902C5.10625 20.2202 4.91625 20.2902 4.73625 20.2902C4.54625 20.2902 4.35625 20.2202 4.20625 20.0702L3.21625 19.0802C2.92625 18.7902 2.92625 18.3102 3.21625 18.0202C3.50625 17.7302 3.98625 17.7302 4.27625 18.0202L4.75625 18.5002L6.35625 17.0202C6.65625 16.7402 7.13625 16.7602 7.41625 17.0602C7.69625 17.3602 7.67625 17.8402 7.37625 18.1202Z" fill="#604F6B" />
					<path d="M17.6562 3.38025H8.15625C5.30625 3.38025 3.40625 5.28025 3.40625 8.13025V12.5902C3.40625 12.9402 3.76625 13.1902 4.10625 13.1002C4.52625 13.0002 4.95625 12.9502 5.40625 12.9502C8.26625 12.9502 10.6262 15.2702 10.8862 18.0802C10.9062 18.3602 11.1362 18.5802 11.4062 18.5802H11.9563L16.1862 21.4002C16.8062 21.8202 17.6562 21.3602 17.6562 20.6002V18.5802C19.0762 18.5802 20.2663 18.1002 21.0963 17.2802C21.9263 16.4402 22.4062 15.2502 22.4062 13.8302V8.13025C22.4062 5.28025 20.5062 3.38025 17.6562 3.38025ZM16.2362 11.7602H9.57625C9.18625 11.7602 8.86625 11.4402 8.86625 11.0502C8.86625 10.6502 9.18625 10.3302 9.57625 10.3302H16.2362C16.6262 10.3302 16.9463 10.6502 16.9463 11.0502C16.9463 11.4402 16.6262 11.7602 16.2362 11.7602Z" fill="#604F6B" />				</SvgIcon>
			);
		} else if (props.icon == 'declined') {
			return (
				<SvgIcon {...props}>
					<path d="M16.5963 2.9502H8.21625C4.57625 2.9502 2.40625 5.1202 2.40625 8.7602V17.1302C2.40625 20.7802 4.57625 22.9502 8.21625 22.9502H16.5863C20.2263 22.9502 22.3962 20.7802 22.3962 17.1402V8.7602C22.4062 5.1202 20.2363 2.9502 16.5963 2.9502ZM15.7662 15.2502C16.0562 15.5402 16.0562 16.0202 15.7662 16.3102C15.6162 16.4602 15.4262 16.5302 15.2362 16.5302C15.0462 16.5302 14.8563 16.4602 14.7063 16.3102L12.4062 14.0102L10.1062 16.3102C9.95625 16.4602 9.76625 16.5302 9.57625 16.5302C9.38625 16.5302 9.19625 16.4602 9.04625 16.3102C8.75625 16.0202 8.75625 15.5402 9.04625 15.2502L11.3462 12.9502L9.04625 10.6502C8.75625 10.3602 8.75625 9.8802 9.04625 9.5902C9.33625 9.3002 9.81625 9.3002 10.1062 9.5902L12.4062 11.8902L14.7063 9.5902C14.9963 9.3002 15.4762 9.3002 15.7662 9.5902C16.0562 9.8802 16.0562 10.3602 15.7662 10.6502L13.4663 12.9502L15.7662 15.2502Z" fill="#6B4A5C" />
				</SvgIcon>
			);
		}
		else if (props.icon == 'overdue') {
			return (
				<SvgIcon {...props}>
					<path d="M19.9162 6.8002L13.9762 3.3702C13.0062 2.8102 11.8062 2.8102 10.8262 3.3702L4.89617 6.8002C3.92617 7.3602 3.32617 8.4002 3.32617 9.5302V16.3702C3.32617 17.4902 3.92617 18.5302 4.89617 19.1002L10.8362 22.5302C11.8062 23.0902 13.0062 23.0902 13.9862 22.5302L19.9262 19.1002C20.8962 18.5402 21.4962 17.5002 21.4962 16.3702V9.5302C21.4862 8.4002 20.8862 7.3702 19.9162 6.8002ZM11.6562 8.7002C11.6562 8.2902 11.9962 7.9502 12.4062 7.9502C12.8162 7.9502 13.1562 8.2902 13.1562 8.7002V13.9502C13.1562 14.3602 12.8162 14.7002 12.4062 14.7002C11.9962 14.7002 11.6562 14.3602 11.6562 13.9502V8.7002ZM13.3262 17.5802C13.2762 17.7002 13.2062 17.8102 13.1162 17.9102C12.9262 18.1002 12.6762 18.2002 12.4062 18.2002C12.2762 18.2002 12.1462 18.1702 12.0262 18.1202C11.8962 18.0702 11.7962 18.0002 11.6962 17.9102C11.6062 17.8102 11.5362 17.7002 11.4762 17.5802C11.4262 17.4602 11.4062 17.3302 11.4062 17.2002C11.4062 16.9402 11.5062 16.6802 11.6962 16.4902C11.7962 16.4002 11.8962 16.3302 12.0262 16.2802C12.3962 16.1202 12.8362 16.2102 13.1162 16.4902C13.2062 16.5902 13.2762 16.6902 13.3262 16.8202C13.3762 16.9402 13.4062 17.0702 13.4062 17.2002C13.4062 17.3302 13.3762 17.4602 13.3262 17.5802Z" fill="#6F331B" />
				</SvgIcon>
			);
		}
		else {
			return (
				<SvgIcon {...props}>
					<path d="M16.5963 2.9502H8.21625C4.57625 2.9502 2.40625 5.1202 2.40625 8.7602V17.1302C2.40625 20.7802 4.57625 22.9502 8.21625 22.9502H16.5863C20.2263 22.9502 22.3962 20.7802 22.3962 17.1402V8.7602C22.4062 5.1202 20.2363 2.9502 16.5963 2.9502ZM17.1863 10.6502L11.5162 16.3202C11.3762 16.4602 11.1862 16.5402 10.9862 16.5402C10.7862 16.5402 10.5963 16.4602 10.4563 16.3202L7.62625 13.4902C7.33625 13.2002 7.33625 12.7202 7.62625 12.4302C7.91625 12.1402 8.39625 12.1402 8.68625 12.4302L10.9862 14.7302L16.1263 9.5902C16.4163 9.3002 16.8963 9.3002 17.1863 9.5902C17.4763 9.8802 17.4763 10.3502 17.1863 10.6502Z" fill="#375525" />
				</SvgIcon>
			);
		}

	}

	function handleInfluncer() {
		let count_increase = count + 1;
		setCount(count_increase);
		user.agent.is_influencer = !user.agent.is_influencer;
		tabValue == 0 ? setTabValue(1) : setTabValue(0);
		setUser(user);
	}

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox >
				{(user.agent.merged_influencer_agent) ?
					<MDBox pb={2} sx={{ maxWidth: isMobile ? "100%" : "30%" }}>
						<AppBar position="static">
							<Tabs orientation={isMobile ? "vertical" : "horizontal"} value={tabValue} onChange={handleInfluncer}>
								<Tab label="Influencer View" />
								<Tab label="Agent View" />
							</Tabs>
						</AppBar>
						<MDTypography variant="overline" pr={2} sx={{ display: "none" }}>{count}</MDTypography>
					</MDBox> : <></>
				}
				<Grid container pb={4}>
					<MDTypography variant="h1" color="soliddark" lineHeight={1.25} fontSize={"2.5rem"}>
						Dashboard : {user.agent.is_influencer ? (user.agent.is_sales_split ? 'Split-Revenue Partner' : 'Cost-Per-Lead Partner') : user.agent.agent_type}
					</MDTypography>
				</Grid>
				{pending >= 1 ?
					<MDAlert color="gradientPeach">
						<HomeIcon icon="overdue" /> &nbsp;
						<MDTypography variant="body2" color="gradientPeachDark">
							You have &nbsp;
							<Link to={"/referral/active/pending"} style={{ color: '#6F331B' }}>
								<b>{pending} Referral Pending !</b>
							</Link>
						</MDTypography>
					</MDAlert> : <></>}
				{high_priority == true ?
					<MDAlert color="gradientPeach">
						<HomeIcon icon="overdue" /> &nbsp;
						<MDTypography variant="body2" color="gradientPeachDark">
							At least 1 referral overdue past 30 days
						</MDTypography>
					</MDAlert> : <></>
				}
				{non_compliant == true ?
					<MDAlert color="gradientPeach">
						<HomeIcon icon="overdue" /> &nbsp;
						<MDTypography variant="body2" color="gradientPeachDark">
							Account on hold due to non-payment.
						</MDTypography>
					</MDAlert> : <></>
				}
				{status_overdue > 1 ?
					<MDAlert color="gradientPeach">
						<HomeIcon icon="overdue" /> &nbsp;
						<MDTypography variant="body2" color="gradientPeachDark">
							You have &nbsp;
							<Link to={"/referral/active/overdue"} style={{ color: '#6F331B' }}>
								<b>{status_overdue} Referral requiring immediate Status Update !</b>
							</Link>
						</MDTypography>
					</MDAlert> : <></>}
				{loading ?
					<>
						<MDBox pb={3} sx={{ margin: "auto" }}>
							<Circles
								height="80"
								width="80"
								color="#434ca0"
								ariaLabel="circles-loading"
								wrapperStyle={{}}
								wrapperClass=""
								visible={true}
							/>
						</MDBox>
					</>
					:
					<div>
						{user.agent.is_influencer ?
							// influencer dashboard
							user.agent.monetization_tool == "Widget" ? <InfluencerWidget/>: 
							<Influencer /> :
							<div data-cy="view-dashboard">
								<Grid>
									<Grid item xs={12} md={6} lg={6}>
										<MDTypography variant="h2" color="soliddark" lineHeight={1.25} fontSize={"2rem"} mb={1}>
											Referral KPIs
										</MDTypography>
										<MDTypography variant="body1" fontSize={"1rem"} color="lightneutrals700">
											The most successful agents in our network accept referrals in a timely fashion, contact leads immediately, and keep us informed of their status along the way.
										</MDTypography>
									</Grid>
								</Grid>
								<MDBox mt={3} mb={3}>
									<Grid container spacing={3} >
										<Grid item xs={12} md={6} lg={6} xxl={4}>
											<MDBox>
												<MiniStatisticsCard
													title={{ text: "Referrals Accepted (90 days)" }}
													count={{ text: days_90_referrals_accepted ? days_90_referrals_accepted : 0 }}
													percentage={{ color: "white", text: "Goal", othertext: "0", othertextcolor: "white" }}
													icon={{ color: "gradientgreen", component: "accepted" }}
												/>
											</MDBox>
										</Grid>
										{/* <Grid item xs={12} md={6} lg={6} xxl={4}>
											<MDBox>
												<MiniStatisticsCard
													title={{ text: "Average Minutes to Accept (90 days)" }}
													count={{ text: avg_acceptence }}
													percentage={{ color: "solidsuccess", text: "Goal", othertext: "<30 mins", othertextcolor: "solidtext" }}
													icon={{ color: "gradientIris", component: "minutes" }}
												/>
											</MDBox>
										</Grid>             */}
										<Grid item xs={12} md={6} lg={6} xxl={4}>
											<MDBox>
												<MiniStatisticsCard
													title={{ text: "Reached Prospect (90 days)" }}
													count={{ text: (isNaN(days_90_reached_prospect) ? 0 : days_90_reached_prospect) + "%" }}
													percentage={{ color: "solidsuccess", text: "Goal", othertext: ">50%", othertextcolor: "solidtext" }}
													icon={{ color: "gradientViolet", component: "prospect" }}
												/>
											</MDBox>
										</Grid>            <Grid item xs={12} md={6} lg={6} xxl={4}>
											<MDBox>
												<MiniStatisticsCard
													title={{ text: "Referrals Declined (90 Days)" }}
													count={{ text: (isNaN(referrals_declined) ? 0 : referrals_declined) + "%" }}
													percentage={{ color: "solidsuccess", text: "Goal", othertext: "<20%", othertextcolor: "solidtext" }}
													icon={{ color: "gradientRose", component: "declined" }}
												/>
											</MDBox>
										</Grid>            <Grid item xs={12} md={6} lg={6} xxl={4}>
											<MDBox>
												<MiniStatisticsCard
													title={{ text: "Status Updates Overdue" }}
													count={{ text: status_overdue ? status_overdue : 0 }}
													percentage={{ color: "soliderror", text: "Goal", othertext: "0", othertextcolor: "solidtext" }}
													icon={{ color: "gradientPeach", component: "overdue" }}
												/>
											</MDBox>
										</Grid>
									</Grid>
								</MDBox>

								<Grid >
									<Grid item xs={12} md={6} lg={6}>
										<MDBox sx={{ display: "flex", alignItems: "center", flexDirection: "row", columnGap: "10px" }}>
											<MDTypography variant="h2" color="soliddark" lineHeight={1.25} fontSize={"2rem"} display="inline" mb={1} spr={2}>
												Sales History
											</MDTypography>
											<MDBox
												variant="gradient"
												borderRadius="lg"
												opacity={1}
												bgColor="gradientIris"
												pt={1}
												pb={1}
												pl={1.3}
												pr={1.3}
												display="flex"
											>
												<MDTypography variant="button" color="solidPrimary">All-Time</MDTypography>
											</MDBox>
										</MDBox>
										<MDTypography variant="body1" fontSize={"1rem"} color="lightneutrals700">
											Higher conversion on the referrals we send means happier customers and more future opportunities for you.        </MDTypography>
									</Grid>
								</Grid>

								<MDBox mt={3}>
									<Grid container spacing={3}>
										<Grid item xs={12} md={6} lg={6} xl={4}>
											<MDBox mb={3}>
												<MiniStatisticsCard
													title={{ text: "Referrals Accepted" }}
													count={{ text: referrals_accepted ? referrals_accepted : 0 }}
													percentage={{ color: "white", text: "Goal", othertext: "0", othertextcolor: "white" }}
													icon={{ color: "gradientgreen", component: "accepted" }}
												/>
											</MDBox>
										</Grid>
										<Grid item xs={12} md={6} lg={6} xl={4}>
											<MDBox mb={3}>
												<MiniStatisticsCard
													title={{ text: "Sides Closed" }}
													count={{ text: sides_closed ? sides_closed : 0 }}
													percentage={{ color: "white", text: "Goal", othertext: "<30 mins", othertextcolor: "white" }}
													icon={{ color: "gradientViolet", component: "sidesclosed" }}
												/>
											</MDBox>
										</Grid>
										<Grid item xs={12} md={6} lg={6} xl={4}>
											<MDBox mb={3}>
												<MiniStatisticsCard
													title={{ text: "Transaction Volume" }}
													count={{ text: transaction_volume }}
													percentage={{ color: "white", text: ".", othertext: ".", othertextcolor: "white" }}
													icon={{ color: "gradientViolet", component: "sidesclosed" }}
												/>
											</MDBox>
										</Grid>
									</Grid>
								</MDBox>
							</div>}
					</div>
				}
			</MDBox>
			<Footer />
		</DashboardLayout>
	);
}

export default Analytics;
