// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ColorLibrary from "assets/images/ColorLibrary.png";

function BrandToolKit() {
    return (
        <DashboardLayout>
        <DashboardNavbar />
            <Grid container  direction="column">
                <Grid item xs>
                    <MDBox >
                        <Grid container spacing={3} justifyContent="center">
                            <Grid item xs={12} lg={8}>
                                <MDTypography align="left" variant="h2" pt={5} pb={4} color="soliddark"><b>Brand ToolKit</b></MDTypography>
                                <MDBox bgColor="white" p={3} sx={{ borderRadius: "12px" }}>
                                    <MDBox display="flex" justifyContent="space-between" alignItems="center">
                                        <MDTypography variant="h4" color="soliddark">Logos </MDTypography>
                                    </MDBox>
                                    <MDTypography variant="subtitle2" color="soliddark">If you have a need to use a Home & Money logo in any partnership marketing, please contact Zach Spanton at zspanton@insiderealestate.com for files and usage guidance. </MDTypography>
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} lg={8}>
                                <MDBox bgColor="white" p={3} sx={{ borderRadius: "12px" }}>
                                    <MDBox display="flex" justifyContent="space-between" alignItems="center">
                                        <MDTypography variant="h4" color="soliddark">Colors </MDTypography>
                                    </MDBox>
                                    <MDTypography pb={1} variant="subtitle2" color="soliddark">The Home & Money color palette is extensive. Therefore, careful consideration is required when utilizing the full palette in order maintain visual consistency across the brand’s touch points.</MDTypography>
                                    <MDBox component="img" src={ColorLibrary} alt="color palete" borderRadius="lg" width="auto" />
                                    <MDTypography variant="subtitle2" color="soliddark">The primary colors used for elements like calls to action, headings, and body copy are listed below for your use. If you have a need for accent colors, please contact Zach Spanton at zspanton@insiderealestate.com for guidance.</MDTypography>
                                    <Divider orientation="horizontal" sx={{ ml: -2, mr: 1 }} />
                                    <Grid container spacing={3} justifyContent="center">
                                        <Grid item xs={12} lg={6}>
                                        <MDBox color="black"  align="center"  bgColor="solidPrimary" coloredShadow="none" borderRadius="lg" opacity={1} pl={1.3} pr={1.3} ml={1} sx={{ minHeight: "121px" }}>
                                            <MDBox p={6}>
                                                <MDTypography variant="h4" fontWeight = "bold" color="white" sx={{ fontWeight: "500" }}>Iris</MDTypography>
                                            </MDBox>
                                        </MDBox>
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <MDTypography variant="Body2" fontWeight = "bold" color="solidtext">Uses: </MDTypography><br/>
                                            <MDTypography variant="Body2" color="solidtext">Primary CTA buttons and subheads </MDTypography><br/>
                                            <MDTypography variant="Body2" fontWeight = "bold" color="solidtext">Hex value: </MDTypography><br/>
                                            <MDTypography variant="Body2" color="solidtext">#434CA0 </MDTypography>
                                        </Grid>
                                    </Grid>
                                    <Divider orientation="horizontal" sx={{ ml: -2, mr: 1 }} />
                                    <Grid container spacing={3} justifyContent="center">
                                        <Grid item xs={12} lg={6}>
                                        <MDBox color="black"  align="center"  bgColor="soliddark" coloredShadow="none" borderRadius="lg" opacity={1} pl={1.3} pr={1.3} ml={1} sx={{ minHeight: "121px" }}>
                                            <MDBox p={6}>
                                                <MDTypography variant="h4" fontWeight = "bold" color="white" sx={{ fontWeight: "500" }}>Navy</MDTypography>
                                            </MDBox>
                                        </MDBox>
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <MDTypography variant="Body2" fontWeight = "bold" color="solidtext">Uses: </MDTypography><br/>
                                            <MDTypography variant="Body2" color="solidtext">Headlines and background colors</MDTypography><br/>
                                            <MDTypography variant="Body2" fontWeight = "bold" color="solidtext">Hex value: </MDTypography><br/>
                                            <MDTypography variant="Body2" color="solidtext">#2a2f64 </MDTypography>
                                        </Grid>
                                    </Grid>
                                    <Divider orientation="horizontal" sx={{ ml: -2, mr: 1 }} />
                                    <Grid container spacing={3} justifyContent="center">
                                        <Grid item xs={12} lg={6}>
                                        <MDBox color="black"  align="center"  bgColor="neutralToolkit" coloredShadow="none" borderRadius="lg" opacity={1} pl={1.3} pr={1.3} ml={1} sx={{ minHeight: "121px" }}>
                                            <MDBox p={6}>
                                                <MDTypography variant="h4" fontWeight = "bold" color="white" sx={{ fontWeight: "500" }}>Neutral</MDTypography>
                                            </MDBox>
                                        </MDBox>
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <MDTypography variant="Body2" fontWeight = "bold" color="solidtext">Uses: </MDTypography><br/>
                                            <MDTypography variant="Body2" color="solidtext">Body text color</MDTypography><br/>
                                            <MDTypography variant="Body2" fontWeight = "bold" color="solidtext">Hex value: </MDTypography><br/>
                                            <MDTypography variant="Body2" color="solidtext">#667085 </MDTypography>
                                        </Grid>
                                    </Grid>
                                </MDBox>
                            </Grid>
                        </Grid>
                    </MDBox>
                </Grid>
            </Grid>
            <MDBox pt={2} pb={2}>
                <Footer />
            </MDBox>
            
        </DashboardLayout>
    );
}

export default BrandToolKit;
