import React from 'react';
import PropTypes from 'prop-types';

const ButtonLink = ({ href, text, className, style, scrollTo, children, onClick }) => {

  const handleClick = (e) => {
    
    if (onClick) {
      onClick(e);
    }
    if (scrollTo) {
      e.preventDefault();
      document.getElementById(scrollTo).scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <a
      href={href}
      onClick={handleClick}
      style={{ ...style }}
      className={`flex justify-center items-center gap-[8px] self-stretch ${className}`}
    >
      {text}
      {children}
    </a>
  );
};

ButtonLink.propTypes = {
  href: PropTypes.string,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  scrollTo: PropTypes.string,
  icon: PropTypes.bool,
  onClick: PropTypes.func,
};


export default ButtonLink;
