import React from 'react';

const CheckboxField = ({ label, name, checked, onChange }) => {
  return (
    <div className="inline-flex items-start self-stretch justify-start gap-3 CheckboxItem">
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        className="w-[18px] h-[18px] border border-[#7e85a3] rounded bg-white"
      />
      <label className="LabelText grow shrink basis-0 text-[#7e85a3] text-sm font-normal leading-tight">
        {label}
      </label>
    </div>
  );
};

export default CheckboxField;
