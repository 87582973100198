import React from 'react';
import { FormControl, FormControlLabel, RadioGroup, Radio } from '@mui/material';

const Buy = ({ formData, handleChange, errors }) => (
  <div className="flex flex-col space-y-2">
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="buyingAndSelling"
        name="buyingAndSelling"
        value={formData.buyingAndSelling}
        onChange={handleChange}
      >
        <FormControlLabel
          value={'true'}
          control={<Radio />}
          label={<div className="text-[#7E85A3] text-[14px] not-italic font-normal leading-[20px]">Yes</div>}
        />
        <FormControlLabel
          value={'false'}
          control={<Radio />}
          label={<div className="text-[#7E85A3] text-[14px] not-italic font-normal leading-[20px]">No</div>}
        />
      </RadioGroup>
    </FormControl>
    {errors.buyingAndSelling && (
      <div className="mt-1 text-sm text-red-500">{errors.buyingAndSelling}</div>
    )}
  </div>
);

export default Buy;
