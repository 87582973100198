import React, { useEffect, useRef, useState } from 'react';
import { TextField } from '@mui/material';
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';

const AddressInput = ({ handleChange, userType, errors }) => {
  const usAddressRef = useRef(null);
  const caAddressRef = useRef(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [country, setCountry] = useState('us');
  const [autocompleteInstance, setAutocompleteInstance] = useState(null);

  useEffect(() => {
    if (!window.google) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA1RQD6W3h9GDnt1UYdX9dKO0oO3L9J4Qc&libraries=places&callback=gmNoop`;
      script.async = true;
      script.defer = true;
      script.onload = () => setScriptLoaded(true);
      document.head.appendChild(script);
    } else {
      setScriptLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (scriptLoaded) {
      let currentRef = country === 'us' ? usAddressRef.current : caAddressRef.current;
      if (autocompleteInstance) {
        autocompleteInstance.unbindAll();
      }

      const autocomplete = new window.google.maps.places.Autocomplete(currentRef, {
        types: userType === "buyer"
          ? ['postal_code', 'neighborhood', 'locality', 'administrative_area_level_2', 'administrative_area_level_3']
          : ['geocode'],
        componentRestrictions: { country: country },
      });

      setAutocompleteInstance(autocomplete);

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        const addressData = extractAddressData(place);
        handleChange({
          target: {
            name: 'addressFields',
            value: addressData,
          },
        });
      });
    }
  }, [scriptLoaded, country, userType]);

  const extractAddressData = (place) => {
    const addressComponents = place.address_components || [];
    const formattedAddress = place.formatted_address || '';

    const addressData = {
      address: formattedAddress,
      street: '',
      neighborhood: '',
      city: '',
      state: '',
      postalcode: '',
      county: '',
    };

    addressComponents.forEach((component) => {
      const types = component.types;
      if (types.includes('street_number') || types.includes('route')) {
        addressData.street += component.long_name + ' ';
      }
      if (types.includes('locality')) {
        addressData.city = component.long_name;
      }
      if (types.includes('administrative_area_level_1')) {
        addressData.state = component.short_name;
      }
      if (types.includes('postal_code')) {
        addressData.postalcode = component.long_name;
      }
      if (types.includes('neighborhood')) {
        addressData.neighborhood = component.long_name;
      }
      if (types.includes('administrative_area_level_2')) {
        addressData.county = component.long_name;
      }
    });

    return addressData;
  };

  return (
    <>
       <div className="Frame158 flex px-[8px] py-0 flex-col items-start gap-[12px] self-stretch">
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="country"
            name="country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          >
            <FormControlLabel
              value='us'
              control={<Radio />}
              label={<div className="text-[#7E85A3] text-[14px] not-italic font-normal leading-[20px]">United States address</div>}
            />
            <FormControlLabel
              value='ca'
              control={<Radio />}
              label={<div className="text-[#7E85A3] text-[14px] not-italic font-normal leading-[20px]">Canadian address</div>}
            />
          </RadioGroup>
        </FormControl>
      </div>
    <div className="flex flex-col items-start justify-start p-2 Col grow shrink basis-0">
   

      <form className="flex flex-col w-full" noValidate>
        {country === 'us' && (
          <TextField
            name="usAddress"
            fullWidth
            label={userType === "buyer" ? "Zip, Neighborhood, or City" : "Property Address"}
            inputProps={{
              ref: usAddressRef,
              type: "text",
              autoComplete: 'new-password',
            }}
            onChange={handleChange}
            autoComplete="new-password"
          />
        )}

        {country === 'ca' && (
          <TextField
            name="caAddress"
            fullWidth
            label={userType === "buyer" ? "Postal Code, Neighborhood, or City" : "Property Address"}
            inputProps={{
              ref: caAddressRef,
              type: "text",
              autoComplete: 'new-password',
            }}
            onChange={handleChange}
            autoComplete="new-password"
          />
        )}

        {errors.address && (
          <div className="mt-1 text-sm text-red-500">{errors.address}</div>
        )}
      </form>
    </div>
    </>
  );
};

export default AddressInput;
