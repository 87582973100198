// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";


function Overview() {

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
