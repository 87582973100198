import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDDatePicker from "components/MDDatePicker";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
import AuthService from '../../../services/auth.service';
import Card from "@mui/material/Card";
import { useParams } from "react-router-dom";
import TimelineItem from "examples/Timeline/TimelineItem";
import default_data from "values/default";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import { SvgIcon } from "@mui/material";
import MDBadgeDot from "components/MDBadgeDot";
import MDAlert from "components/MDAlert";

import { useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';

function Widgets({ userdata }) {
	const [notes, setNotes] = useState([]);
	const { id } = useParams();
	const [oppData, setOppData] = useState({});
	const [notestimeline, setnotestimeline] = useState('');
	const [details, setDetails] = useState("");
	const [status, setStatus] = useState("");
	const [history, setHistory] = useState('');
	const [error, setError] = useState(true);
	const [selectedValue, setSelectedValue] = useState("");
	const [buyerseller, setBuyerSeller] = useState("");
	const [update, setUpdate] = useState(false);
	const [nextStatusUpdate, setNextStatusUpdate] = useState('');
	const [nextStatusUpdateFormatted, setNextStatusUpdateFormatted] = useState('');
	const [coeDate, setCoeDate] = useState('');
	const [closingDate, setClosingDate] = useState('');
	const [closingDateFormatted, setClosingDateFormatted] = useState('');
	const [coeDateFormatted, setCoeDateFormatted] = useState('');
	const [salePrice, setSalePrice] = useState(0);
	const [purchasePrice, setPurchasePrice] = useState('');
	const [street, setStreet] = useState('');
	const [city, setCity] = useState('');
	const [state, setState] = useState('');
	const [zip, setZip] = useState('');
	const [location, setLocation] = useState('');
	const [opp_agent, setOppAgent] = useState('');
	const [agentUpdate, setAgentUpdate] = useState(false);

	const [user, setUser] = useState({});

	const [Agents, setAgents] = useState([]);
	const [teamMembers, setTeamMembers] = useState([]);
	const [hasteam, setHasTeam] = useState(false);

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	useEffect(() => {
		if (Object.keys(userdata).length != 0) {
			const user = userdata;
			setUser(user);
			let opps = userdata.agent.opportunity;
			let team_opp = userdata.team.data;
			//check if the user has any team opportunities
			if (team_opp) {
				Object.values(team_opp).map((opp) => opp.map((opp_id) => opps = [...opps, opp_id]));
			}
			let result = opps.filter((item) => item.id === id);
			setOppData(result[0]);
			let info = result[0];
			setLocation((info.propertyStreet || '') + "," + (info.propertyCity || '') + "," + (info.propertyState || '') + "," + (info.propertyZip || ''));
			setBuyerSeller(result[0].buyerSeller);
			if (Object.keys(user.team).length != 0) {
				getTeamMembers(user, info);
				setHasTeam(true);
			} else {
				setOppAgent({
					name: info.assignedAgentName
				})
			}
		}
	}, [userdata])

	function getTeamMembers(userInformation, info) {
		const team = userInformation.team.members.map(({ id, name, sfid }) => ({ id, name, sfid }));
		team.push({ "id": userInformation.agent.id, "name": userInformation.agent.firstname + " " + userInformation.agent.lastname, "sfid": userInformation.agent.sfid });
		setTeamMembers(team);
		const agentValues = userInformation.team.members.map(({ id, name }) => ({ id, name }));
		agentValues.push({ "id": userInformation.agent.id, "name": userInformation.agent.firstname + " " + userInformation.agent.lastname });
		agentValues.push({ "id": 0, "name": "All Agents" });
		setAgents(agentValues);
		setOppAgent(team.filter((member) => member.id == info.assignedagentuuid)[0]);
	}

	function AssignOpp(newagent) {
		AuthService.assignOppUpdate(newagent.sfid, oppData.id);
		setAgentUpdate(true);
	}
	function getOptionLabel(option) {
		if (option.length == 0) {
			return opp_agent.name;
		}
		if (option && option.name) {
			return option.name;
		} else {
			let info = teamMembers.filter((team) => team.name == option);
			return info ?? info[0].name;
		}
	}
	const isOptionEqualToValue = (option, value) => {
		return option.name === value || option.id === value.id;
	};

	const onChangeDetails = (e) => {
		setDetails(e.target.value.trim());
		setError(!((status.length > 0) && (e.target.value.trim().length > 0)));
	}

	const onChangeAdditionalDetails = (e) => {
		if (e.target.name == 'sale_price') {
			setSalePrice(e.target.value.trim().replace(/\$|,/g, ''));
		}
		if (e.target.name == 'purchase_price') {
			setPurchasePrice(e.target.value.trim().replace(/\$|,/g, ''));
		}
		if (e.target.name == 'property_street') {
			setStreet(e.target.value.trim());
		}
		if (e.target.name == 'property_city') {
			setCity(e.target.value.trim());
		}
		if (e.target.name == 'property_state') {
			setState(e.target.value.trim());
		}
		if (e.target.name == 'property_zip') {
			setZip(e.target.value.trim());
		}
	}

	const onChangeStatus = (status) => {
		setNextStatusUpdateFormatted('');
		setCoeDateFormatted('');
		setSelectedValue(status);
		setStatus(status);
		setError(!((status.length > 0) && (details.length > 0)));
	}

	const getTodayDate = () => {
		const now = new Date();
		const year = now.getFullYear();
		const month = String(now.getMonth() + 1).padStart(2, '0');
		const day = String(now.getDate()).padStart(2, '0');
		return `${year}-${month}-${day}`;
	};

	const getTodayDateFormatted = () => {
		const now = new Date();
		const year = now.getFullYear();
		const month = String(now.getMonth() + 1).padStart(2, '0');
		const day = String(now.getDate()).padStart(2, '0');
		return `${month}/${day}/${year}`;
	};

	const handleDate = (e) => {
		const date = new Date(e);
		const year = date.getFullYear();
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		const day = ('0' + date.getDate()).slice(-2);
		const formattedDate = `${year}-${month}-${day}`;
		setNextStatusUpdate(formattedDate);
		const updateDate = `${month}/${day}/${year}`;
		setNextStatusUpdateFormatted(updateDate);
	}

	const handleCoeDate = (e) => {
		const date = new Date(e);
		const year = date.getFullYear();
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		const day = ('0' + date.getDate()).slice(-2);
		const formattedDate = `${year}-${month}-${day}`;
		setCoeDate(formattedDate);
		const updateDate = `${month}/${day}/${year}`;
		setCoeDateFormatted(updateDate);
	}

	const handleClosingDate = (e) => {
		const date = new Date(e);
		const year = date.getFullYear();
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		const day = ('0' + date.getDate()).slice(-2);
		const formattedDate = `${year}-${month}-${day}`;
		setClosingDate(formattedDate);
		const updateDate = `${month}/${day}/${year}`;
		setClosingDateFormatted(updateDate);
	}

	const updateHistory = (notes) => {
		let itemList = '';
		if (notes.length) {
			itemList = notes.map((item, index) => {
				let icon_user = item.stage ? default_data.icons[0][item.stage] : { "color": "primary", "icon": "supportagent" };
				return <TimelineItem
					key={index}
					color={icon_user.color}
					icon={icon_user.icon}
					title={item.title}
					lastItem={(index == (notes.length - 1)) ? true : false}
					dateTime={item.date}
					description={item.body}
				/>
			});
		}
		setnotestimeline(itemList);
	}

	const handleUpdate = (e) => {
		e.preventDefault();
		let oppData = {};
		let stageName = status.split('-')[0].trim();
		let stage = status.split('-')[1].trim();
		oppData.currentStatusNote = details;
		oppData.currentStatus = stage;
		oppData.id = id;
		oppData.stageName = stageName;
		oppData.mls = null;
		oppData.setUponMLSSearch = false;

		if (stageName == "Nurture" && stage == "I'm nurturing this client (long term)") {
			oppData.nextStatusUpdateDate = nextStatusUpdateFormatted.length > 0 ? nextStatusUpdateFormatted : getTodayDateFormatted();
		}
		if (stageName == 'Closed Escrow' && stage == "Sold! I have closed escrow with this Client") {
			oppData.closeOfEscrow = coeDateFormatted.length > 0 ? coeDateFormatted : getTodayDateFormatted();
			oppData.salesPrice = salePrice;
		}
		if (stageName == 'In Escrow' && stage == "I am in escrow with this Client") {
			oppData.purchasePrice = purchasePrice;
			oppData.property_address = street;
			oppData.property_city = city;
			oppData.property_state = state;
			oppData.property_zip = zip;
			oppData.tentativeClosing = closingDateFormatted.length > 0 ? closingDateFormatted : getTodayDateFormatted();
		}

		const options = { year: "numeric", month: "long", day: "numeric" };
		const today = new Date().toLocaleDateString("en-US", options);
		const newObj = { id: null, body: details, date: today, title: stage, stage: stageName };
		notes.notes.unshift(newObj);
		updateHistory(notes.notes);

		AuthService.updateOpp(oppData).then((response) => {
			setUpdate(true);
			AuthService.getNotes(id).then((response) => {
				setHistory(response.notes);
			})
		})
	}

	useEffect(() => {
		setNextStatusUpdate(getTodayDate());
		setCoeDate(getTodayDate());
		setClosingDate(getTodayDate());
		AuthService.getNotes(id).then((response) => {
			setNotes(response);
			let itemList = '';
			setHistory(response.notes);
			if (response.notes.length) {
				itemList = response.notes.map((item, index) => {
					let icon_user = item.stage ? default_data.icons[0][item.stage] : { "color": "primary", "icon": "supportagent" };
					return <TimelineItem
						key={index}
						color={icon_user.color}
						icon={icon_user.icon}
						title={item.title}
						dateTime={item.date}
						description={item.body}
					/>
				});
			}
			setnotestimeline(itemList);
		},
			(error) => {
				const _content =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();
			})
	}, [])

	function HomeIcon(props) {
		if (props.icon == 'email') {
			return (
				<SvgIcon {...props} pt={1} sx={{ width: "20px", height: "20px" }}>
					<svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M11.3334 2.33337H4.66671C2.66671 2.33337 1.33337 3.33337 1.33337 5.66671V10.3334C1.33337 12.6667 2.66671 13.6667 4.66671 13.6667H11.3334C13.3334 13.6667 14.6667 12.6667 14.6667 10.3334V5.66671C14.6667 3.33337 13.3334 2.33337 11.3334 2.33337ZM11.6467 6.39337L9.56004 8.06004C9.12004 8.41337 8.56004 8.58671 8.00004 8.58671C7.44004 8.58671 6.87337 8.41337 6.44004 8.06004L4.35337 6.39337C4.14004 6.22004 4.10671 5.90004 4.27337 5.68671C4.44671 5.47337 4.76004 5.43337 4.97337 5.60671L7.06004 7.27337C7.56671 7.68004 8.42671 7.68004 8.93337 7.27337L11.02 5.60671C11.2334 5.43337 11.5534 5.46671 11.72 5.68671C11.8934 5.90004 11.86 6.22004 11.6467 6.39337Z" fill="#283567" />
					</svg>
				</SvgIcon>
			);
		} else if (props.icon == 'phone') {
			return (
				<SvgIcon {...props} sx={{ width: "20px", height: "20px" }}>
					<svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fillRule="evenodd" clipRule="evenodd" d="M2.00321 1.59989C2.5045 1.10795 3.09054 0.833296 3.73682 0.833296C3.99227 0.833296 4.25222 0.887551 4.48976 1.00089C4.73519 1.11482 4.95888 1.28925 5.13279 1.53944L6.67699 3.71597C6.81368 3.906 6.92062 4.09194 6.99804 4.2802C7.07663 4.4647 7.13015 4.67092 7.13015 4.87996C7.13015 5.13787 7.05499 5.38438 6.92152 5.60622C6.80801 5.80519 6.653 5.99708 6.47391 6.17664L6.01474 6.65393C6.01939 6.66492 6.02563 6.67975 6.03237 6.69695C6.13215 6.87188 6.30883 7.11811 6.57675 7.4349C6.87016 7.77389 7.18133 8.11738 7.5171 8.45977C7.87169 8.80771 8.20758 9.12385 8.5467 9.41181C8.86736 9.68311 9.11723 9.85601 9.29882 9.95475C9.31346 9.96107 9.32701 9.9672 9.33805 9.97225L9.79783 9.51851C9.98012 9.33638 10.1756 9.17586 10.3843 9.06437C10.6023 8.93456 10.84 8.85996 11.1035 8.85996C11.3034 8.85996 11.501 8.90265 11.6995 8.98386C11.8881 9.06103 12.0727 9.16721 12.258 9.29317L12.2622 9.29601L14.4707 10.864C14.7071 11.0283 14.8924 11.2363 15.0078 11.4986L15.0112 11.5064L15.0144 11.5143C15.0993 11.7266 15.1568 11.9567 15.1568 12.22C15.1568 12.535 15.0866 12.8544 14.9423 13.1601C14.8091 13.4421 14.6356 13.7105 14.4073 13.9624C14.037 14.3705 13.6173 14.6773 13.1345 14.8754C12.6716 15.068 12.1731 15.1666 11.6435 15.1666C10.8844 15.1666 10.091 14.988 9.27416 14.6399C8.46917 14.297 7.66979 13.8374 6.88252 13.2642L6.8819 13.2637C6.09463 12.6887 5.34898 12.0524 4.63831 11.3486L4.63488 11.3452C3.93114 10.6346 3.29467 9.88874 2.72594 9.10759L2.72424 9.10527C2.15998 8.3208 1.70022 7.52865 1.36328 6.73545L1.36267 6.73399C1.02161 5.92399 0.843486 5.1313 0.843486 4.35996C0.843486 3.84786 0.933889 3.35151 1.11873 2.88891C1.30673 2.41165 1.60204 1.97932 2.00321 1.59989Z" fill="#283567" />
					</svg>
				</SvgIcon>
			);
		}

	}

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox my={3}>
				<MDBox mb={3}>
					<Grid container spacing={3}>
						<Grid item xs={12} lg={12} md={12}>
							{agentUpdate && (<MDAlert color="success" dismissible> Your user assignment update is received and will update shortly</MDAlert>)}
							<Card sx={{ height: "100%" }}>
								<MDBox display="flex" justifyContent="space-between" alignItems="center" pb={3} pt={3} px={3}>
									<MDTypography variant="h3" color="soliddark">
										{oppData.firstName} &nbsp;{oppData.lastName}
									</MDTypography>
									{oppData.buyerSeller == "Buyer" ? (<MDBox
										bgColor="lightneutralswidgets"
										variant="gradient"
										borderRadius="lg"
										opacity={1}
										pt={1}
										pb={1}
										pl={1.3}
										pr={1.3}
										sx={{ color: "#000000", border: "2px solid #6471ED" }}
									>
										{oppData.bothbuyandsell ?
											<MDTypography variant="h6" color="soliddark"> {oppData.buyerSeller} + SELLER</MDTypography>
											:
											<MDTypography variant="h6" color="soliddark"> {oppData.buyerSeller}</MDTypography>
										}


									</MDBox>) : (<MDBox
										bgColor="solidrose"
										variant="gradient"
										borderRadius="lg"
										opacity={1}
										pt={1}
										pb={1}
										pl={1.3}
										pr={1.3}
										sx={{ color: "#000000", border: "2px solid #FFB1DA" }}
									>
										{oppData.bothbuyandsell ?
											<MDTypography variant="h6" color="soliddark"> {oppData.buyerSeller} + Buyer</MDTypography>
											:
											<MDTypography variant="h6" color="soliddark"> {oppData.buyerSeller}</MDTypography>
										}
									</MDBox>)}

								</MDBox>

								<MDBox pb={1} px={3}>
									<MDTypography variant="body2" color="solidinfo" sx={{ display: "flex", alignItems: "center", columnGap: "8px" }}><HomeIcon icon="email" />  {oppData.oppemail}</MDTypography>
								</MDBox>
								<MDBox px={3}>
									<MDTypography variant="body2" color="solidinfo" sx={{ display: "flex", alignItems: "center", columnGap: "8px" }}><HomeIcon icon="phone" />   {oppData.phone}</MDTypography>
								</MDBox>
								<Divider orientation="horizontal" sx={{ ml: -2, mr: 1 }} />
								<MDBox display="flex" justifyContent="space-between" alignItems="center" px={3}>
									<Grid container spacing={3}>
										<Grid item xs={12} lg={6} md={6}>
											<MDTypography variant="h4">
												Current Status:
											</MDTypography>
											{((new Date(oppData.nextStatusUpdateDate) - new Date()) / (1000 * 60 * 60 * 24)) > 0 ?
												<MDTypography variant="h6">
													<MDBadgeDot ml={-2.5} badgeContent={'Update Within ' + Math.ceil((new Date(oppData.nextStatusUpdateDate) - new Date()) / (1000 * 60 * 60 * 24)) + ' Days'} size="lg" color="success" />
												</MDTypography> : (oppData.currentStatus == "Final Sold" || oppData.currentStatus == "Final Cancel") ? <></> :
													<MDTypography variant="h6">
														<MDBadgeDot ml={-2.5} badgeContent={'Update Over Due By ' + Math.floor((new Date() - new Date(oppData.nextStatusUpdateDate)) / (1000 * 60 * 60 * 24)) + ' Days'} size="lg" color="error" />
													</MDTypography>}

										</Grid>
										<Grid item xs={12} lg={6} md={6}>
											<MDBox color="black" bgColor="blackout" variant="gradient" coloredShadow="none" borderRadius="lg" opacity={1} p={2}>
												<MDTypography variant="h6" color="lightneutrals800" sx={{ fontWeight: "500" }}>{oppData.currentStatus}</MDTypography>
											</MDBox>
										</Grid>
									</Grid>

								</MDBox>
								<Divider orientation="horizontal" sx={{ ml: -2, mr: 1 }} />
								{hasteam ?
									<MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={3}>
										<Grid container spacing={isMobile ? 0 : 3}>
											<Grid item xs={12} lg={6} md={6}>
												<MDTypography variant="h6">
													update Assigned agent to :
												</MDTypography>
											</Grid>
											<Grid item xs={12} lg={6} md={6}>
												<Autocomplete
													sx={{ width: "250px" }}
													onChange={(event, value) => {
														AssignOpp(value)
													}}
													inputprops={{ 'aria-label': 'controlled' }}
													options={teamMembers}
													defaultValue={opp_agent}
													getOptionLabel={getOptionLabel}
													isOptionEqualToValue={isOptionEqualToValue}
													renderInput={(params) => (
														<FormField {...params} label="Assign To " InputLabelProps={{ shrink: true }} />
													)}
												/>										</Grid>
										</Grid>
									</MDBox> : <></>}

								<MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={3}>
									<Grid container spacing={isMobile ? 0 : 3}>
										<Grid item xs={12} lg={6} md={6}>
											<MDTypography variant="h6">
												Assigned to:
											</MDTypography>
										</Grid>
										<Grid item xs={12} lg={6} md={6}>
											<MDTypography variant="h6" color="lightneutrals800" sx={{ fontWeight: "500" }}>{opp_agent.name}</MDTypography>
										</Grid>
									</Grid>
								</MDBox>
								<MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={3}>
									<Grid container spacing={isMobile ? 0 : 3}>
										<Grid item xs={12} lg={6} md={6}>
											<MDTypography variant="h6">
												Date assigned:
											</MDTypography>
										</Grid>
										<Grid item xs={12} lg={6} md={6}>
											<MDTypography variant="h6" color="lightneutrals800" sx={{ fontWeight: "500" }}>{oppData.dateSubmitted}</MDTypography>
										</Grid>
									</Grid>
								</MDBox>
								<MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={3}>
									<Grid container spacing={isMobile ? 0 : 3}>
										<Grid item xs={12} lg={6} md={6}>
											<MDTypography variant="h6">
												Referral Fee:
											</MDTypography>
										</Grid>
										<Grid item xs={12} lg={6} md={6}>
											<MDTypography variant="h6" color="lightneutrals800" sx={{ fontWeight: "500" }}>{oppData.referralFee}%</MDTypography>
										</Grid>
									</Grid>
								</MDBox>
								<MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={3}>
									<Grid container spacing={isMobile ? 0 : 3}>
										<Grid item xs={12} lg={6} md={6}>
											<MDTypography variant="h6">
												Referral Source:
											</MDTypography>
										</Grid>
										<Grid item xs={12} lg={6} md={6}>
											<MDTypography variant="h6" color="lightneutrals800" sx={{ fontWeight: "500" }}>{oppData.referralSource}</MDTypography>
										</Grid>
									</Grid>
								</MDBox>
								<MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={3}>
									<Grid container spacing={isMobile ? 0 : 3}>
										<Grid item xs={12} lg={6} md={6}>
											<MDTypography variant="h6">
												Lead Source:
											</MDTypography>
										</Grid>
										<Grid item xs={12} lg={6} md={6}>
											<MDTypography variant="h6" color="lightneutrals800" sx={{ fontWeight: "500" }}>{oppData.leadsource}</MDTypography>
										</Grid>
									</Grid>
								</MDBox>
								<MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={3}>
									<Grid container spacing={isMobile ? 0 : 3}>
										<Grid item xs={12} lg={6} md={6}>
											<MDTypography variant="h6">
												Price Range:
											</MDTypography>
										</Grid>
										<Grid item xs={12} lg={6} md={6}>
											<MDTypography variant="h6" color="lightneutrals800" sx={{ fontWeight: "500" }}>{oppData.priceRange}</MDTypography>
										</Grid>
									</Grid>
								</MDBox>
								{oppData.buyerSeller == "Seller" ?
									<>
										<MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={3}>
											<Grid container spacing={isMobile ? 0 : 3}>
												<Grid item xs={12} lg={6} md={6}>
													<MDTypography variant="h6">
														Buying Time Frame:
													</MDTypography>
												</Grid>
												<Grid item xs={12} lg={6} md={6}>
													<MDTypography variant="h6" color="lightneutrals800" sx={{ fontWeight: "500" }}>{oppData.timeframe}</MDTypography>
												</Grid>
											</Grid>
										</MDBox>
										<MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={3}>
											<Grid container spacing={isMobile ? 0 : 3}>
												<Grid item xs={12} lg={6} md={6}>
													<MDTypography variant="h6">
														Reason for Buying:
													</MDTypography>
												</Grid>
												<Grid item xs={12} lg={6} md={6}>
													<MDTypography variant="h6" color="lightneutrals800" sx={{ fontWeight: "500" }}>{oppData.reasonForBuyingSelling}</MDTypography>
												</Grid>
											</Grid>
										</MDBox>
										<MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={3}>
											<Grid container spacing={isMobile ? 0 : 3}>
												<Grid item xs={12} lg={6} md={6}>
													<MDTypography variant="h6">
														Mortgage Status:
													</MDTypography>
												</Grid>
												<Grid item xs={12} lg={6} md={6}>
													<MDTypography variant="h6" color="lightneutrals800" sx={{ fontWeight: "500" }}>{oppData.existingMortgageStatus}</MDTypography>
												</Grid>
											</Grid>
										</MDBox>
									</>
									:
									<></>
								}
								<MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={3}>
									<Grid container spacing={isMobile ? 0 : 3}>
										<Grid item xs={12} lg={6} md={6}>
											<MDTypography variant="h6">
												Address/Location:
											</MDTypography>
										</Grid>
										<Grid item xs={12} lg={6} md={6}>
											<MDTypography variant="h6" color="lightneutrals800" sx={{ fontWeight: "500" }}>{location}</MDTypography>
										</Grid>
									</Grid>
								</MDBox>
								<Divider orientation="horizontal" sx={{ ml: -2, mr: 1 }} />
								<MDBox display="flex" justifyContent="space-between" alignItems="center" px={3}>
									<Grid container spacing={3}>
										<Grid item xs={12} lg={6} md={6}>
											<MDTypography variant="h4">
												Loan Status:
											</MDTypography>
										</Grid>
										<Grid item xs={12} lg={6} md={6}>
											<MDBox color="black" bgColor="blackout" variant="gradient" coloredShadow="none" borderRadius="lg" opacity={1} p={2}>
												<MDTypography variant="h6" color="lightneutrals800" sx={{ fontWeight: "500" }}>{oppData.existingMortgageStatus}</MDTypography>
											</MDBox>
										</Grid>
									</Grid>
								</MDBox>
								<Divider orientation="horizontal" sx={{ ml: -2, mr: 1 }} />
								<MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={3}>
									<Grid container spacing={isMobile ? 0 : 3}>
										<Grid item xs={12} lg={6} md={6}>
											<MDTypography variant="h6">
												Lender:
											</MDTypography>
										</Grid>
										<Grid item xs={12} lg={6} md={6}>
											<MDTypography variant="h6" color="lightneutrals800" sx={{ fontWeight: "500" }}>{oppData.lender}</MDTypography>
										</Grid>
									</Grid>
								</MDBox>
								<MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={3}>
									<Grid container spacing={isMobile ? 0 : 3}>
										<Grid item xs={12} lg={6} md={6}>
											<MDTypography variant="h6">
												Lender (NMLS #):
											</MDTypography>
										</Grid>
										<Grid item xs={12} lg={6} md={6}>
											<MDTypography variant="h6" color="lightneutrals800" sx={{ fontWeight: "500" }}>{oppData.lenderContactNMLS}</MDTypography>
										</Grid>
									</Grid>
								</MDBox>
								<MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={3}>
									<Grid container spacing={isMobile ? 0 : 3}>
										<Grid item xs={12} lg={6} md={6}>
											<MDTypography variant="h6">
												Lender contact Person:
											</MDTypography>
										</Grid>
										<Grid item xs={12} lg={6} md={6}>
											<MDTypography variant="h6" color="lightneutrals800" sx={{ fontWeight: "500" }}>{oppData.lenderContactFullName}</MDTypography>
										</Grid>
									</Grid>
								</MDBox>
								<MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={3}>
									<Grid container spacing={isMobile ? 0 : 3}>
										<Grid item xs={12} lg={6} md={6}>
											<MDTypography variant="h6">
												Lender Phone:
											</MDTypography>
										</Grid>
										<Grid item xs={12} lg={6} md={6}>
											<MDTypography variant="h6" color="lightneutrals800" sx={{ fontWeight: "500" }}>{oppData.lenderContactPhone}</MDTypography>
										</Grid>
									</Grid>
								</MDBox>
								<MDBox display="flex" justifyContent="space-between" alignItems="center" mb={3} pt={1} px={3}>
									<Grid container spacing={isMobile ? 0 : 3}>
										<Grid item xs={12} lg={6} md={6}>
											<MDTypography variant="h6">
												Lender email:
											</MDTypography>
										</Grid>
										<Grid item xs={12} lg={6} md={6}>
											<MDTypography variant="h6" color="lightneutrals800" sx={{ fontWeight: "500" }}>{oppData.lenderContactEmail}</MDTypography>
										</Grid>
									</Grid>
								</MDBox>
							</Card>
						</Grid>
						{oppData.currentStatus == "Final Sold" ? <></> :
							<Grid item xs={12} lg={6} md={6}>
								{update && (<MDAlert color="success" dismissible> Your information is received and will update shortly</MDAlert>)}
								<Card>
									<MDBox pt={3} px={3}>
										<MDTypography variant="h3" fontWeight="bold" color="soliddark">
											Keep Us Informed
										</MDTypography>
									</MDBox>
									<MDBox px={3} pt={2}>
										<MDTypography pb={2} variant="h6" fontWeight="medium">
											Update the current status:
										</MDTypography>
										{buyerseller === "Buyer" ?
											<Autocomplete
												onChange={(event, value) => onChangeStatus(value)}
												options={default_data.buyerStatuses_new}
												renderInput={(params) => (
													<FormField {...params} label="Status*" InputLabelProps={{ shrink: true }} />
												)}
											/> :
											<Autocomplete
												onChange={(event, value) => onChangeStatus(value)}
												options={default_data.sellerStatuses_new}
												renderInput={(params) => (
													<FormField {...params} label="Status*" InputLabelProps={{ shrink: true }} />
												)}
											/>
										}
									</MDBox>
									{selectedValue === "In Escrow - I am in escrow with this Client" && (
										<>
											<MDTypography variant="h6" pl={3} pt={2}> Closing Details </MDTypography>
											<MDBox pt={2} px={2}>
												<Grid container spacing={3} pb={3}>
													<Grid item xs={12} lg={6}>
														<MDInput
															fullWidth
															label="Purchase Price *"
															inputProps={{ type: "text", autoComplete: "" }}
															name="purchase_price"
															onChange={onChangeAdditionalDetails}
														/>
													</Grid>
													<Grid item xs={12} lg={6}>
														<MDTypography variant="overline" pr={2}> Closing Date * </MDTypography>
														<MDDatePicker name='agent_license_date' value={closingDate} dateFormat="dd/MM/yyyy" onChange={handleClosingDate} />
													</Grid>
													<Grid item xs={12} lg={12}>
														<MDTypography variant="h6" pl={1}> Property Details </MDTypography>
													</Grid>
													<Grid item xs={12} lg={6}>
														<MDInput
															fullWidth
															label="Property Street"
															inputProps={{ type: "text", autoComplete: "" }}
															name="property_street"
															onChange={onChangeAdditionalDetails}
														/>
													</Grid>
													<Grid item xs={12} lg={6}>
														<MDInput
															fullWidth
															label="Property City"
															inputProps={{ type: "text", autoComplete: "" }}
															name="property_city"
															onChange={onChangeAdditionalDetails}
														/>
													</Grid>
													<Grid item xs={12} lg={6}>
														<MDInput
															fullWidth
															label="Property State"
															inputProps={{ type: "text", autoComplete: "" }}
															name="property_state"
															onChange={onChangeAdditionalDetails}
														/>
													</Grid>
													<Grid item xs={12} lg={6}>
														<MDInput
															fullWidth
															label="Property Zip"
															inputProps={{ type: "text", autoComplete: "" }}
															name="property_zip"
															onChange={onChangeAdditionalDetails}
														/>
													</Grid>
												</Grid>
											</MDBox>
										</>
									)}
									{selectedValue === "Closed Escrow -  Sold! I have closed escrow with this Client" && (
										<>
											<MDTypography variant="h6" pl={3} pt={2}> Closing Details </MDTypography>
											<MDBox pt={2} px={2}>
												<Grid container spacing={3} pb={3} pl={1}>
													<Grid item xs={12} lg={6}>
														<MDInput
															fullWidth
															label="Sale Price *"
															inputProps={{ type: "text", autoComplete: "" }}
															name="sale_price"
															onChange={onChangeAdditionalDetails}
														/>
													</Grid>
													<Grid item xs={12} lg={6}>
														<MDTypography variant="overline" pr={2}> COE Date * </MDTypography>
														<MDDatePicker name='coe_date' value={coeDate} dateFormat="dd/MM/yyyy" onChange={handleCoeDate} />
													</Grid>
												</Grid>
											</MDBox>
										</>
									)}
									{selectedValue === "Listed - I have listed this Seller's property" && (
										<>
											<MDTypography variant="h6" pl={3} pt={2}> MLS Details </MDTypography>
											<MDBox pt={2} px={2}>
												<Grid container spacing={3} pb={3} pl={1}>
													<Grid item xs={12} lg={12}>
														<MDInput
															fullWidth
															label="MLS #"
															inputProps={{ type: "text", autoComplete: "" }}
															name="MLS_Number"
														/>
													</Grid>
												</Grid>
											</MDBox>
										</>
									)}
									{selectedValue === "On Hold -  I am nurturing this client (long term)"
										&& (
											<>
												<MDBox pt={2} px={2}>
													<Grid container spacing={3} pb={3} pl={1}>
														<Grid item xs={12} lg={12}>
															<MDTypography variant="overline" pr={2}> Next Status Update Date * </MDTypography>
															<MDDatePicker name='next_status_update_date' dateFormat="dd/MM/yyyy" />
														</Grid>
													</Grid>
												</MDBox>
											</>
										)}
									{selectedValue === "Nurture - I'm nurturing this client (long term)"
										&& (
											<>
												<MDBox pt={2} px={2}>
													<Grid container spacing={3} pb={3} pl={1}>
														<Grid item xs={12} lg={6}>
															<MDTypography variant="overline" pr={2}> Next Status Update Date * </MDTypography>
															<MDDatePicker name='next_status_update_date' value={nextStatusUpdate} dateFormat="dd/MM/yyyy" onChange={handleDate} />
														</Grid>
														<Grid item xs={12} lg={6}>
															<MDBox pt={2}>
																<MDTypography variant="subtitle2" color="warning">NOTE* : Must select a future Date</MDTypography>
															</MDBox>
														</Grid>
													</Grid>
												</MDBox>
											</>
										)}
									<MDBox px={3} pt={1}>
										<MDTypography variant="h6" fontWeight="medium">Details:</MDTypography>
									</MDBox>
									<TextField
										placeholder="Add Details here"
										size="large"
										multiline
										rows={4}
										sx={{ padding: "20px" }}
										onChange={onChangeDetails}
									/>
									{error ?
										<MDBox px={3} pb={3} align="right">
											<MDTypography variant="subtitle2" color="warning">NOTE* : Must select a status and put in a detail for the update to be properly received</MDTypography>
										</MDBox>
										: <></>
									}
									{selectedValue == "In Escrow - I am in escrow with this Client" ||
										selectedValue == "Closed Escrow -  Sold! I have closed escrow with this Client" ?
										<MDBox px={3} pb={3} align="right">
											<MDTypography variant="subtitle2" color="warning">If you need anything regarding this closing, don't hesitate to get in touch with <br />escrow@myagentfinder.com</MDTypography>
										</MDBox>
										: <></>
									}
									<MDBox px={3} pb={3} align="right">
										<MDTypography variant="h6" color="text">{250 - details.length} Characters Remaining</MDTypography>
									</MDBox>
									<MDBox px={3} pb={3} align="right">
										<MDButton variant="gradient" color="solidPrimary" disabled={error} onClick={handleUpdate} sx={{ fontSize: "0.875rem", textTransform: "lowercase" }}>
											update
										</MDButton>
									</MDBox>
								</Card>

							</Grid>}

						<Grid item xs={12} lg={6} md={6}>
							<Card>
								<MDBox pt={3} px={3}>
									<MDTypography variant="h3" fontWeight="bold">
										History
									</MDTypography>
								</MDBox>
								<MDBox p={2}>
									{notestimeline}
								</MDBox>
							</Card>

						</Grid>
					</Grid>
				</MDBox>
			</MDBox>
			<Footer />
		</DashboardLayout>
	);
}

export default Widgets;
