import { useMemo, useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
import AuthService from '../../../services/auth.service';
import ReferralsOutboundService from "services/referrals_outbound.service";
import Card from "@mui/material/Card";
import { useParams } from "react-router-dom";
import TimelineItem from "examples/Timeline/TimelineItem";
import default_data from "values/default";

import { SvgIcon, useMediaQuery, Tooltip, IconButton, Button } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';
import MDBadgeDot from "components/MDBadgeDot";
import { Circles } from "react-loader-spinner";

import { useTheme } from '@mui/material/styles';


function Oppurtunity() {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const [notes, setNotes] = useState([]);
	const { id } = useParams();
	const [oppData, setOppData] = useState({});
	const [notestimeline, setnotestimeline] = useState('');
	const [history, setHistory] = useState('');
	const [oppLoading, setOppLoading] = useState(true);
	const [historyLoading, setHistoryLoading] = useState(true);

	const navigate = useNavigate();

	const goBack = () => {
		navigate(-1);
	};
  

	useEffect(() => {
		setOppLoading(true);
		setHistoryLoading(true);
		ReferralsOutboundService.getOpp(id).then((response) => {
			setOppData(response);
			setOppLoading(false);
		})
		AuthService.getNotes(id).then((response) => {
			setNotes(response);
			let itemList = '';
			setHistory(response.notes);
			if (response.notes.length) {
				itemList = response.notes.map((item, index) => {
					let icon_user = item.stage ? default_data.icons[0][item.stage] : { "color": "primary", "icon": "supportagent" };
					return <TimelineItem
						key={index}
						color={icon_user.color}
						icon={icon_user.icon}
						title={item.title}
						lastItem={(index == (response.notes.length - 1)) ? true : false}
						dateTime={item.date}
						description={item.body}
					/>
				});
			}
			setnotestimeline(itemList);
			setHistoryLoading(false);
		},
			(error) => {
				const _content =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();
			})
	}, [])

	function HomeIcon(props) {
		if (props.icon == 'email') {
			return (
				<SvgIcon {...props} pt={1} sx={{ width: "20px", height: "20px" }}>
					<svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M11.3334 2.33337H4.66671C2.66671 2.33337 1.33337 3.33337 1.33337 5.66671V10.3334C1.33337 12.6667 2.66671 13.6667 4.66671 13.6667H11.3334C13.3334 13.6667 14.6667 12.6667 14.6667 10.3334V5.66671C14.6667 3.33337 13.3334 2.33337 11.3334 2.33337ZM11.6467 6.39337L9.56004 8.06004C9.12004 8.41337 8.56004 8.58671 8.00004 8.58671C7.44004 8.58671 6.87337 8.41337 6.44004 8.06004L4.35337 6.39337C4.14004 6.22004 4.10671 5.90004 4.27337 5.68671C4.44671 5.47337 4.76004 5.43337 4.97337 5.60671L7.06004 7.27337C7.56671 7.68004 8.42671 7.68004 8.93337 7.27337L11.02 5.60671C11.2334 5.43337 11.5534 5.46671 11.72 5.68671C11.8934 5.90004 11.86 6.22004 11.6467 6.39337Z" fill="#283567" />
					</svg>
				</SvgIcon>
			);
		} else if (props.icon == 'phone') {
			return (
				<SvgIcon {...props} sx={{ width: "20px", height: "20px" }}>
					<svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fillRule="evenodd" clipRule="evenodd" d="M2.00321 1.59989C2.5045 1.10795 3.09054 0.833296 3.73682 0.833296C3.99227 0.833296 4.25222 0.887551 4.48976 1.00089C4.73519 1.11482 4.95888 1.28925 5.13279 1.53944L6.67699 3.71597C6.81368 3.906 6.92062 4.09194 6.99804 4.2802C7.07663 4.4647 7.13015 4.67092 7.13015 4.87996C7.13015 5.13787 7.05499 5.38438 6.92152 5.60622C6.80801 5.80519 6.653 5.99708 6.47391 6.17664L6.01474 6.65393C6.01939 6.66492 6.02563 6.67975 6.03237 6.69695C6.13215 6.87188 6.30883 7.11811 6.57675 7.4349C6.87016 7.77389 7.18133 8.11738 7.5171 8.45977C7.87169 8.80771 8.20758 9.12385 8.5467 9.41181C8.86736 9.68311 9.11723 9.85601 9.29882 9.95475C9.31346 9.96107 9.32701 9.9672 9.33805 9.97225L9.79783 9.51851C9.98012 9.33638 10.1756 9.17586 10.3843 9.06437C10.6023 8.93456 10.84 8.85996 11.1035 8.85996C11.3034 8.85996 11.501 8.90265 11.6995 8.98386C11.8881 9.06103 12.0727 9.16721 12.258 9.29317L12.2622 9.29601L14.4707 10.864C14.7071 11.0283 14.8924 11.2363 15.0078 11.4986L15.0112 11.5064L15.0144 11.5143C15.0993 11.7266 15.1568 11.9567 15.1568 12.22C15.1568 12.535 15.0866 12.8544 14.9423 13.1601C14.8091 13.4421 14.6356 13.7105 14.4073 13.9624C14.037 14.3705 13.6173 14.6773 13.1345 14.8754C12.6716 15.068 12.1731 15.1666 11.6435 15.1666C10.8844 15.1666 10.091 14.988 9.27416 14.6399C8.46917 14.297 7.66979 13.8374 6.88252 13.2642L6.8819 13.2637C6.09463 12.6887 5.34898 12.0524 4.63831 11.3486L4.63488 11.3452C3.93114 10.6346 3.29467 9.88874 2.72594 9.10759L2.72424 9.10527C2.15998 8.3208 1.70022 7.52865 1.36328 6.73545L1.36267 6.73399C1.02161 5.92399 0.843486 5.1313 0.843486 4.35996C0.843486 3.84786 0.933889 3.35151 1.11873 2.88891C1.30673 2.41165 1.60204 1.97932 2.00321 1.59989Z" fill="#283567" />
					</svg>
				</SvgIcon>
			);
		}
		else if (props.icon == 'location') {
			return (
				<SvgIcon {...props} sx={{ width: "20px", height: "20px" }}>
					<svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M5.20209 2.97584C5.32168 2.91061 5.44876 3.01096 5.44876 3.14718V12.1691C5.44876 12.327 5.34069 12.4602 5.20209 12.5358L3.63542 13.4292C2.54209 14.0558 1.64209 13.5358 1.64209 12.2692V5.78251C1.64209 5.36251 1.94209 4.84251 2.31542 4.62917L5.20209 2.97584Z" fill="#283567" />
						<path d="M9.83713 4.61813C10.0075 4.70248 10.1152 4.87612 10.1152 5.06619V13.4662C10.1152 13.8347 9.73019 14.0766 9.39822 13.9167L6.89822 12.7122C6.72521 12.6288 6.61523 12.4538 6.61523 12.2617V3.8281C6.61523 3.45724 7.0048 3.21545 7.33713 3.38003L9.83713 4.61813Z" fill="#283567" />
						<path d="M14.7817 4.92249V11.4092C14.7817 11.8292 14.4817 12.3492 14.1084 12.5625L12.0304 13.7535C11.697 13.9445 11.2817 13.7038 11.2817 13.3196V4.91281C11.2817 4.7333 11.378 4.56756 11.5339 4.47858L12.7884 3.76249C13.8817 3.13583 14.7817 3.65583 14.7817 4.92249Z" fill="#283567" />					</svg>
				</SvgIcon>
			);
		}


	}

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<Button onClick={goBack} sx={{gap:"2px"}}><ArrowBack></ArrowBack> go back</Button>
			<MDBox my={1}>
				<MDBox mb={3}>
					<Grid container spacing={3}>
						{oppLoading ?
							<MDBox pb={3} sx={{ margin: "auto" }}>
								<Circles
									height="80"
									width="80"
									color="#434ca0"
									ariaLabel="circles-loading"
									wrapperStyle={{}}
									wrapperClass=""
									visible={true}
								/>
							</MDBox>
							:
							<Grid item xs={12} lg={12} md={12}>
								<Card sx={{ height: "100%" }}>
									<MDBox  alignItems="center">
										<Grid  container justifyContent="space-between" pb={3} pt={3} px={3}>
											<Grid display="flex" item xs={12} sm={12} md={6} lg={6}>
												<MDTypography variant="h3" color="soliddark">
													{oppData.firstName} &nbsp;{oppData.lastName}
												</MDTypography>
											</Grid>
											<Grid item display="flex" xs={12} sm={12} md={6} lg={6} justifyContent= {isMobile ? "flex-start":"flex-end"}>
												{oppData.buyerSeller == "Buyer" ? (<MDBox
													bgColor="lightneutralswidgets"
													variant="gradient"
													borderRadius="lg"
													opacity={1}
													pt={1}
													pb={1}
													pl={1.3}
													pr={1.3}
													sx={{ color: "#000000", border: "2px solid #6471ED", maxWidth: "75px" }}
												>
													<MDTypography variant="h6" color="soliddark"> {oppData.buyerSeller}</MDTypography>
												</MDBox>) : (<MDBox
													bgColor="solidrose"
													variant="gradient"
													borderRadius="lg"
													opacity={1}
													pt={1}
													pb={1}
													pl={1.3}
													pr={1.3}
													sx={{ color: "#000000", border: "2px solid #FFB1DA" }}
												>
													<MDTypography variant="h6" color="soliddark"> {oppData.buyerSeller}</MDTypography>
												</MDBox>)}
											</Grid></Grid>
									</MDBox>

									<MDBox pb={1} px={3}>
										<MDTypography variant="body2" color="solidinfo" sx={{ display: "flex", alignItems: "center", columnGap: "8px" }}><HomeIcon icon="email" />  {oppData.oppemail}</MDTypography>
									</MDBox>
									<MDBox px={3} pb={1}>
										<MDTypography variant="body2" color="solidinfo" sx={{ display: "flex", alignItems: "center", columnGap: "8px" }}><HomeIcon icon="phone" />   {oppData.phone}</MDTypography>
									</MDBox>
									<MDBox px={3}>
										<MDTypography variant="body2" color="solidinfo" sx={{ display: "flex", alignItems: "center", columnGap: "8px" }}><HomeIcon icon="location" />   {oppData.propertyCity} , {oppData.propertyState}</MDTypography>
									</MDBox>
									<Divider orientation="horizontal" sx={{ ml: -2, mr: 1 }} />
									<MDBox display="flex" justifyContent="space-between" alignItems="center" px={3}>
										<Grid container spacing={3}>
											<Grid item xs={12} lg={6} md={6}>
												<MDTypography variant="h4">
													Current Status:
												</MDTypography>
												{oppData.updateWithinDays > 0 ?
													<MDTypography variant="h6">
														<MDBadgeDot ml={-2.5} badgeContent={'Update Within ' + oppData.updateWithinDays + ' Days'} size="lg" color="success" />
													</MDTypography> :
													<MDTypography variant="h6">
														<MDBadgeDot ml={-2.5} badgeContent={'Update Over Due By ' + Math.abs(oppData.updateWithinDays) + ' Days'} size="lg" color="error" />
													</MDTypography>}

											</Grid>
											<Grid item xs={12} lg={6} md={6}>
												<MDBox color="black" bgColor="blackout" variant="gradient" coloredShadow="none" borderRadius="lg" opacity={1} p={2}>
													<MDTypography variant="h6" color="lightneutrals800" sx={{ fontWeight: "500" }}>{oppData.currentStatus}</MDTypography>
												</MDBox>
											</Grid>
										</Grid>

									</MDBox>
									<Divider orientation="horizontal" sx={{ ml: -2, mr: 1 }} />
									<MDBox display="flex" justifyContent="space-between" alignItems="center" px={3}>
										<Grid container spacing={3}>
											<Grid item xs={12} lg={6} md={6}>
												<MDTypography variant="h4">
													Assigned Agent:
												</MDTypography>
											</Grid>
											<Grid item xs={12} lg={6} md={6}>
												<MDBox color="black" bgColor="blackout" variant="gradient" coloredShadow="none" borderRadius="lg" opacity={1} p={2}>
													<MDTypography variant="h6" color="lightneutrals800" sx={{ fontWeight: "500" }}>{oppData.assignedAgentName}</MDTypography>
												</MDBox>
											</Grid>
										</Grid>

									</MDBox>
									<Divider orientation="horizontal" sx={{ ml: -2, mr: 1 }} />
									<MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={3}>
										<Grid container spacing={isMobile ? 0 : 3}>
											<Grid item xs={12} lg={6} md={6}>
												<MDTypography variant="h6">
													Submission Date:
												</MDTypography>
											</Grid>
											<Grid item xs={12} lg={6} md={6}>
												<MDTypography variant="h6" color="lightneutrals800" sx={{ fontWeight: "500" }}>{oppData.dateSubmitted}</MDTypography>
											</Grid>
										</Grid>
									</MDBox>
									<MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={3}>
										<Grid container spacing={isMobile ? 0 : 3}>
											<Grid sx={{ display: "flex", alignItems: "center", gap: "4px" }} item xs={12} lg={6} md={6}>
												<MDTypography variant="h6">
													Revenue Opportunity:
												</MDTypography>
												<Tooltip title="This is the potential revenue from this opportunity should a sale close. This calculation is based on an assumed 2.5-3.0% commission to the Partner Agent and the customer's target price range.est">
													<IconButton sx={{ padding: 0 }}>
														<SvgIcon viewBox="0 0 18 18" sx={{ width: "1em", height: "1em" }}>
															<svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
																<g clipPath="url(#clip0_2751_39369)">
																	<path d="M9 8.25C9.41421 8.25 9.75 8.58579 9.75 9V12C9.75 12.4142 9.41421 12.75 9 12.75C8.58579 12.75 8.25 12.4142 8.25 12V9C8.25 8.58579 8.58579 8.25 9 8.25Z" fill="#6471ED" />
																	<path d="M9 5.25C8.58579 5.25 8.25 5.58579 8.25 6C8.25 6.41421 8.58579 6.75 9 6.75H9.0075C9.42171 6.75 9.7575 6.41421 9.7575 6C9.7575 5.58579 9.42171 5.25 9.0075 5.25H9Z" fill="#6471ED" />
																	<path fillRule="evenodd" clipRule="evenodd" d="M0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C13.5563 0.75 17.25 4.44365 17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5563 0.75 9ZM9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25Z" fill="#6471ED" />
																</g>
																<defs>
																	<clipPath id="clip0_2751_39369">
																		<rect width="18" height="18" fill="white" />
																	</clipPath>
																</defs>      </svg></SvgIcon>
													</IconButton>
												</Tooltip>
											</Grid>
											<Grid item xs={12} lg={6} md={6}>
												<MDTypography variant="h6" color="lightneutrals800" sx={{ fontWeight: "500" }}>${oppData.affiliateOpportunity}</MDTypography>
											</Grid>
										</Grid>
									</MDBox>
									<MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={3}>
										<Grid container spacing={isMobile ? 0 : 3}>
											<Grid item xs={12} lg={6} md={6}>
												<MDTypography variant="h6">
													Referral Source:
												</MDTypography>
											</Grid>
											<Grid item xs={12} lg={6} md={6}>
												<MDTypography variant="h6" color="lightneutrals800" sx={{ fontWeight: "500" }}>{oppData.referralSource}</MDTypography>
											</Grid>
										</Grid>
									</MDBox>
									<MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={3}>
										<Grid container spacing={isMobile ? 0 : 3}>
											<Grid item xs={12} lg={6} md={6}>
												<MDTypography variant="h6">
													Price Range:
												</MDTypography>
											</Grid>
											<Grid item xs={12} lg={6} md={6}>
												<MDTypography variant="h6" color="lightneutrals800" sx={{ fontWeight: "500" }}>{oppData.priceRange}</MDTypography>
											</Grid>
										</Grid>
									</MDBox>
									{
										oppData.buyerSeller == "Buyer" ?
											<>
												<MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={3}>
													<Grid container spacing={isMobile ? 0 : 3}>
														<Grid item xs={12} lg={6} md={6}>
															<MDTypography variant="h6">
																Buying Time Frame:
															</MDTypography>
														</Grid>
														<Grid item xs={12} lg={6} md={6}>
															<MDTypography variant="h6" color="lightneutrals800" sx={{ fontWeight: "500" }}>{oppData.timeframe}</MDTypography>
														</Grid>
													</Grid>
												</MDBox>
												<MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={3}>
													<Grid container spacing={isMobile ? 0 : 3}>
														<Grid item xs={12} lg={6} md={6}>
															<MDTypography variant="h6">
																Mortgage Status:
															</MDTypography>
														</Grid>
														<Grid item xs={12} lg={6} md={6}>
															<MDTypography variant="h6" color="lightneutrals800" sx={{ fontWeight: "500" }}>{oppData.existingMortgageStatus}</MDTypography>
														</Grid>
													</Grid>
												</MDBox>
											</>
											: <></>
									}
									{
										oppData.buyerSeller == "Seller" ?
											<>
												<MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={3}>
													<Grid container spacing={isMobile ? 0 : 3}>
														<Grid item xs={12} lg={6} md={6}>
															<MDTypography variant="h6">
																Selling Time Frame:
															</MDTypography>
														</Grid>
														<Grid item xs={12} lg={6} md={6}>
															<MDTypography variant="h6" color="lightneutrals800" sx={{ fontWeight: "500" }}>{oppData.timeframe}</MDTypography>
														</Grid>
													</Grid>
												</MDBox>
												<MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={3}>
													<Grid container spacing={isMobile ? 0 : 3}>
														<Grid item xs={12} lg={6} md={6}>
															<MDTypography variant="h6">
																Type of Home:
															</MDTypography>
														</Grid>
														<Grid item xs={12} lg={6} md={6}>
															<MDTypography variant="h6" color="lightneutrals800" sx={{ fontWeight: "500" }}>{oppData.typeOfHome}</MDTypography>
														</Grid>
													</Grid>
												</MDBox>
											</>
											: <></>
									}


									<MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={3}>
										<Grid container spacing={isMobile ? 0 : 3}>
											<Grid item xs={12} lg={6} md={6}>
												<MDTypography variant="h6">
													Min Bed/Bath:
												</MDTypography>
											</Grid>
											<Grid item xs={12} lg={6} md={6}>
												<MDTypography variant="h6" color="lightneutrals800" sx={{ fontWeight: "500" }}>{oppData.bedrooms ? oppData.bedrooms : 0}beds/{oppData.bathrooms ? oppData.bathrooms : 0}baths</MDTypography>
											</Grid>
										</Grid>
									</MDBox>
									<MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={3} pb={3}>
										<Grid container spacing={isMobile ? 0 : 3}>
											<Grid item xs={12} lg={6} md={6}>
												<MDTypography variant="h6">
													Notes:
												</MDTypography>
											</Grid>
											<Grid item xs={12} lg={6} md={6}>
												<MDTypography variant="h6" color="lightneutrals800" sx={{ fontWeight: "500" }}>{oppData.leadcomment}</MDTypography>
											</Grid>
										</Grid>
									</MDBox>
								</Card>
							</Grid>
						}
						<Grid item xs={12} lg={12} md={12}>
							<Card>
								<MDBox pt={3} px={3}>
									<MDTypography variant="h3" fontWeight="bold">
										History
									</MDTypography>
								</MDBox>
								{historyLoading ?
									<MDBox pb={3} sx={{ margin: "auto" }}>
										<Circles
											height="80"
											width="80"
											color="#434ca0"
											ariaLabel="circles-loading"
											wrapperStyle={{}}
											wrapperClass=""
											visible={true}
										/>
									</MDBox>
									:
									<MDBox p={2}>
										{notestimeline}
									</MDBox>
								}
							</Card>

						</Grid>
					</Grid>
				</MDBox>
			</MDBox>
			<Footer />
		</DashboardLayout>
	);
}

export default Oppurtunity;
