import React from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Title from "components/global/title";
import ButtonLink from "components/global/buttonLink";

const PlanSubmitted = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="flex flex-col items-start gap-[32px] self-stretch pb-12">
        <div className="flex items-start self-stretch">
          <Title
            text="Plan Change Request Submitted"
            className="text-[28px] sm:text-[30px]"
          />
        </div>
        <div className="inline-flex flex-col items-start justify-start gap-6 Frame48">
          <div className="YourRequestHasBeenReceivedWeLlConfirmYourPlanChangeViaEmailAndOnYourMyPlanPageWithinTheNext24Hours w-[500px] text-[#7e85a3] text-base font-normal leading-relaxed">Your request has been received!  We'll confirm your plan change via email and on your My Plan page within the next 24 hours.</div>
            <ButtonLink
              text="Back to My Plan"
              href="/my-plan"
              className="text-sm w-[fit-content] font-bold leading-tight text-right text-white grow shrink basis-0 px-6 py-3 bg-[#434ca0] hover:bg-[#373E82] rounded-lg justify-center items-center gap-2.5 flex"
            >
            </ButtonLink>
          </div>
        </div>
      <Footer />
    </DashboardLayout>
  );
};

export default PlanSubmitted;