 

/**
 * The base colors for the Material Dashboard 2 PRO React.
 * You can add new color using this file.
 * You can customized the colors for the entire Material Dashboard 2 PRO React using thie file.
 */

const colors = {
  background: {
    default: "#E4EBFF",
  },

  text: {
    main: "#7b809a",
    focus: "#7b809a",
  },

  transparent: {
    main: "transparent",
  },

  gradientgreen : {
    main : "#C3F1A9",
    focus : "#C3F1A9"
  },

  white: {
    main: "#ffffff",
    focus: "#ffffff",
  },

  black: {
    light: "#000000",
    main: "#000000",
    focus: "#000000",
  },

  primary: {
    main: "#e91e63",
    focus: "#e91e63",
  },

  secondary: {
    main: "#7b809a",
    focus: "#8f93a9",
  },

  info: {
    main: "#6471ED",
    focus: "#1662C4",
  },

  blackout: {
    main: "#F0F1FD",
    focus: "#1662C4",
  },
  solidinfo:{
    focus:"#6471ED",
    main:"#6471ED",
  },
  soliddark:{
    main:"#283567",
    focus:"#283567",
  },
  solidtext:{
    main:"#7E85A3",
    focus:"#7E85A3",
  },
  solidsuccess:{
    main:"#4DA917",
    focus:"#4DA917",
  },
  soliderror:{
    main:"#EB2C28",
    focus:"#EB2C28",
  },
  solidrose:{
    main:"#FFF7FB",
    focus:"#FFF7FB",
  },
  lightneutrals:{
    300:"#F0F1FD",
    700:"#7E85A3",
    focus:"#7E85A3",
  },
  success: {
    main: "#4CAF50",
    focus: "#67bb6a",
  },
  lightneutrals700: {
    main: "#7E85A3",
    focus: "#7E85A3",
  },
  solidgreenLighter : {
    main : "#CDF3B7",
    focus : "#CDF3B7",
  },
  soliderror : {
    main : "#EB2C28",
    focus : "#EB2C28",
  },
  solidwarning : {
    main : "#F9CFBD",
    focus : "#F9CFBD",
  },
  solidlight : {
    main : "#FFFFFF",
    focus : "#FFFFFF",
  },
  lightneutrals800: {
    main: "#535D85",
    focus: "#535D85",
  },
  gradientGreenDark: {
    main: "#375525",
    focus: "#375525",
  },  gradientPeachDark: {
    main: "#6F331B",
    focus: "#6F331B",
  },
  solidPrimary:{
    focus:"#434CA0",
    main:"#434CA0",
  },

  warning: {
    main: "#fb8c00",
    focus: "#fc9d26",
  },

  error: {
    main: "#F44335",
    focus: "#f65f53",
  },

  light: {
    main: "#f0f2f5",
    focus: "#f0f2f5",
  },

  dark: {
    main: "#344767",
    focus: "#2c3c58",
  },
  neutralToolkit: {
    main: "#667085",
    focus: "#667085",
  },

  grey: {
    100: "#f8f9fa",
    200: "#f0f2f5",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#6c757d",
    700: "#495057",
    800: "#343a40",
    900: "#212529",
  },

  gradients: {
    primary: {
      main: "#EC407A",
      state: "#D81B60",
    },

    secondary: {
      main: "#747b8a",
      state: "#495361",
    },

    info: {
      main: "#838DF1",
      state: "#6471ED",
    },

    success: {
      main: "#66BB6A",
      state: "#43A047",
    },

    warning: {
      main: "#FFA726",
      state: "#FB8C00",
    },

    error: {
      main: "#EF5350",
      state: "#E53935",
    },

    light: {
      main: "#EBEFF4",
      state: "#CED4DA",
    },

    dark: {
      state: "#212650",
      main: "#434CA0",
    },
    blackout: {
      state: "#F0F1FD",
      main: "#F0F1FD",
    },
    solidinfo:{
      state:"#6471ED",
      main:"#6471ED",
    },
    solidPrimary:{
      state:"#434CA0",
      main:"#434CA0",
    },
    lightneutrals:{
      main:"#7E85A3",
      focus:"#7E85A3",
    },
    solidrose:{
      state:"#FFF7FB",
      main:"#FFF7FB",
    },
    lightneutralswidgets:{
      state:"#F0F1FD",
      main:"#F0F1FD",
    },
    gradientgreen : {
      main : "#C3F1A9",
      state : "#A4D886"
    },
    gradientIris : {
      main : "#CFD3F9",
      state : "#B8BEF7"
    },
    gradientVibrant : {
      main : "#838DF1",
      state : "#838DF1"
    },
    gradientPeach : {
      state : "#F8B497",
      main : "#F9CFBD"
    },
    gradientViolet : {
      main : "#EDD2FF",
      state : "#E4BCFF"
    },
    gradientRose : {
      main : "#FFCBE6",
      state : "#FFB1DA"
    },
    solidgreenLighter : {
      main : "#CDF387",
      state : "#CDF387"
    }
  },

  socialMediaColors: {
    facebook: {
      main: "#3b5998",
      dark: "#344e86",
    },

    twitter: {
      main: "#55acee",
      dark: "#3ea1ec",
    },

    instagram: {
      main: "#125688",
      dark: "#0e456d",
    },

    linkedin: {
      main: "#0077b5",
      dark: "#00669c",
    },

    pinterest: {
      main: "#cc2127",
      dark: "#b21d22",
    },

    youtube: {
      main: "#e52d27",
      dark: "#d41f1a",
    },

    vimeo: {
      main: "#1ab7ea",
      dark: "#13a3d2",
    },

    slack: {
      main: "#3aaf85",
      dark: "#329874",
    },

    dribbble: {
      main: "#ea4c89",
      dark: "#e73177",
    },

    github: {
      main: "#24292e",
      dark: "#171a1d",
    },

    reddit: {
      main: "#ff4500",
      dark: "#e03d00",
    },

    tumblr: {
      main: "#35465c",
      dark: "#2a3749",
    },
  },

  badgeColors: {
    primary: {
      background: "#f8b3ca",
      text: "#cc084b",
    },

    secondary: {
      background: "#d7d9e1",
      text: "#6c757d",
    },

    info: {
      background: "#aecef7",
      text: "#095bc6",
    },

    success: {
      background: "#bce2be",
      text: "#339537",
    },

    warning: {
      background: "#ffd59f",
      text: "#c87000",
    },

    error: {
      background: "#fcd3d0",
      text: "#f61200",
    },

    light: {
      background: "#ffffff",
      text: "#c7d3de",
    },

    dark: {
      background: "#8097bf",
      text: "#1e2e4a",
    },
  },

  coloredShadows: {
    primary: "#e91e62",
    secondary: "#110e0e",
    info: "#00bbd4",
    success: "#4caf4f",
    warning: "#ff9900",
    error: "#f44336",
    light: "#adb5bd",
    dark: "#404040",
    gradientgreen : "#C3F1A9",
  },

  inputBorderColor: "#d2d6da",

  tabs: {
    indicator: { boxShadow: "#ddd" },
  },
};

export default colors;
