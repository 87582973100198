import axios from "axios";

import default_data from "values/default";
import Cookies from 'js-cookie';

const API_URL = default_data.API_URL + "/api/v0/agents";

const getOutboundReferrals = (stage) => {
    return axios.post(API_URL + "/opps",
        {
        username: Cookies.get('username'),
        api_key: default_data.api_key,
        secret_key: default_data.secret_key,
        paginate : 10,
        stage : stage,
        }, { headers: { 'Authorization': 'Bearer ' + Cookies.get('referral_id'), 'Accept': 'application/json' } }
    ).then((response) => {
        return response.data;
    });
}

const getOpp = (id) => {
    return axios.post(API_URL + "/oppid", {
        "oppid" : id
    }, { headers: { 'Authorization': 'Bearer ' + Cookies.get('referral_id'), 'Accept': 'application/json' } }).then((response) => {
        return response.data;
    })
}


const ReferralsOutboundService = {
    getOutboundReferrals,
    getOpp,
}

export default ReferralsOutboundService;