

import { useState, useRef } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";
import MDAlert from "components/MDAlert";
// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import AuthService from '../../../../services/auth.service';
import { useSignIn } from "react-auth-kit";

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setIsAuthenticated } from "context";
import Cookies from 'js-cookie';
import CoverLayout from "layouts/authentication/components/CoverLayout";

function AgentheroBasic(props) {

	const [rememberMe, setRememberMe] = useState(false);
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState("");
	const handleSetRememberMe = () => setRememberMe(!rememberMe);
	const navigate = useNavigate();
	const signIn = useSignIn();

	const [controller, dispatch] = useMaterialUIController();
	const { isAuthenticated } = controller;

	const onChangePassword = (e) => {
		const password = e.target.value.trim();
		if (password !== '') setPassword(password);
	}

	const onChangeUsername = (e) => {
		const username = e.target.value.trim();
		if (username !== '') setUsername(username);
	}
	const usernameKeyDown = (e) => {
		if (e.keyCode === 8) {
			const {name} = e.target;
			setUsername('')
		}
	}
	const passwordKeyDown = (e) => {
		if (e.keyCode === 8) {
			const {name} = e.target;
			setPassword('')
		}
	}

	const handleLogin = (e) => {
		e.preventDefault();
		setMessage("");
		setLoading(true);
		setError(false);
		AuthService.Loginuser(username, password).then((res) => {
			signIn({
				token: res.data.response.user.session_id,
				expiresIn: 3600,
				tokenType: "Bearer",
				authState: { username: username, first_name: res.data.response.user.first_name, last_name: res.data.response.user.last_name },
			});
			setIsAuthenticated(dispatch, "true");
			Cookies.set('userToken', res.data.response.user.session_id);
			Cookies.set('referral_id', res.data.response.user.session_id);
			Cookies.set('uuid', res.data.response.user.uuid);
			Cookies.set('username', res.data.response.user.username);
			AuthService.getUserInfo().then((response) => {
				props.setUserdata(response.data.response);
			})
			navigate("/dashboard")
		}, (error) => {
			const resMessage =
				(error.response &&
					error.response.data &&
					error.response.data.message) ||
				error.message ||
				error.toString();
			setError(true);
			setLoading(false);
			setMessage(resMessage);
		})
	}

	return (
		<CoverLayout image="logo-white-agenthero.png" maxWidth = "600px">
			<Card>
				<MDBox
					variant="gradient"
					bgColor="info"
					borderRadius="lg"
					coloredShadow="info"
					mx={2}
					mt={-3}
					p={2}
					mb={1}
					textAlign="center"
					sx={{
						background: `linear-gradient(180deg, #838DF1 0%, #6471ED 100%);`
					}}
				>
					<MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
						Sign in
					</MDTypography>
				</MDBox>
				<MDBox pt={4} pb={3} px={3}>
					<MDBox component="form" role="form">
						{error && (<MDAlert color="error" dismissible>Email or Password Wrong!</MDAlert>)}
						<MDBox mb={2}>
							<MDInput type="email" label="Email" fullWidth value={username} onChange={onChangeUsername}  onKeyDown={usernameKeyDown}/>
						</MDBox>
						<MDBox mb={2}>
							<MDInput type="password" label="Password" fullWidth value={password} onChange={onChangePassword} onKeyDown={passwordKeyDown}/>
						</MDBox>
						<Grid container spacing={3}>
							<Grid item sm={6} md={6} lg={6}>
								<MDBox display="flex" alignItems="center" ml={-1}>
									<Switch checked={rememberMe} onChange={handleSetRememberMe} />
									<MDTypography
										variant="button"
										fontWeight="regular"
										color="text"
										onClick={handleSetRememberMe}
										sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
									>
										&nbsp;&nbsp;Remember me
									</MDTypography>
								</MDBox>
							</Grid>
							<Grid align="right" item sm={6} md={6} lg={6}>
								<MDTypography
									component={Link}
									to="/forgot"
									variant="button"
									color="solidinfo"
									fontWeight="medium"
									textGradient
								>
									Forgot Password
								</MDTypography>
							</Grid>
						</Grid>

						<MDBox mt={4} mb={1}>
							<MDButton variant="gradient" color="solidPrimary" fullWidth onClick={handleLogin}>
								sign in
							</MDButton>
						</MDBox>
						<MDBox mt={3} mb={1} textAlign="center">
							<MDTypography variant="button" color="text">
								Don&apos;t have an account?{" "}
								<MDTypography
									component={Link}
									to="/register/agenthero"
									variant="button"
									color="solidinfo"
									fontWeight="medium"
									textGradient
								>
									Sign up
								</MDTypography>
							</MDTypography>
						</MDBox>
					</MDBox>
				</MDBox>
			</Card>
		</CoverLayout>
	);
}

export default AgentheroBasic;
