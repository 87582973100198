// @mui material components
import Link from "@mui/material/Link";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React base styles
import typography from "assets/theme/base/typography";
import { FormatUnderlined } from "@mui/icons-material";

function Footer() {
  const { size } = typography;

  return (
    <MDBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
      >
       {new Date().getFullYear()} &copy; BTRN, LLC &nbsp;| 
        <Link href="/agree" target="_blank" >
        &nbsp; 
          <MDTypography variant="button" fontWeight="light" sx={"text-decoration: underline"} color="lightneutrals">
            Referral Terms and Conditions
          </MDTypography>
        </Link>
      </MDBox>
    </MDBox>
  );
}

export default Footer;
