import React from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Title from "components/global/title";
import ButtonLink from "components/global/buttonLink";

const NoAccess = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="flex flex-col items-start gap-[32px] self-stretch pb-12">
        <div className="flex items-start self-stretch">
          <Title
            text="Looks like you don't have access to this page."
            className="text-[28px] sm:text-[30px]"
          />
        </div>
        <div className="inline-flex flex-col items-start justify-start gap-6 Frame48">
          <div className="w-[500px] text-[#7e85a3] text-base font-normal leading-relaxed">Our subscription tiers are only available to registered agents within our referral network who have agreed to our terms and conditions.</div>
          <div className="w-[500px] text-[#7e85a3] text-base font-normal leading-relaxed">
            If you believe this is an error and you should have access to this page, please contact <a href="mailto:referrals@myagentfinder.com" className="text-blue-500 underline">referrals@myagentfinder.com</a>
          </div>
          <ButtonLink
            text="Back to Dashboard"
            href="/dashboard"
            className="text-sm w-[fit-content] font-bold leading-tight text-right text-white grow shrink basis-0 px-6 py-3 bg-[#434ca0] hover:bg-[#373E82] rounded-lg justify-center items-center gap-2.5 flex"
          >
          </ButtonLink>
        </div>
      </div>
      <Footer />
    </DashboardLayout>
  );
};

export default NoAccess;