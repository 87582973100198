import default_data from "values/default";

export const makeUserDetails = (data) => {
  const stateObj = default_data.states.find(state => state.abbr === data.state);

  // Create a new userdata object by copying properties from data
  let userdata = { ...data };

  // Update only the necessary fields
  userdata.state = stateObj ? stateObj.name : data.state;
  userdata.price_min_buyers = data.price_min_buyers ? changeStringNumbertoLocalNumber(data.price_min_buyers) : '';
  userdata.price_min_buyers_sellers = data.price_min_buyers_sellers ? changeStringNumbertoLocalNumber(data.price_min_buyers_sellers) : ''; //landbuyers and sellers
  userdata.price_min_sellers = data.price_min_sellers ? changeStringNumbertoLocalNumber(data.price_min_sellers) : '';

  if (data.account_no_longer_hold_date) {
    const today = new Date();
    const holdDate = new Date(data.account_no_longer_hold_date);
    if (holdDate > today) {
      userdata.account_no_longer_hold_date = data.account_no_longer_hold_date;
      userdata.account_pause = data.account_pause;
    } else {
      userdata.account_no_longer_hold_date = '';
      userdata.account_pause = false;
    }
  }

  // Update the referralTypes separately if needed
  if (data.referralTypes) {
    const referralTypes = [
      { value: 'Home Buyers', text: 'Buyers' },
      { value: 'Investors', text: 'Investors' },
      { value: 'Home Sellers', text: 'Sellers' },
      { value: 'Short Sales', text: 'Short Sales' },
      { value: 'Commercial Properties', text: 'Commercial Properties' },
      { value: 'Land', text: 'Land' },
    ];
    const filteredReferralTypes = referralTypes.filter(type => data.referralTypes.includes(type.text));
    userdata.referralTypes = filteredReferralTypes.map(type => type.text);
  } else {
    userdata.referralTypes = [];
  }

  return userdata;
}

const changeStringNumbertoLocalNumber = (number) => {
  return Number(number).toLocaleString();
}
