import Title from 'components/global/title';
import React from 'react';
import FieldTitle from './fieldTitle';
import Divider from './divider';
import ContactInformation from './steps/ContactInformation';
import HomeType from './steps/HomeType';
import AddressInput from './steps/AddressInput';
import BudgetSelect from './steps/BudgetSelect';
import PreApproved from './steps/PreApproved';
import Timeframe from './steps/Timeframe';
import FirstTime from './steps/FirstTime';
import AlsoBuy from './steps/Buy';

const FormComponent = ({ type, formData, handleChange, errors }) => {
  const title = type === 'buyer' ? 'Manually Add Buyer Referral' : 'Manually Add Seller Referral';
  
  return (
    <>
      <div className={`flex p-[16px] flex-col items-start gap-[10px] self-stretch rounded-[12px] bg-[#FFF] [box-shadow:0px_2px_6px_0px_rgba(0,_0,_0,_0.13)]`}>
        <div className="Row flex p-[8px] flex-col items-start gap-[8px] self-stretch">
          <div className="Column flex px-0 py-[4px] items-center gap-[4px]">
            <Title
              text={title}
              className="text-[25px] not-italic font-bold leading-[125%]"
            />
          </div>
          <div className="self-stretch text-[#7e85a3] text-base font-normal leading-relaxed">Before submitting a referral through this form, you must confirm you have permission to share this individual's personal information, and that they agree to be contacted by a Licensed Realtor.</div>
        </div>
        <div>
          <Divider />
          <FieldTitle title="Referral Contact Information" />
          <ContactInformation formData={formData} handleChange={handleChange} errors={errors} />

          <Divider />
          <FieldTitle title={type === "buyer" ? "What kind of home are they looking to buy?" : "What kind of home are they looking to sell?"} />
          <HomeType formData={formData} handleChange={handleChange} errors={errors} />

          <Divider />
          <FieldTitle title={type === "buyer" ? "Where are they looking to buy?" : "Where are they looking to sell?"} />
          <AddressInput formData={formData} handleChange={handleChange} userType={type} errors={errors} />

          <Divider />
          <FieldTitle title={type === "buyer" ? "Roughly, how much is their budget?" : "Roughly, how much is their home worth?"} />
          <BudgetSelect formData={formData} handleChange={handleChange} userType={type} errors={errors} />

          {type === "buyer" && (
            <>
              <Divider />
              <FieldTitle title="Are they pre-approved?" />
              <PreApproved formData={formData} handleChange={handleChange} errors={errors} />
            </>
          )}

          <Divider />
          <FieldTitle title={type === "buyer" ? "How soon are they looking to buy?" : "How soon are they looking to sell?"} />
          <Timeframe formData={formData} handleChange={handleChange} userType={type} errors={errors} />

          {type === "buyer" && (
            <>
              <Divider />
              <FieldTitle title="Is this their first time buying a home?" />
              <FirstTime formData={formData} handleChange={handleChange} errors={errors} />
            </>
          )}

          {type === "seller" && (
            <>
              <Divider />
              <FieldTitle title="Are they also looking to buy a home?" />
              <AlsoBuy formData={formData} handleChange={handleChange} errors={errors} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FormComponent;
