import React from 'react';
import { FormControl, FormControlLabel, RadioGroup, Radio } from '@mui/material';

const FirstTime = ({ formData, handleChange, errors }) => (
  <div className="flex flex-col space-y-2">
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="firstHome"
        name="firstHome"
        value={formData.firstHome}
        onChange={handleChange}
      >
        <FormControlLabel
          value={'true'}
          control={<Radio />}
          label={<div className="text-[#7E85A3] text-[14px] not-italic font-normal leading-[20px]">Yes</div>}
        />
        <FormControlLabel
          value={'false'}
          control={<Radio />}
          label={<div className="text-[#7E85A3] text-[14px] not-italic font-normal leading-[20px]">No</div>}
        />
      </RadioGroup>
    </FormControl>
    {errors.firstHome && (
      <div className="mt-1 text-sm text-red-500">{errors.firstHome}</div>
    )}
  </div>
);

export default FirstTime;
