import React from 'react';

const FieldTitle = ({title}) => {
 
  return (
    <div className="inline-flex items-start justify-start Row">
      <div className="inline-flex flex-col items-start justify-start gap-4 p-2 Col grow shrink basis-0">
        <div className="Title text-[#283567] text-base font-semibold leading-tight">{title}</div>
      </div>
    </div>
  );
};

export default FieldTitle;
