import axios from "axios";
import default_data from "values/default";
import Cookies from 'js-cookie';

const API_URL = default_data.API_URL + "/api/analytics";

const getInfluencerDashboardValues = (start_date,end_date) => {
    return axios.post(API_URL, 
        {
            "date_from" : start_date ,
            "date_to" : end_date
        }, { headers: { 'Authorization': 'Bearer ' + Cookies.get('referral_id'), 'Accept': 'application/json' } }
    ).then((response) => {
        return response.data;
    });
}

const getInfluencerMonthLeads = (year,month) => {
    return axios.post(API_URL + '/leads/month', 
        {
            "year" : year ,
            "month" : month
        }, { headers: { 'Authorization': 'Bearer ' + Cookies.get('referral_id'), 'Accept': 'application/json' } }
    ).then((response) => {
        return response.data;
    });
}

const getInfluencerYearLeads = (year) => {
    return axios.post(API_URL + '/leads/year', 
        {
            "year" : year 
        }, { headers: { 'Authorization': 'Bearer ' + Cookies.get('referral_id'), 'Accept': 'application/json' } }
    ).then((response) => {
        return response.data;
    });
}

const dashboardService = {
    getInfluencerDashboardValues,
    getInfluencerMonthLeads,
    getInfluencerYearLeads,
}

export default dashboardService;