import React from 'react';
import { FormControl, FormControlLabel, RadioGroup, Radio } from '@mui/material';

const TIMEFRAME = [
  { text: '0 - 3 Months', value: '0 - 3 Months' },
  { text: '3 - 6 Months', value: '3 - 6 Months' },
  { text: '6 - 12 Months', value: '6 - 12 Months' },
  { text: 'Over 1 Year', value: 'Over 1 Year' },
];

const Timeframe = ({ formData, handleChange, errors }) => (
  <div className="flex flex-col space-y-2">
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="timeframe"
        name="timeframe"
        value={formData.timeframe}
        onChange={handleChange}
      >
        {TIMEFRAME.map(option => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={<div className="text-[#7E85A3] text-[14px] not-italic font-normal leading-[20px]">{option.text}</div>}
          />
        ))}
      </RadioGroup>
    </FormControl>
    {errors.timeframe && (
      <div className="mt-1 text-sm text-red-500">{errors.timeframe}</div>
    )}
  </div>
);

export default Timeframe;
