import React, { useState, useEffect } from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MainForm from "./forms";
import ButtonLink from "components/global/buttonLink";
import Modal from "./forms/modal";
import { isAlpha, isEmail } from 'validator';
import AuthService from "services/auth.service";
import { useSelector } from 'react-redux';

const FormTabs = () => {

  const { agent } = useSelector((state) => state.data);

  useEffect(() => {
    if (agent.sfid) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        affiliate: agent.sfid,
      }));
    }
  }, [agent.sfid]);


  const [activeTab, setActiveTab] = useState('buyer');
  
  const [formData, setFormData] = useState({
    leadtype: 'buyer',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    permission: false,
    hometype: '',
    address: '',
    street: '',
    city: '',
    state: '',
    county: '',
    neighborhood: '',
    postalcode: '',
    price: '',
    status: '',
    timeframe: '',
    firstHome: null,
    buyingAndSelling: null,
    affiliate: '',
    leadsource: 'MAF Agent Referral',
  });
  
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const isNameValid = (name) => isAlpha(name, 'en-US', { ignore: ' -' });

  const isPhoneValid = (phone) => {
    const phoneRegex = /^[+]?[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/;
    return phoneRegex.test(phone);
  };

  const validateForm = () => {
    let validationErrors = {};
  
    if (!formData.firstName) {
      validationErrors.firstName = 'First name is required';
    } else if (!isNameValid(formData.firstName)) {
      validationErrors.firstName = 'First name is invalid';
    }
  
    if (!formData.lastName) {
      validationErrors.lastName = 'Last name is required';
    } else if (!isNameValid(formData.lastName)) {
      validationErrors.lastName = 'Last name is invalid';
    }
  
    if (!formData.email) {
      validationErrors.email = 'Email is required';
    } else if (!isEmail(formData.email)) {
      validationErrors.email = 'Email is invalid';
    }
  
    if (!formData.phone) {
      validationErrors.phone = 'Phone number is required';
    } else if (!isPhoneValid(formData.phone)) {
      validationErrors.phone = 'Phone number is invalid';
    }
  
    if (!formData.permission) {
      validationErrors.permission = 'You must check this box to create a referral';
    }
  
    if (activeTab === 'buyer') {
      if (!formData.status) {
        validationErrors.status = 'This field is required';
      }
      if (formData.firstHome === null || formData.firstHome === undefined) {
        validationErrors.firstHome = 'This field is required';
      }
    }
  
    if (activeTab === 'seller') {
      if (formData.buyingAndSelling === null || formData.buyingAndSelling === undefined) {
        validationErrors.buyingAndSelling = 'This field is required';
      }
    }
  
    if (!formData.hometype) {
      validationErrors.hometype = 'This field is required';
    }
    if (!formData.address) {
      validationErrors.address = 'This field is required';
    }
    if (!formData.price) {
      validationErrors.price = 'This field is required';
    }
    if (!formData.timeframe) {
      validationErrors.timeframe = 'This field is required';
    }
  
    setErrors(validationErrors);
  
    return Object.keys(validationErrors).length === 0;
  };
  

  const resetForm = () => {

    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      permission: false,
      hometype: '',
      address: '',
      street: '',
      city: '',
      state: '',
      county: '',
      neighborhood: '',
      postalcode: '',
      price: '',
      status: '',
      timeframe: '',
      firstHome: null,
      buyingAndSelling: null,
      affiliate: '',
    });

    setErrors({});
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    resetForm();
    setFormData((prevFormData) => ({
      ...prevFormData,
      leadtype: tab,
    }));
  };

  const handleCloseModal = (e) => {
    e.preventDefault();
    setShowModal(false);
    resetForm();
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    const processedValue = (name === 'firstHome' || name === 'buyingAndSelling')
    ? (value === 'true')
    : value;
  
    if (name === 'addressFields') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...value,
      }));
    } else {
      setFormData({
        ...formData,
        [name]: type === 'checkbox' ? checked : processedValue,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateForm();
  
    if (isValid) {
      const submissionData = {
        ...formData,
        prop_location: formData.address,
        lastname: `${formData.firstName} ${formData.lastName}`,
      };
  
      AuthService.createLead(submissionData).then(
        (response) => {
          const res = response.data.response;
          setLoading(false);
          setShowModal(true);
        },(error) => {
          const resMessage =error.response.data.response.error
          setLoading(false);
          setErrors(true);
        }
      );
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="flex max-w-[700px] flex-col items-start gap-[32px] mx-[auto] my-[0] mb-8">
        <div className="flex items-start self-stretch justify-center sm:justify-start">
          <div className="flex flex-col items-start self-stretch justify-start PageTitle">
            <div className="flex flex-col items-start justify-start gap-1 p-1 sm:flex-row Tabs rounded-xl">
              <div
                className={`Tab min-w-[fit-content] px-[22px] py-1.5 ${activeTab === 'buyer' ? 'bg-white rounded-lg shadow' : ''} rounded-lg justify-start items-center gap-2 flex cursor-pointer`}
                onClick={() => handleTabClick('buyer')}
              >
                <div className="Text text-[#283567] text-base font-normal leading-snug">Add Buyer Referral</div>
              </div>
              <div
                className={`Tab min-w-[fit-content] px-[22px] py-1.5 ${activeTab === 'seller' ? 'bg-white rounded-lg shadow' : ''} rounded-lg justify-start items-center gap-2 flex cursor-pointer`}
                onClick={() => handleTabClick('seller')}
              >
                <div className="Text text-[#283567] text-base font-normal leading-snug">Add Seller Referral</div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-start gap-[40px] self-stretch">
          <div className="flex flex-col items-start gap-[24px] self-stretch">
            <div className="ManualAddBuyer w-full max-w-[700px] flex-col justify-start items-start gap-8 inline-flex">
              <MainForm
                type={activeTab}
                formData={formData}
                handleChange={handleChange}
                errors={errors}
              />
            </div>
          </div>
        </div>

        <div className="flex items-start gap-[24px] self-stretch flex-row justify-end">
          <ButtonLink
            onClick={handleSubmit}
            text="Submit Referral"
            href="#"
            className="flex flex-row text-[#FFF] text-center text-[14px] not-italic font-semibold leading-[14px] px-[24px] py-[15px] justify-center items-center self-stretch rounded-[8px] bg-[#434CA0] hover:bg-[#373E82] [box-shadow:0px_1px_2px_0px_rgba(0,_0,_0,_0.15)]"
          />
        </div>
      </div>
      <Modal
        isOpen={showModal}
        onClose={handleCloseModal}
      />
      <Footer />
    </DashboardLayout>
  );
};

export default FormTabs;
