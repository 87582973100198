import React from 'react';
import { FormControl, FormControlLabel, RadioGroup, Radio } from '@mui/material';

const PreApproved = ({ formData, handleChange }) => (
  <div className="flex flex-col space-y-2">
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="status"
        name="status"
        value={formData.status}
        onChange={handleChange}
      >
        <FormControlLabel
          value="I am in the process of getting pre-approved"
          control={<Radio />}
          label={<div className="text-[#7E85A3] text-[14px] not-italic font-normal leading-[20px]">Yes</div>}
        />
        <FormControlLabel
          value="Haven't Applied Yet"
          control={<Radio />}
          label={<div className="text-[#7E85A3] text-[14px] not-italic font-normal leading-[20px]">No</div>}
        />
        <FormControlLabel
          value="I don't know"
          control={<Radio />}
          label={<div className="text-[#7E85A3] text-[14px] not-italic font-normal leading-[20px]">I don't know</div>}
        />
      </RadioGroup>
    </FormControl>
  </div>
);

export default PreApproved;
