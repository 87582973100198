import React, { useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateAgentDetails } from 'store/userSlice';
import ButtonLink from 'components/global/buttonLink';
import default_data from "values/default";
import AuthService from 'services/auth.service';

const PlanModal = ({ isOpen, onClose, planName, activePlan, price, referralFee, activePrice, activeReferralFee, isCancelingPlan }) => {
  const modalRef = useRef(null);
  const scrollPositionRef = useRef(0);

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.data);
  const agent = userDetails.agent;
  const user = userDetails.user;

  const handleConfirmChange = (e) => {
    e.preventDefault();

    const payload = {};
    payload.subscription_plan = planName;
    payload.subscription_status = default_data.subscription_status.pending;

    AuthService.updateCompany(payload)
      .then(() => {

        dispatch(updateAgentDetails(payload));

        if (activePlan === default_data.subscription_plans.free_profile && payload.subscription_plan !== default_data.subscription_plans.free_profile) {
          const params = `First=${agent.firstname}&Last=${agent.lastname}&Email=${user.email}&GUID=${user.uuid}&Choose_your_plan=${payload.subscription_plan}`;
          window.location.href = 'https://boomtownroi.formstack.com/forms/myagentfinder_upgrade?' + params;

        } else {
          window.location.href = '/plan-submitted';
        }

        onClose();
      });
  };

  const lockScroll = useCallback(() => {
    scrollPositionRef.current = window.scrollY || window.pageYOffset;
    document.body.style.overflow = 'hidden';
  }, []);

  const unlockScroll = useCallback(() => {
    document.body.style.overflow = '';

  }, []);

  useEffect(() => {
    if (isOpen) {
      lockScroll();
    } else {
      unlockScroll();
    }

    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose, lockScroll, unlockScroll]);

  useEffect(() => {
    return () => {
      unlockScroll();
    };
  }, [unlockScroll]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[99999] flex px-[16px] py-0 gap-[4px] items-center justify-center bg-black bg-opacity-50 ModalBackdrop">
      <div ref={modalRef} className="">
        <div className="ConfirmationDesktop relative flex w-full sm:w-[500px] px-[24px] py-[28px] flex-col items-center gap-[16px] rounded-[12px] bg-[#FFF] [box-shadow:0px_2px_6px_0px_rgba(0,_0,_0,_0.13)]">
          <ButtonLink
            className="Close w-3.5 absolute right-[24px] top-[24px] p-0"
            onClick={(e) => {e.preventDefault(); onClose();}}
            href="#"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="none">
              <path d="M2 1.70117L12 11.7012" stroke="#283567" strokeWidth="2" />
              <path d="M12 1.70117L2 11.7012" stroke="#283567" strokeWidth="2" />
            </svg>
          </ButtonLink>

          <div className="Slot1 flex flex-col items-center gap-[8px] self-stretch">
            <div className="Headline self-stretch text-[#283567] text-[25px] font-bold leading-loose">Confirm Plan Change</div>
          </div>

          {isCancelingPlan &&
            <div className="flex flex-col items-start gap-[8px] self-stretch">
              <div className="text-[#283567] text-[16px] not-italic font-normal leading-[140%]">If you proceed, your plan will be updated at the end of your current subscription period.</div>
            </div>
          }

          <div className="flex flex-col items-start self-stretch justify-start gap-2 py-4 Frame14190">
            <div className="flex flex-col items-start self-stretch justify-start gap-4 Frame45">
              <div className="flex flex-col items-start self-stretch justify-start gap-2 Frame88">
                <div className="inline-flex items-start self-stretch justify-between Frame14183">
                  <div className="text-[#283567] text-[16px] font-normal leading-[140%]">Current Plan</div>
                  <div className="Text text-[#283567] text-base font-semibold leading-tight">{activePlan}</div>
                </div>
                <div className="inline-flex items-start self-stretch justify-between Frame14184">
                  <div className="text-[#283567] text-[16px] font-normal leading-[140%]">Price</div>
                  <div className="Text text-[#283567] text-base font-semibold leading-tight">${activePrice} / month</div>
                </div>
                <div className="inline-flex items-start self-stretch justify-between Frame14185">
                  <div className="text-[#283567] text-[16px] font-normal leading-[140%]">Referral Fee</div>
                  <div className="Text text-[#283567] text-base font-semibold leading-tight">{activeReferralFee}</div>
                </div>
              </div>
            </div>

            <div className="Divider flex w-full flex-row h-8 pt-6 pb-6 justify-start items-start gap-2.5 ">
              <span className="Line h-0.5 w-full bg-[#CACCE4]"></span>
            </div>

            <div className="flex flex-col items-start self-stretch justify-start gap-4 Frame14189">
              <div className="flex flex-col items-start self-stretch justify-start gap-2 Frame88">
                <div className="inline-flex items-start self-stretch justify-between Frame14183">
                  <div className="text-[#283567] text-[16px] font-normal leading-[140%]">New Plan</div>
                  <div className="Text text-[#283567] text-base font-semibold leading-tight">{planName}</div>
                </div>
                <div className="inline-flex items-start self-stretch justify-between Frame14184">
                  <div className="text-[#283567] text-[16px] font-normal leading-[140%]">Price</div>
                  <div className="Text text-[#283567] text-base font-semibold leading-tight">{price} / month</div>
                </div>
                <div className="inline-flex items-start self-stretch justify-between Frame14185">
                  <div className="text-[#283567] text-[16px] font-normal leading-[140%]">Referral Fee</div>
                  <div className="Text text-[#283567] text-base font-semibold leading-tight">{referralFee}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end items-center gap-[16px] self-stretch">
            <ButtonLink
              text="Cancel"
              href="#"
              onClick={(e) => {e.preventDefault(); onClose();}}
              className="ButtonText text-center text-[#6471ed] text-sm font-bold leading-tight flex-[1_0_0]"
              >
            </ButtonLink>
            <ButtonLink
              onClick={(e) => { handleConfirmChange(e); }}
              href="#"
              text={isCancelingPlan ? 'Confirm Downgrade*' : 'Confirm Plan'}
              className={`text-sm font-bold leading-tight text-right text-white grow shrink basis-0 px-6 py-3 rounded-lg justify-center items-center gap-2.5 flex 
                ${isCancelingPlan ? 'bg-[#EB2C28] hover:bg-[#CD1713]' : 'bg-[#434ca0] hover:bg-[#373E82]'}`}
            >
            </ButtonLink>
          </div>

          {isCancelingPlan &&
            <div className="flex flex-col items-start gap-[8px] self-stretch">
              <div className="text-[#283567] text-[14px] italic text-center leading-[140%]">*To ensure prompt action, please submit your request at least 5 days prior to your next billing date.</div>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default PlanModal;
