// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import {useParams,Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { CSVLink } from 'react-csv';
import MDButton from "components/MDButton";

import ReferralsOutboundService from "services/referrals_outbound.service";

import { Circles } from "react-loader-spinner";
import { SvgIcon } from "@mui/material";
import Title from "components/global/title";

function DataTablesOutbound(props) {
	const { index, state } = useParams();
	const [tableData, setTabledata] = useState(  {columns: [
		{ Header: "Action", accessor: "action", width: "20%" },
		{ Header: "Contact Info", accessor: "phone", width: "25%" },
		{ Header: "Details", accessor: "priceRange" },
		{ Header: "Location", accessor: "propertyCity", width: "7%" },
		{ Header: "Status", accessor: "currentStatus", width:"5%"},
		{ Header: "Date Assigned", accessor: "assignedDate" },
		{ Header: "Updates", accessor: "updateWithinDays" },
	  ],
	  rows : [],});
	const [opportunities, setOpportunities] = useState([]);
	const [helpertext, setHelpertext] = useState("");
	const [loading,setLoading] = useState(true);

	function EditIcon() {
		return (
			<SvgIcon {...props} sx={{ width: "30px", height: "24px" }}>
				<svg width="30" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM16 12.75H12.75V16C12.75 16.41 12.41 16.75 12 16.75C11.59 16.75 11.25 16.41 11.25 16V12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H11.25V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75Z" fill="#434CA0"/>				</svg>
			</SvgIcon>
		);
	}

	const [csvData,setCsvData] = useState([]);
	const exportcolumns = [
		"assignedDate","buyerSeller","affiliateOpportunity","currentStatus","firstName","lastName","phone","oppemail","existingMortgageStatus","propertyStreet","propertyCity","propertyState","propertyZip","typeOfHome","priceRange","timeframe","reasonForBuyingSelling","referralFee","salesPrice","channelwebsite",
	];
	const handleExportCsv = () => {
		const fields = tableData.rows.map((item) => {
			const fieldValues = {};
			exportcolumns.forEach((fieldName) => {
			  fieldValues[fieldName] = item[fieldName];
			});
			return fieldValues;
		  });
		  setCsvData(fields);
	};

	const filterOpps = (opps, state) => {
		let dataTableData = {
			columns: [
				{
					Header: "Action", accessor: "id", Cell: ({ value }) =>
						<Link to={"/outbound/" + value}>
							<EditIcon/>
						</Link>

				},
			  { Header: "Contact Info", accessor: "contact",      Cell: ({ value: [firstname, lastname, mobile_number] }) => (
				  <>
				  <MDTypography variant="subtitle2">{firstname} {lastname}</MDTypography>
				  <MDTypography variant="subtitle2">{mobile_number}</MDTypography>
				  </>
			  ), width: "25%" },
			  { Header: "Details", accessor: "details" ,Cell: ({ value: [range, buyerseller] }) => (
				<>
				<MDTypography variant="subtitle2">{buyerseller}</MDTypography>
				<MDTypography variant="subtitle2">{range}</MDTypography>
				</>
			),},
			  { Header: "Location", accessor: "location",Cell: ({ value: [street, city, state,zip] }) => (
				<>
				<MDTypography variant="subtitle2">{street}</MDTypography>
				<MDTypography variant="subtitle2">{city},{state}</MDTypography>
				<MDTypography variant="subtitle2">{zip}</MDTypography>
				</>
			), width: "7%" },
			{ Header: "Opportunity", accessor: "affiliateOpportunity", Cell : ({ value}) => 
			<MDTypography variant="subtitle2">$ {value}</MDTypography>
			 ,width: "7%" },
			  { Header: "Status", accessor: "currentStatus", width:"5%"},
			  { Header: "Source", accessor: "referralSource", width:"5%"},
			  { Header: "Date Submitted", accessor: "dateSubmitted" },
			],
			rows : [],
		  };
		setTabledata (dataTableData);
		dataTableData.rows = opps;
		Object.values(dataTableData.rows).map((opp) => {
			opp.contact = [opp.firstName,opp.lastName,opp.phone];
			opp.details = [opp.priceRange,opp.buyerSeller]; 
			opp.location = [opp.propertyStreet,opp.propertyCity,opp.propertyState,opp.propertyZip];
	  });
		  setTabledata(dataTableData);
	}

	useEffect(() => {
		setOpportunities([]);
		setLoading(true);
		ReferralsOutboundService.getOutboundReferrals(state).then((response) => {
			if(state == 'prospects') {
				setHelpertext("Here you can view all of your referrals that have been assigned an agent! During this stage the agent that has received the referral is either trying to reach the client, communicating, or have an appointment lined up. Click on the referrals name to see more information.");
			} else if(state == 'clients'){
				setHelpertext("Here you can view all of your referrals that have been moved to the client stage! During this stage they are working with their assigned agent and either submitting offers, seeing properties, have signed a listing agreement, or have listed their property. Click on the referrals name to see more information.");
			} else if(state == 'under-contract'){
				setHelpertext("Here you can view all of your referrals that you under contract and/or in escrow! Click on the referrals name to see more information.");
			} else if(state == 'nurture'){
				setHelpertext("Here you can view all of your referrals that need more long term nurturing! During this stage they may be set up on an MLS search drip and/or followed up with until they are ready to move to the next stage.")
			} else if(state == 'closed'){
				setHelpertext("Here you can view all of your referrals that have closed! You will receive an email when the referral fee has been received and can expectant your check to be mailed out on the next Thursday. It is also during this stage that we send you a virtual high-five and do a victory dance. Woohoo!");
			} else if(state == 'cancelled'){
				setHelpertext("Here you can view all of your referrals that are no longer working with their agent. During this stage they have either become unresponsive, declared they are working with another agent, are a duplicate lead, etc. Our client concierge will continue to try to reach these referrals to verify their situation and try to reconnect with them.")
			}
			setOpportunities(response);
			filterOpps(response,state);
			setLoading(false);
		})
	}, [state]);

	useEffect(() => {
		if(opportunities.length > 0){
			filterOpps(opportunities, state);
		}
	}, [opportunities]);

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox pb={3}>
				<MDBox pb={3}>
					<Title
						className="text-[30px] leading-[50px]"
						tags="h1"
						text={`${index.charAt(0).toUpperCase() + index.slice(1)} : ${state.charAt(0).toUpperCase() + state.slice(1)}`}
					/>
					<MDTypography>
						{helpertext}
					</MDTypography>
				</MDBox>
				<Card pt={3}>
					<MDBox p={3} lineHeight={1}>
						<MDTypography variant="h4" color="soliddark">
							Referrals
						</MDTypography>
					</MDBox>
					{loading ? 
					<>
					<MDBox pb={3} sx={{margin: "auto"}}>
						<Circles
						height="80"
						width="80"
						color="#434ca0"
						ariaLabel="circles-loading"
						wrapperStyle={{}}
						wrapperClass=""
						visible={true}
						/>
						</MDBox>
						</>
						:
					 <DataTable table={tableData} canSearch />
					}
				</Card>
				<MDBox ml={1} pt={4} align="right">
					<MDButton variant="gradient" color="solidPrimary" sx={{fontSize : "0.875rem", textTransform : "lowercase"}} onClick={handleExportCsv}>
						<CSVLink style= {{color: "#FFFF"}} data={csvData} filename={'Referral-data.csv'}>
							Export CSV
						</CSVLink>
					</MDButton>
				</MDBox>
			</MDBox>
		</DashboardLayout>
	);
}

export default DataTablesOutbound;
