import React from 'react';
import MDInput from "components/MDInput";
import CheckboxField from './checkbox';

const ContactInformation = ({ formData, handleChange, errors }) => (
  <div className="inline-flex flex-col items-start justify-start Fields">
    <div className="flex flex-col items-start self-stretch justify-start w-full sm:flex-row Row">
      <div className="inline-flex flex-col items-start justify-start p-2 Col grow shrink basis-0">
        <MDInput
          name="firstName"
          fullWidth
          label="First Name"
          inputProps={{ type: "text" }}
          value={formData.firstName}
          onChange={handleChange}
          error={!!errors.firstName}
        />
        {errors.firstName && (
          <div className="mt-1 text-sm text-red-500">{errors.firstName}</div>
        )}
      </div>

      <div className="inline-flex flex-col items-start justify-start w-full p-2 Col grow shrink basis-0">
        <MDInput
          name="lastName"
          fullWidth
          label="Last Name"
          inputProps={{ type: "text" }}
          value={formData.lastName}
          onChange={handleChange}
          error={!!errors.lastName}
        />
        {errors.lastName && (
          <div className="mt-1 text-sm text-red-500">{errors.lastName}</div>
        )}
      </div>
    </div>

    <div className="flex flex-col items-start self-stretch justify-start w-full sm:flex-row Row">
      <div className="inline-flex flex-col items-start justify-start w-full p-2 Col grow shrink basis-0">
        <MDInput
          name="email"
          fullWidth
          label="Email Address"
          inputProps={{ type: "email" }}
          value={formData.email}
          onChange={handleChange}
          error={!!errors.email}
        />
        {errors.email && (
          <div className="mt-1 text-sm text-red-500">{errors.email}</div>
        )}
      </div>
      <div className="inline-flex flex-col items-start justify-start w-full p-2 Col grow shrink basis-0">
        <MDInput
          name="phone"
          fullWidth
          label="Phone Number"
          inputProps={{ type: "text" }}
          value={formData.phone}
          onChange={handleChange}
          error={!!errors.phone}
        />
        {errors.phone && (
          <div className="mt-1 text-sm text-red-500">{errors.phone}</div>
        )}
      </div>
    </div>

    <div className="inline-flex items-start self-stretch justify-start Row">
      <div className="inline-flex flex-col items-start justify-start w-full p-2 Col grow shrink basis-0">
        <CheckboxField
          label={<div className="text-[#7E85A3] text-[14px] not-italic font-normal leading-[20px]">I have permission to share this individual's information and they agree to be contacted by a Realtor</div>}
          name="permission"
          checked={formData.permission}
          onChange={handleChange}
        />
        {errors.permission && (
          <div className="mt-1 text-sm text-red-500">{errors.permission}</div>
        )}
      </div>
    </div>
  </div>
);

export default ContactInformation;
