// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function ContactUs() {
    return (
        <DashboardLayout>
        <DashboardNavbar />
            <Grid container  direction="column" height="80vh">
                <Grid item xs>
                    <MDBox >
                        <Grid container spacing={3} justifyContent="center">
                            <Grid item xs={12} lg={8}>
                                <MDTypography align="left" variant="h2" pt={5} pb={4} color="soliddark"><b>Contact Us</b></MDTypography>
                                <MDBox bgColor="white" p={3} sx={{ borderRadius: "12px" }}>
                                    <MDBox display="flex" justifyContent="space-between" alignItems="center">
                                        <MDTypography variant="h4" color="soliddark">Questions? </MDTypography>
                                    </MDBox>
                                    <MDTypography variant="subtitle2" color="soliddark">Contact Allie</MDTypography>
                                    <MDTypography variant="subtitle2" color="soliddark">Email: <MDTypography variant="subtitle2" color="solidinfo" display="inline">allie.gardner@boomtownroi.com</MDTypography></MDTypography>
                                </MDBox>
                            </Grid>
                        </Grid>
                    </MDBox>
                </Grid>
                <Grid item style={{ alignSelf: 'flex-start' }}>
                    <Footer />
                </Grid>
            </Grid>
        </DashboardLayout>
    );
}

export default ContactUs;
