import { useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import AuthService from "services/auth.service";

function Login({ userdata }) {
	const [email, setEmail] = useState(userdata.email);
	const [password,setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [valid,setValid] = useState(true);

	const onchangedata = (e) => {
		if(e.target.name == 'email'){
			setEmail(e.target.value);
			setValid(false);
		}
		if(e.target.name =='password'){
			setValid(true);
			setPassword(e.target.value);
		}
		if(e.target.name == 'confirm_password'){
			(e.target.value == password) ? setValid(false) : setValid(true);
			setConfirmPassword(e.target.value);
		}
		
	}

	const onClickUpdate = () => {
		let userData = {};
		if(userdata.email != email) {
			if(password.length > 0){
				userData.change_password = password;
			}
			userData.email = email;
		}else{
			userData.change_password = password;
		}
		AuthService.updateLogin(userData).then((res) => {
			window.location.reload();
		})
	}

  return (
    <>
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} mb={4} justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={2}>
                <MDTypography variant="h3">Login Info</MDTypography>
              </MDBox>
              <MDBox pt={2} px={2}>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={12}>
                    <MDInput
                      fullWidth
                      label="Email Address (Your Login)"
                      value={email}
					  name="email"
					  onChange = {onchangedata}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <MDInput
                      fullWidth
                      label="New Password"
                      inputProps={{ type: "password", autoComplete: "" }}
					  name = "password"
					  value={password}
					  onChange = {onchangedata}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} pb={3}>
                    <MDInput
                      fullWidth
                      label="Confirm New Password"
                      inputProps={{ type: "password", autoComplete: "" }}
					  value={confirmPassword}
					  name = "confirm_password"
					  onChange = {onchangedata}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        <MDBox mt={4} mb={1} mr={29} align="right">
          <MDButton align="right" variant="gradient" disabled={valid} color="solidPrimary" sx={{ fontSize: "0.875rem" }} onClick={onClickUpdate}>
            Save Changes
          </MDButton>
        </MDBox>
      </MDBox>
    </>
  );
}

export default Login;
