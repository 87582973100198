import React from 'react';
import { FormControl } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "components/MDInput";

const BUDGET = [
  { text: 'Under $50K', value: 'Under $50,000' },
  { text: '$50k - $100k', value: '$50,000 - $100,000' },
  { text: '$100K - $150K', value: '$100,000 - $150,000' },
  { text: '$150K - $200K', value: '$200,000 - $250,000' },
  { text: '$250K - $300K', value: '$250,000 - $300,000' },
  { text: '$300K - $400K', value: '$300,000 - $400,000' },
  { text: '$400K - $500K', value: '$400,000 - $500,000' },
  { text: '$500K - $600K', value: '$500,000 - $600,000' },
  { text: '$600K - $800K', value: '$600,000 - $800,000' },
  { text: '$800K - $1M', value: '$800,000 - $1,000,000' },
  { text: 'Over $1M', value: 'Over $1,000,000' },
];

const BudgetSelect = ({ formData, handleChange, userType, errors }) => (
  <div className="flex flex-col items-start justify-start p-2 Col grow shrink basis-0">
    <FormControl fullWidth> 
      <Autocomplete
        disableClearable
        value={formData.price || ''}
        freeSolo={true}
        forcePopupIcon
        options={BUDGET.map(option => option.value)}
        onChange={(event, newValue) => handleChange({
          target: {
            name: 'price',
            value: newValue,
          }
        })}
        size="small"
        renderInput={(params) => (
          <MDInput
            {...params} 
            label={<span className="text-[#7E85A3] text-[14px] not-italic font-normal">{userType === "buyer" ? "Select Budget" : "Select Home Value"} </span>}
          />
        )}
        popupIcon={<KeyboardArrowDownIcon />}
        sx={{
          '& .MuiAutocomplete-inputRoot': {
            height: '44px',
            display: 'flex',
            alignItems: 'center',
          },
          '& .MuiAutocomplete-input': {
            fontSize: '14px',
          }
        }}
      />
      {errors.price && (
        <div className="mt-1 text-sm text-red-500">{errors.price}</div>
      )}
    </FormControl>
  </div>
);

export default BudgetSelect;
