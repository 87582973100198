// @mui material components
import Card from "@mui/material/Card";
import { useState } from "react";
import { Modal } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import FormHelperText from "@mui/material/FormHelperText";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

import AuthService from '../../../../services/auth.service';

import { useLocation } from 'react-router-dom';

function Update() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    
    const token = queryParams.get('token');
    const email = queryParams.get('email');

    const [password, setPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(false);
    const [open, setOpen] = useState(false);

    const centerModalStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    };

    const modalContentStyle = {
        backgroundColor: '#fff',
        padding: '20px',
    };

    const handleReset = (e) => {
        AuthService.UpdatePassword(email,token,password).then(
            (response) => {
              setOpen(true);
            }
          );

    }
    const handleClose = () => {
        setOpen(false);
    };

    const handlesubmit = () => {
        window.location = "/";
    }

    return (
        <CoverLayout image="/maf-logo.svg" coverHeight="38vh" maxWidth="700px">
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    mx={2}
                    mt={-3}
                    py={2}
                    mb={1}
                    textAlign="center"
                    sx={{
                        background: `linear-gradient(180deg, #838DF1 0%, #6471ED 100%);`
                    }}
                >
                    <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
                        Reset Password
                    </MDTypography>
                    <MDTypography display="block" variant="button" color="white" my={1}>
                        Please set your new password here
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox component="form" role="form">
                        <MDBox mb={4}>
                            <MDInput type="password" label="Password" variant="standard" fullWidth onChange={(event) => { setPassword(event.target.value); }} />
                        </MDBox>
                        <MDBox mb={4}>
                            <MDInput type="password" label="Retype Password" variant="standard" fullWidth onChange={(event) => { setConfirmPassword(event.target.value); (password !=event.target.value) ? setError(true): setError(false); }} />
                            {error && <FormHelperText error>Password doesn't match</FormHelperText>}
                        </MDBox>
                        <MDBox mt={6} mb={1}>
                            <MDButton variant="gradient" disabled={error}color="solidPrimary" fullWidth onClick={handleReset}>
                                Update Password
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </Card>
            <Modal open={open} onClose={handleClose} style={centerModalStyle}>
                <div style={modalContentStyle}>
                    <p>Password Reset successful</p>
                    <MDBox mt={6} mb={1}>
                        <MDButton variant="gradient" color="solidPrimary" fullWidth onClick={handlesubmit}>
                            Go to Home
                        </MDButton>
                    </MDBox>
                </div>
            </Modal>
        </CoverLayout>
    );
}

export default Update;
