// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React context
import CoverLayout from "layouts/authentication/components/CoverLayout";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

function NotFound(props) {
	return (
		<CoverLayout maxWidth = "600px">
			<Card>
				<MDBox
					variant="gradient"
					bgColor="info"
					borderRadius="lg"
					coloredShadow="info"
					mx={2}
					mt={-3}
					p={2}
					mb={1}
					textAlign="center"
					sx={{
						background: `linear-gradient(180deg, #838DF1 0%, #6471ED 100%);`
					}}
				>
					<MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
						Uh oh...
					</MDTypography>
				</MDBox>
				<MDBox pt={4} pb={3} px={3} textAlign="center">
                    <MDTypography variant = "h4" color="soliddark" p={2}> 404</MDTypography>
                    <MDTypography variant = "h6" color="lightneutrals700" pb={2}> This page could not be found.</MDTypography>
                    <Link to="/dashboard">
                        <MDTypography variant = "h6" color="solidinfo" fontWeight="bold">
                             Return to home
                        </MDTypography>
                        </Link>
				</MDBox>
			</Card>
		</CoverLayout>
	);
}

export default NotFound;
