import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import MDDatePicker from "components/MDDatePicker";
import MDAlert from "components/MDAlert";
// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import default_data from "values/default";

import AuthService from "services/auth.service";
import { updateCompanyDetails } from "store/userSlice";

function Company() {
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const companyFromRedux = useSelector((state) => state.data.companyDetails);

  const [companyDetails, setDetails] = useState(companyFromRedux);
  const [changes, setChanges] = useState({});
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({ visible: false, message: "" });

  useEffect(() => {
    setDetails(companyFromRedux);
  }, [companyFromRedux]);

  const onChangeState = (state_name) => {
    setDetails((prev) => ({
      ...prev,
      other_state: state_name,
    }));
    setChanges((prev) => ({
      ...prev,
      other_state: state_name,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
    setChanges((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: false,
    }));

    if (name === "broker_phone" || name === "main_phone" || name === "coordinator_phone" || name === "office_assistant_phone") {
      const inputPhoneNumber = value;
      let formattedPhoneNumber = inputPhoneNumber.replace(/\D/g, ""); // remove all non-numeric characters
      if (formattedPhoneNumber.length >= 3 && formattedPhoneNumber.length < 6) {
        formattedPhoneNumber = `(${formattedPhoneNumber.slice(0, 3)}) ${formattedPhoneNumber.slice(3)}`;
      } else if (formattedPhoneNumber.length >= 6) {
        formattedPhoneNumber = `(${formattedPhoneNumber.slice(0, 3)}) ${formattedPhoneNumber.slice(3, 6)}-${formattedPhoneNumber.slice(6, 10)}`;
      }
      setDetails((prev) => ({
        ...prev,
        [name]: formattedPhoneNumber,
      }));
      setChanges((prev) => ({
        ...prev,
        [name]: formattedPhoneNumber,
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!companyDetails.company_name) newErrors.company_name = true;
    if (!companyDetails.broker_name) newErrors.broker_name = true;
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onClickUpdate = () => {
    if (!validateForm()) {
      setAlert({ visible: true, message: "Please fill out all required fields." });
      return;
    }

    let updatedDetails = { ...companyDetails, ...changes };

    if (updatedDetails.other_state && updatedDetails.other_state.length > 2) {
      const stateObj = default_data.states.find((other_state) => other_state.name === updatedDetails.other_state);
      updatedDetails.other_state = stateObj ? stateObj.abbr : updatedDetails.other_state;
    }

    const payload = {};
    for (let key in changes) {
      if (changes.hasOwnProperty(key)) {
        payload[key] = updatedDetails[key];
      }
    }

    AuthService.updateCompany(payload).then(() => {
      dispatch(updateCompanyDetails(payload));
      window.location.reload();
    });
  };

  const handleDate = (e) => {
    const date = new Date(e);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const formattedDate = `${year}-${month}-${day}`;
    setDetails((prev) => ({
      ...prev,
      license_date: formattedDate,
    }));
    setChanges((prev) => ({
      ...prev,
      license_date: formattedDate,
    }));
  };

  return (
		<MDBox ref={formRef} mt={6} mb={3}>
			<Grid container spacing={3} mb={4} justifyContent="center">
				<Grid item xs={12} lg={8}>
					{alert.visible && (
            <MDAlert color="error" dismissible onClose={() => setAlert({ visible: false, message: "" })}>
              {alert.message}
            </MDAlert>
          )}
					<Card>
						<MDBox p={2}>
							<MDTypography variant="h3">Brokerage Details</MDTypography>
						</MDBox>
						<MDBox pt={2} px={2} pb={3}>
							<Grid container spacing={3}>
								<Grid item xs={12} lg={6}>
									<MDInput
										name="company_name"
										fullWidth
										label="Brokerage Agency Name * "
										inputProps={{ type: "text", autoComplete: "" }}
										value={companyDetails.company_name}
										onChange={handleChange}
										error={errors.company_name}
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<MDInput
										fullWidth
										label="Broker of Record * "
										inputProps={{ type: "text", autoComplete: "" }}
										name="broker_name"
										value={companyDetails.broker_name}
										onChange={handleChange}
										error={errors.broker_name}
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<MDInput
										fullWidth
										label="Broker License Number"
										inputProps={{ type: "text", autoComplete: "" }}
										name="license"
										value={companyDetails.license}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<MDTypography variant="overline" pr={2}>Broker License Exp</MDTypography>
									<MDDatePicker name="license_date" value={companyDetails.license_date} dateFormat="dd/MM/yyyy" onChange={handleDate} />
								</Grid>
								<Grid item xs={12} lg={6}>
									<MDInput
										fullWidth
										label="Broker email "
										inputProps={{ type: "text", autoComplete: "" }}
										name="broker_email"
										value={companyDetails.broker_email}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<MDInput
										fullWidth
										label="Broker Phone Number"
										inputProps={{ type: "text", autoComplete: "" }}
										name="broker_phone"
										value={companyDetails.broker_phone}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} lg={6} pb={3}>
									<MDInput
										fullWidth
										label="Office Phone number"
										inputProps={{ type: "text", autoComplete: "" }}
										name="main_phone"
										value={companyDetails.main_phone}
										onChange={handleChange}
									/>
								</Grid>
							</Grid>
							<MDTypography variant="h5">Broker's Mailing Address</MDTypography>
							<Grid container spacing={3} pt={3}>
								<Grid item xs={12} lg={6}>
									<MDInput
										fullWidth
										label="Street Address"
										inputProps={{ type: "text", autoComplete: "" }}
										name="other_street"
										value={companyDetails.other_street}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<MDInput
										fullWidth
										label="City"
										inputProps={{ type: "text", autoComplete: "" }}
										name="other_city"
										value={companyDetails.other_city}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<Autocomplete
										onChange={(event, value) => onChangeState(value)}
										value={companyDetails.other_state}
										options={default_data.state_names}
										renderInput={(params) => (
											<FormField {...params} label="State" InputLabelProps={{ shrink: true }} />
										)}
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<MDInput
										fullWidth
										label="Zip Code"
										inputProps={{ type: "text", autoComplete: "" }}
										name="other_postalcode"
										value={companyDetails.other_postalcode}
										onChange={handleChange}
									/>
								</Grid>
							</Grid>
						</MDBox>
					</Card>
					<MDBox align="right" mt={4} mb={1}>
						<MDButton variant="gradient" color="solidPrimary" sx={{ fontSize: "0.875rem" }} onClick={onClickUpdate}>
							Update Company Details
						</MDButton>
					</MDBox>
				</Grid>
				<Grid item xs={12} lg={8}>
					<Card>
						<MDBox p={2}>
							<MDTypography align="left" variant="h3">Transaction Coordinator</MDTypography>
						</MDBox>
						<MDBox pt={2} px={2}>
							<Grid container spacing={3} pb={3}>
								<Grid item xs={12} lg={6}>
									<MDInput
										fullWidth
										label="Transaction Coordinator Name"
										inputProps={{ type: "text", autoComplete: "" }}
										name="coordinator_name"
										value={companyDetails.coordinator_name}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<MDInput
										fullWidth
										label="Transaction Coordinator email"
										inputProps={{ type: "email", autoComplete: "" }}
										name="coordinator_email"
										value={companyDetails.coordinator_email}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<MDInput
										fullWidth
										label="Transaction Coordinator Phone"
										inputProps={{ type: "text", autoComplete: "" }}
										name="coordinator_phone"
										value={companyDetails.coordinator_phone}
										onChange={handleChange}
									/>
								</Grid>
							</Grid>
						</MDBox>
					</Card>
					<MDBox align="right" mt={4} mb={1}>
						<MDButton variant="gradient" color="solidPrimary" sx={{ fontSize: "0.875rem" }} onClick={onClickUpdate}>
							Update Coordinator Details
						</MDButton>
					</MDBox>
				</Grid>
				<Grid item xs={12} lg={8}>
					<Card>
						<MDBox p={2}>
							<MDTypography align="left" variant="h3">Office Assistant</MDTypography>
						</MDBox>
						<MDBox pt={2} px={2}>
							<Grid container spacing={3} pb={3}>
								<Grid item xs={12} lg={6}>
									<MDInput
										fullWidth
										label="Office Assistant Name"
										inputProps={{ type: "text", autoComplete: "" }}
										name="office_assistant_name"
										value={companyDetails.office_assistant_name}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<MDInput
										fullWidth
										label="Office Assistant email"
										inputProps={{ type: "email", autoComplete: "" }}
										name="office_assistant_email"
										value={companyDetails.office_assistant_email}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<MDInput
										fullWidth
										label="Office Assistant Phone"
										inputProps={{ type: "text", autoComplete: "" }}
										name="office_assistant_phone"
										value={companyDetails.office_assistant_phone}
										onChange={handleChange}
									/>
								</Grid>
							</Grid>
						</MDBox>
					</Card>
					<MDBox align="right" mt={4} mb={1}>
						<MDButton variant="gradient" color="solidPrimary" sx={{ fontSize: "0.875rem" }} onClick={onClickUpdate}>
							Update Assistant Details
						</MDButton>
					</MDBox>
				</Grid>
			</Grid>
		</MDBox>
  );
}

export default Company;
