import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDDatePicker from "components/MDDatePicker";
import Autocomplete from "@mui/material/Autocomplete";
import FormField from "layouts/pages/account/components/FormField";


import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

import AuthService from '../../../../../services/dashboard.service';
import { Circles, TailSpin } from "react-loader-spinner";

import DataTable from "examples/Tables/DataTable";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import LineChartLeadsMonth from "examples/Charts/LineCharts/LineChartLeadsMonth";
import Cookies from 'js-cookie';

import { useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';

import default_data from "values/default";


function InfluencerWidget() {
    const today_date = new Date();
    const [loading, setLoading] = useState(true);
    const [dashboard, setDashboard] = useState({
        "basic": [
            {
                "New_Users": 0,
                "Sessions": 0,
                "Views": 0
            }
        ],
        "basic_previous_percent": {
            "New_Users": "0%",
            "Sessions": "0%",
            "Views": "0%"
        },
        "device_ui": {
            "columns": [
                {
                    "Header": "DEVICE CATEGORY",
                    "accessor": "Device",
                    "width": "33%"
                },
                {
                    "Header": "FORM LOADS",
                    "accessor": "Sessions",
                    "width": "33%"
                },
                {
                    "Header": "FORM INTERACTIONS",
                    "accessor": "Engaged Sessions",
                    "width": "33%"
                },
            ],
            "rows": []
        },
        "region_ui": {
            "columns": [
                {
                    "Header": "REGION",
                    "accessor": "Region",
                    "width": "20%"
                },
                {
                    "Header": "SESSIONS",
                    "accessor": "Sessions",
                    "width": "20%"
                },
                {
                    "Header": "TOTAL USERS",
                    "accessor": "Total Users",
                    "width": "20%"
                },
                {
                    "Header": "ENGAGEMENT RATE",
                    "accessor": "Engagement Rate",
                    "width": "20%"
                },
                {
                    "Header": "VIEWS",
                    "accessor": "Views",
                    "width": "20%"
                }
            ],
            "rows": []
        },
        "region": [],
        "device": [],
        "leads_by_month": {
            "datasets": [
                {
                    "label": "leads",
                    "color": "info",
                    "data": []
                }
            ],
            "labels": []
        },
        "leads": {
            "lead_revenue": 0,
            "leads_generated": 0,
            "leads_qualified": 0
        },
        "leads_previous_percent": {
            "lead_revenue": "0%",
            "leads_generated": "0%",
            "leads_qualified": "0%"
        },
        "landing_page_url": null,
        "landing_page_status": null,
        "split_revenue": false,
        "google_data": {
            "total": {
                "FormLoads": 0,
                "FormClicks": 0
            },
            "device": [],
            "widget_location": [],
            "city": []
        },
        "placement_ui": {
            "columns": [
                {
                    "Header": "DEVICE CATEGORY",
                    "accessor": "Device",
                    "width": "33%"
                },
                {
                    "Header": "FORM LOADS",
                    "accessor": "Sessions",
                    "width": "33%"
                },
                {
                    "Header": "FORM INTERACTIONS",
                    "accessor": "Engaged Sessions",
                    "width": "33%"
                },
            ],
            "rows": []
        }
    });
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [analyticsloading, setAnalyticsLoading] = useState(false);
    const [monthlyleadsloading, setMonthlyLeadsLoading] = useState(true);
    const [yearlyleadsloading, setYearlyLeadsLoading] = useState(false);
    const [leadsbymonth, setLeadsByMonth] = useState([]);
    const [currentMonthName,setCurrentMonthName] = useState(today_date.toLocaleString('default', { month: 'long' }));
    const [currentYearLeads,setCurrentYear] = useState(today_date.getFullYear());
    const [YearLeads,setYearLeads] = useState(today_date.getFullYear());
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const influencer_cookie = Cookies.get('influencer_data');
        const today = new Date();
        const currentDate = today.getDate();
        const currentMonth = today.getMonth() + 1;
        const currentYear = today.getFullYear();
        const end_date = `${currentYear}-${currentMonth}-${currentDate}`;
        Cookies.get('end_date') ? setEndDate(Cookies.get('end_date')) : setEndDate(end_date);
        Cookies.set('end_date', end_date);
        const oneMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
        const oneMonthAgoDate = oneMonthAgo.getDate();
        const oneMonthAgoMonth = oneMonthAgo.getMonth() + 1;
        const oneMonthAgoYear = oneMonthAgo.getFullYear();
        const start_date = `${oneMonthAgoYear}-${oneMonthAgoMonth}-${oneMonthAgoDate}`;
        Cookies.get('start_date') ? setStartDate(Cookies.get('start_date')) : setStartDate(start_date);
        Cookies.set('start_date', start_date);
        if (influencer_cookie) {
            setLoading(false);
            setDashboard(JSON.parse(influencer_cookie));
        }
        // else if (Object.keys(dashboard).length == 0) {
            AuthService.getInfluencerDashboardValues(start_date, end_date).then((response) => {
                setLoading(false);
                setDashboard(response);
                Cookies.set('influencer_data', JSON.stringify(response));
            });
        //}
        AuthService.getInfluencerMonthLeads(currentYear,currentMonth).then((response)=> {
            setLeadsByMonth(response);
            setMonthlyLeadsLoading(false);
        })
    }, [])

    const handleDate_start = (e) => {
        const date = new Date(e);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        const formattedDate = `${year}-${month}-${day}`;
        setStartDate(formattedDate);
        Cookies.set('start_date', formattedDate);
    }

    const handleDate_end = (e) => {
        const date = new Date(e);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        const formattedDate = `${year}-${month}-${day}`;
        setEndDate(formattedDate);
        Cookies.set('end_date', formattedDate);
    }

    const handleChange = () => {
        setAnalyticsLoading(true);
        AuthService.getInfluencerDashboardValues(startDate, endDate).then((response) => {
            setAnalyticsLoading(false);
            setDashboard(response);
            Cookies.set('influencer_data', JSON.stringify(response));
        })
    }

    const handleLeadsByDayChange = () => {
        setMonthlyLeadsLoading(true);
        const monthNumber = new Date(`${currentMonthName} 1, 2000`).getMonth() + 1;
        AuthService.getInfluencerMonthLeads(currentYearLeads,monthNumber).then((response)=> {
            setLeadsByMonth(response);
            setMonthlyLeadsLoading(false);
        })
    }

    const handleLeadsByMonthChange = () => {
        setYearlyLeadsLoading(true);
        AuthService.getInfluencerYearLeads(YearLeads).then((response)=> {
            dashboard.leads_by_month = response;
            setDashboard(dashboard);
            setYearlyLeadsLoading(false);
        }) 
    }

	const onChangeMonth = (month_name) => {
		setCurrentMonthName(month_name);
	}

	const onChangeYear = (year) => {
		setCurrentYear(year);
	}
    const onChangeYearLeads = (year) => {
		setYearLeads(year);
	}

    return (
        <>
            <MDTypography variant="h4" color="soliddark" lineHeight={1.25} fontSize={"2.5rem"} mb={6}>
                Performance
            </MDTypography>
            {loading ?
                <>
                    <MDBox pb={3} sx={{ margin: "auto" }}>
                        <Circles
                            height="80"
                            width="80"
                            color="#434ca0"
                            ariaLabel="circles-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </MDBox>
                </> :
                <>
                    {dashboard.split_revenue ?
                        <MDBox mt={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6} lg={4}>
                                    <MDBox mb={1.5}>
                                        <MiniStatisticsCard
                                            title={{ text: "Total In Escrow" }}
                                            count={{ text: "$" + parseFloat(dashboard.opportunity.total_in_escrow).toLocaleString() }}
                                            percentage={{ color: "white", text: "" }}
                                            icon={{ color: "gradientgreen", component: "creators_escrow" }}
                                        />
                                    </MDBox>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <MDBox mb={1.5}>
                                        <MiniStatisticsCard
                                            title={{ text: "Total Pending Revenue" }}
                                            count={{ text: "$" + parseFloat(dashboard.opportunity.total_pending_revenue).toLocaleString() }}
                                            percentage={{ color: "white", text: "" }}
                                            icon={{ color: "gradientgreen", component: "creators_pending_revenue" }}
                                        />
                                    </MDBox>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <MDBox mb={1.5}>
                                        <MiniStatisticsCard
                                            title={{ text: "Total Closed Revenue" }}
                                            count={{ text: "$" + parseFloat(dashboard.opportunity.total_closed_revenue).toLocaleString() }}
                                            percentage={{ color: "white", text: "" }}
                                            icon={{ color: "gradientgreen", component: "creators_closed_revenue" }}
                                        />
                                    </MDBox>
                                </Grid>
                            </Grid>
                        </MDBox>
                        : <></>}
                        
                        <>
                            <MDBox bgColor="white" display="flex" mt={3} sx={{ borderRadius: "12px", boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.13) !important" }}>
                                <Grid container p={3} sx={{ alignItems: "center", justifyContent: "space-between" }}>
                                    <Grid align="left" item pb={isMobile ? 1 : 0} xs={12} lg={4} md={4}>
                                        <MDTypography variant="h4" color="soliddark">
                                            Filter Dates
                                        </MDTypography>
                                    </Grid>
                                    <Grid align="center" item xs={12} lg={3} md={2}>
                                        <MDTypography variant="overline" pr={2}>Start Date</MDTypography>
                                        <MDDatePicker input={{ placeholder: "Select a Start date" }} value={startDate} name='start_date' dateFormat="dd/MM/yyyy" onChange={handleDate_start} />
                                    </Grid>
                                    <Grid align="center" item pt={1} pb={1} xs={12} lg={3} md={2}>
                                        <MDTypography variant="overline" pr={2}>End Date </MDTypography>
                                        <MDDatePicker input={{ placeholder: "Select a End date" }} value={endDate} name='end_date' dateFormat="dd/MM/yyyy" onChange={handleDate_end} />
                                    </Grid>
                                    <Grid align="right" mr={1} item xs={12} lg={1} md={1}>
                                        <MDButton variant="gradient" color="solidPrimary" fullWidth={isMobile} pt={3} pb={3} px={3} pl={2} sx={{ fontSize: "0.875rem", textTransform: "capitalize" }} onClick={handleChange}>
                                            Apply
                                        </MDButton>
                                    </Grid>
                                </Grid>
                            </MDBox>


                            <MDBox mt={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <MDBox mb={1.5}>
                                            {analyticsloading ? <TailSpin height="100" width="100" color="#6471ED" ariaLabel="tail-spin-loading" radius="1" wrapperStyle={{}} wrapperClass="" visible={true} />
                                                :
                                                <ComplexStatisticsCard
                                                    color="gradientgreen"
                                                    icon={dashboard.split_revenue ? "totalReferrals" : "leadRevenue"}
                                                    title={dashboard.split_revenue ? "Total Referrals" : "Lead Revenue"}
                                                    count={dashboard.split_revenue ? dashboard.opportunity.total_referrals : '$'+dashboard.leads.lead_revenue}
                                                    helper
                                                    helperText = {dashboard.split_revenue ? "This is the total number of referrals that have been assigned an agent." : "This is the affiliate commission you have earned calculated from your leads generated."}
                                                    percentage={{
                                                        color: dashboard.split_revenue ?
                                                            parseFloat(dashboard.opportunity.total_referrals_percent, 10) >= 0 ? "success" : "error"
                                                            :
                                                            parseFloat(dashboard.leads_previous_percent.leads_generated, 10) >= 0 ? "success" : "error",
                                                        amount: dashboard.split_revenue ?
                                                            (parseFloat(dashboard.opportunity.total_referrals_percent, 10) >= 0 ? "+" : "") + dashboard.opportunity.total_referrals_percent
                                                            :
                                                            (parseFloat(dashboard.leads_previous_percent.leads_generated, 10) >= 0 ? "+" : "") + dashboard.leads_previous_percent.leads_generated,
                                                        label: "Compared to last period",
                                                    }}
                                                />}
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <MDBox mb={1.5}>
                                            {analyticsloading ? <TailSpin height="100" width="100" color="#6471ED" ariaLabel="tail-spin-loading" radius="1" wrapperStyle={{}} wrapperClass="" visible={true} />
                                                :
                                                <ComplexStatisticsCard
                                                    color="gradientIris"
                                                    icon="qualifiedLeads"
                                                    title="Qualified Leads"
                                                    helper
                                                    helperText="This is the total number of referrals that were ready and attempted to be assigned an agent."
                                                    count={dashboard.leads.leads_qualified.toLocaleString()}
                                                    percentage={{
                                                        color: parseFloat(dashboard.leads_previous_percent.leads_qualified, 10) >= 0 ? "success" : "error",
                                                        amount: (parseFloat(dashboard.leads_previous_percent.leads_qualified, 10) >= 0 ? "+" : "") + dashboard.leads_previous_percent.leads_qualified,
                                                        label: "Compared to last period",
                                                    }}
                                                />}
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <MDBox mb={1.5}>
                                            {analyticsloading ? <TailSpin height="100" width="100" color="#6471ED" ariaLabel="tail-spin-loading" radius="1" wrapperStyle={{}} wrapperClass="" visible={true} />
                                                :
                                                <ComplexStatisticsCard
                                                    color="gradientViolet"
                                                    icon="formLoads"
                                                    title="Form Loads"
                                                    count={dashboard.google_data.total.FormLoads.toLocaleString()}
                                                    percentage={{
                                                        color: parseFloat(dashboard.basic_previous_percent.New_Users, 10) > 0 ? "success" : "error",
                                                        amount: (parseFloat(dashboard.basic_previous_percent.New_Users, 10) > 0 ? "+" : "") + dashboard.basic_previous_percent.New_Users,
                                                        label: "Compared to last period",
                                                    }}
                                                />}
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <MDBox mb={1.5}>
                                            {analyticsloading ? <TailSpin height="100" width="100" color="#6471ED" ariaLabel="tail-spin-loading" radius="1" wrapperStyle={{}} wrapperClass="" visible={true} />
                                                :
                                                <ComplexStatisticsCard
                                                    color="gradientIris"
                                                    icon="formClicks"
                                                    title="Form Clicks"
                                                    count={dashboard.google_data.total.FormClicks.toLocaleString()}
                                                    percentage={{
                                                        color: parseFloat(dashboard.basic_previous_percent.Sessions, 10) > 0 ? "success" : "error",
                                                        amount: (parseFloat(dashboard.basic_previous_percent.Sessions, 10) > 0 ? "+" : "") + dashboard.basic_previous_percent.Sessions,
                                                        label: "Compared to last period",
                                                    }}
                                                />}
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <MDBox mb={1.5}>
                                            {analyticsloading ? <TailSpin height="100" width="100" color="#6471ED" ariaLabel="tail-spin-loading" radius="1" wrapperStyle={{}} wrapperClass="" visible={true} />
                                                :
                                                <ComplexStatisticsCard
                                                    color="gradientPeach"
                                                    icon="leadsGenerated"
                                                    title="Leads Generated"
                                                    count={dashboard.leads.leads_generated.toLocaleString()}
                                                    percentage={{
                                                        color: parseFloat(dashboard.leads_previous_percent.leads_generated, 10) >= 0 ? "success" : "error",
                                                        amount: (parseFloat(dashboard.leads_previous_percent.leads_generated, 10) >= 0 ? "+" : "") + dashboard.leads_previous_percent.leads_generated,
                                                        label: "Compared to last period",
                                                    }}
                                                />}
                                        </MDBox>
                                    </Grid>
                                </Grid>
                            </MDBox>
                            <MDBox mt={4} sx={{ borderRadius: "12px", boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.13) !important" }}>
                                {analyticsloading ? <TailSpin height="100" width="100" color="#6471ED" ariaLabel="tail-spin-loading" radius="1" wrapperStyle={{}} wrapperClass="" visible={true} />
                                    :
                                    <DataTable table={dashboard.placement_ui} entriesPerPage={false} showTotalEntries={false} title="Performance By Placement" />}
                            </MDBox>
                            <MDBox mt={4} sx={{ borderRadius: "12px", boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.13) !important" }}>
                                {analyticsloading ? <TailSpin height="100" width="100" color="#6471ED" ariaLabel="tail-spin-loading" radius="1" wrapperStyle={{}} wrapperClass="" visible={true} />
                                    :
                                    <DataTable table={dashboard.device_ui} entriesPerPage={false} showTotalEntries={false} title="Device Breakdown" />}
                            </MDBox>
                            <MDBox mt={6} sx={{ borderRadius: "12px", boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.13) !important" }}>
                                {analyticsloading ? <TailSpin height="100" width="100" color="#6471ED" ariaLabel="tail-spin-loading" radius="1" wrapperStyle={{}} wrapperClass="" visible={true} />
                                    :
                                    <DataTable table={dashboard.region_ui} entriesPerPage={{ defaultValue: 5 }} canSearch title="Local Performance" />
                                }
                            </MDBox>
                            <MDBox bgColor="white" display="flex" mt={3} sx={{ borderRadius: "12px 12px 0 0", boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.13) !important" }}>
                                <Grid container sx={{ padding: "16px 16px 0 16px", alignItems: "center", justifyContent: "space-between" }}>
                                    <Grid align="left" item pb={isMobile ? 1 : 0} xs={12} lg={4} md={4}>
                                        <MDTypography variant="h4" color="soliddark">
                                            Filter By Year
                                        </MDTypography>
                                    </Grid>
                                    <Grid align="right" pb={isMobile ? 1 : 0} item xs={12} lg={3} md={2}>
                                        <Autocomplete
                                                onChange={(event, value) => onChangeYearLeads(value)}
                                                value = {YearLeads}
                                                getOptionLabel={(option) => option.toString()}
                                                options={default_data.years}
                                                renderInput={(params) => (
                                                    <FormField {...params} label="Year" InputLabelProps={{ shrink: true }} />
                                                )}
                                            />
                                    </Grid>
                                    <Grid align="right" pb={isMobile ? 2 : 0}mr={3} item xs={12} lg={1} md={1}>
                                        <MDButton variant="gradient" color="solidPrimary" fullWidth={isMobile} pt={3} pb={3} px={3} pl={2} sx={{ fontSize: "0.875rem", textTransform: "capitalize" }} onClick={handleLeadsByMonthChange}>
                                            Apply
                                        </MDButton>
                                    </Grid>
                                </Grid>
                            </MDBox>
                            {yearlyleadsloading ? <TailSpin height="100" width="100" color="#6471ED" ariaLabel="tail-spin-loading" radius="1" wrapperStyle={{}} wrapperClass="" visible={true} />
                                :
                            <MDBox mb={3}>
                                <DefaultLineChart
                                    title="Leads By Month"
                                    description="See how lead volume is trending month-to-month"
                                    chart={dashboard.leads_by_month}
                                />
                            </MDBox>}
                            <MDBox bgColor="white" display="flex" mt={3} sx={{ borderRadius: "12px 12px 0 0", boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.13) !important" }}>
                                <Grid container sx={{ padding: "16px 16px 0 16px" ,alignItems: "center", justifyContent: "space-between" }}>
                                    <Grid align="left" item pb={isMobile ? 1 : 0} xs={12} lg={4} md={4}>
                                        <MDTypography variant="h4" color="soliddark">
                                            Filter By Month
                                        </MDTypography>
                                    </Grid>
                                    <Grid align="right" pb={isMobile ? 1 : 0} item xs={12} lg={3} md={2}>
                                    <Autocomplete
											onChange={(event, value) => onChangeMonth(value)}
											value = {currentMonthName}
											options={default_data.months}
											renderInput={(params) => (
												<FormField {...params} label="Month" InputLabelProps={{ shrink: true }} />
											)}
										/>

                                    </Grid>
                                    <Grid align="right" pb={isMobile ? 1 : 0} item xs={12} lg={3} md={2}>
                                    <Autocomplete
											onChange={(event, value) => onChangeYear(value)}
											value = {currentYearLeads}
                                            getOptionLabel={(option) => option.toString()}
											options={default_data.years}
											renderInput={(params) => (
												<FormField {...params} label="Year" InputLabelProps={{ shrink: true }} />
											)}
										/>

                                    </Grid>
                                    <Grid align="right" pb={isMobile ? 2 : 0}mr={3} item xs={12} lg={1} md={1}>
                                        <MDButton variant="gradient" color="solidPrimary" fullWidth={isMobile} pt={3} pb={3} px={3} pl={2} sx={{ fontSize: "0.875rem", textTransform: "capitalize" }} onClick={handleLeadsByDayChange}>
                                            Apply
                                        </MDButton>
                                    </Grid>
                                </Grid>
                            </MDBox>
                            {monthlyleadsloading ? <TailSpin height="100" width="100" color="#6471ED" ariaLabel="tail-spin-loading" radius="1" wrapperStyle={{}} wrapperClass="" visible={true} />
                                :
                                <MDBox mb={3}>
                                    <LineChartLeadsMonth
                                        title="Leads By Day"
                                        description="See how lead volume is trending day-to-day"
                                        chart={leadsbymonth}
                                    />
                                </MDBox>}
                        </>
                </>
            }
        </>
    );
}

export default InfluencerWidget;
