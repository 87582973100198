import React from 'react';

const Title = ({ className, hTag, text, ...rest }) => {
  const Tag = hTag || 'h1';

  return (
      <Tag className={`text-[#283567] not-italic font-bold leading-[125%] ${className}`} {...rest}>
        {text}
      </Tag>
    );
};

export default Title;