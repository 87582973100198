import React from 'react';
import { FormControl, FormControlLabel, RadioGroup, Radio } from '@mui/material';

const HomeType = ({ formData, handleChange, errors }) => (
  <div className="flex flex-col space-y-2">
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="hometype"
        name="hometype"
        value={formData.hometype}
        onChange={handleChange}
      >
        <FormControlLabel
          value="SINGLE_FAMILY"
          control={<Radio />}
          label={<div className="text-[#7E85A3] text-[14px] not-italic font-normal leading-[20px]">Single Family</div>}
        />
        <FormControlLabel
          value="LAND_OR_LOT"
          control={<Radio />}
          label={<div className="text-[#7E85A3] text-[14px] not-italic font-normal leading-[20px]">Land or Lot</div>}
        />
        <FormControlLabel
          value="CONDO"
          control={<Radio />}
          label={<div className="text-[#7E85A3] text-[14px] not-italic font-normal leading-[20px]">Condo</div>}
        />
        <FormControlLabel
          value="MOBILE_HOME"
          control={<Radio />}
          label={<div className="text-[#7E85A3] text-[14px] not-italic font-normal leading-[20px]">Mobile Home</div>}
        />
        <FormControlLabel
          value="TOWNHOME"
          control={<Radio />}
          label={<div className="text-[#7E85A3] text-[14px] not-italic font-normal leading-[20px]">Townhome</div>}
        />
        <FormControlLabel
          value="COMMERCIAL"
          control={<Radio />}
          label={<div className="text-[#7E85A3] text-[14px] not-italic font-normal leading-[20px]">Commercial</div>}
        />
      </RadioGroup>
      {errors.hometype && (
          <div className="mt-1 text-sm text-red-500">{errors.hometype}</div>
        )}
    </FormControl>
  </div>
);

export default HomeType;
