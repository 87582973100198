import React from 'react';
import ButtonLink from 'components/global/buttonLink';

const Banner = ( {onClose} ) => {
  return (
    <div className="Alert absolute z-[9999] flex flex-col sm:flex-row max-w-[1000px] w-[296px] sm:w-full top-[28px] rounded-[8px] bg-[#6471ED] [box-shadow:0px_8px_16px_-8px_rgba(0,_0,_0,_0.25),_0px_12px_26px_-5px_rgba(0,_0,_0,_0.20)]">
      <div className="flex justify-center items-start gap-[8px] sm:flex-row sm:items-center p-[16px] sm:gap-[16px]">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="shrink-0">
          <path fillRule="evenodd" clipRule="evenodd" d="M6.67271 1.91047C4.41402 2.49139 2.7454 4.5408 2.7454 6.98163V10.4722C2.7454 10.794 2.48409 11.0546 2.16424 11.0546C1.52115 11.0546 1 11.5769 1 12.2183C1 12.8609 1.5219 13.3818 2.16639 13.3818H13.7971C14.4413 13.3818 14.9635 12.8597 14.9635 12.2183C14.9635 11.5755 14.4399 11.0546 13.7992 11.0546C13.4782 11.0546 13.2181 10.7959 13.2181 10.4722V6.98163C13.2181 4.54178 11.5497 2.49164 9.29077 1.91047V1.30939C9.29077 0.584484 8.70456 0 7.98162 0C7.26009 0 6.67266 0.586204 6.67266 1.30939L6.67271 1.91047ZM5.94541 13.9638H10.0181C10.0181 15.0885 9.10629 16 7.98167 16C6.85705 16 5.94547 15.0884 5.94547 13.9638H5.94541Z" fill="white"/>
        </svg>
        <div className="text-[#FFF] flex-[1_0_0] text-[14px] not-italic font-normal leading-[140%]"><span>We've introduced </span><span className="font-bold">subscription tiers.</span> After October 18, referrals matched on our free plan will follow a new 40% fee structure. Upgrade your plan to lock in lower referral fees and access exclusive benefits.</div>
        <div className="ButtonTallFlexiblePrimaryBlack hidden sm:flex px-[24px] py-[12px] justify-center items-center gap-[10px]">
          <ButtonLink
            text="View Plans"
            href="/my-plan"
            className="text-sm w-[fit-content] font-bold leading-tight text-right text-white grow shrink basis-0 px-6 py-3 bg-[#434ca0] hover:bg-[#373E82] rounded-lg justify-center items-center gap-2.5 flex"
          />
        </div>
        <div className="Close w-[14px] flex-shrink-0 cursor-pointer" onClick={onClose}>
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" >
            <path d="M2 2.20117L12 12.2012" stroke="white" strokeWidth="2"/>
            <path d="M12 2.20117L2 12.2012" stroke="white" strokeWidth="2"/>
          </svg>
        </div>
      </div>
      <div className="ButtonTallFlexiblePrimaryBlack sm:hidden flex px-[24px] py-[12px] justify-center items-center gap-[10px]">
        <ButtonLink
          text="View Plans"
          href="/my-plan"
          className="text-sm w-[fit-content] font-bold leading-tight text-right text-white grow shrink basis-0 px-6 py-3 bg-[#434ca0] hover:bg-[#373E82] rounded-lg justify-center items-center gap-2.5 flex"
        />
      </div>
    </div>
  );
};

export default Banner;