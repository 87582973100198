// Material Dashboard 2 PRO React layouts
import ProfileOverview from "layouts/pages/profile/profile-overview";
import SignInBasic from "layouts/authentication/sign-in/basic";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-3.jpg";

const blankroutes = [
    {
        name: "user_data",
        type: "collapse",
        key: "user_data",
        icon: <MDAvatar src={profilePicture} alt="profile Image" size="sm" />,
        collapse: [
            {
                name: "Edit Profile",
                key: "profile",
                route: "/profile",
                component: <ProfileOverview />,
            },
            {
                name: "Logout",
                key: "logout",
                route: "",
                component: <SignInBasic />,
            },
        ],
    },
    { type: "divider", key: "divider-0" },
    {
        type: "collapse",
        name: "Dashboard",
        key: "dashboard",
        icon: <Icon fontSize="medium">home</Icon>,
        route:"/dashboard",
        noCollapse: true,
    },
    { type: "divider", key: "divider-1" },
];

export default blankroutes;
