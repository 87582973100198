import { useState, useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 PRO React routes
import routes from "routes";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { RequireAuth } from "react-auth-kit";

import Analytics from "layouts/dashboards/analytics";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import Timeline from "layouts/pages/projects/timeline";
import Widgets from "layouts/pages/widgets";
import DataTables from "layouts/applications/data-tables";
import DataTablesOutbound from "layouts/applications/data-tables-outbound";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignUpCover from "layouts/authentication/sign-up/cover";
import AgentHeroSignup from "layouts/authentication/sign-up/agenthero";
import Agree from "layouts/agreements/referral";
import RemaxTerms from "layouts/agreements/remax";
import Contact from "layouts/contact";
import Duplicate from "layouts/duplicate";
import Userprofile from "layouts/user_profile/index";
import Forgot from "layouts/authentication/reset-password/cover";
import Update from "layouts/authentication/reset-password/update";
import NotFound from "layouts/authentication/not-found";
import Oppurtunity from "layouts/pages/oppurtunity";
import BrandToolKit from "layouts/brandtoolkit";
import PortalWalkThrough from "layouts/portal/walkthrough";

import { useIsAuthenticated } from "react-auth-kit";
import AuthService from "services/auth.service";
import sales_split_routes from "sales_split_routes";
import blankroutes from "blank_routes";
import cpl_routes from "cpl_routes";
import merged_routes from "merged_routes";
import ContactUs from "layouts/contactus";
import AgentheroBasic from "layouts/authentication/sign-in/agenthero_basic";
import Partners from "layouts/authentication/sign-up/partners";

import PlanPage from "layouts/pages/plan";
import NoAccessPage from "layouts/pages/plan/noAccess";
import PlanSubmitted from "layouts/pages/plan/planSubmitted";
import AddReferral from "layouts/pages/addReferral";

import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "store/userSlice";

import default_data from "values/default";

export default function App() {

	const dispatch = useDispatch();
	const [Userdata, setUserdata] = useState({});
	const isAuthenticated = useIsAuthenticated();

	const { data: allData, isDataLoaded } = useSelector((state) => state.data);

	useEffect(() => {
		if (!isDataLoaded && isAuthenticated()) {
			AuthService.getUserInfo().then((response) => {
				setUserdata(response.data.response);
				dispatch(setUserData(response.data.response));

				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({
					event: 'login',
					id: response.data.response.agent.id,
					sfid: response.data.response.agent.sfid,
					public_id: response.data.response.agent.public_id
				});
			})
		}
	}, [isAuthenticated, dispatch, isDataLoaded]);

	const [controller, controllerDispatch] = useMaterialUIController();
	const {
		miniSidenav,
		direction,
		layout,
		sidenavColor,
		transparentSidenav,
		whiteSidenav,
		darkMode,
	} = controller;
	const [onMouseEnter, setOnMouseEnter] = useState(false);
	const { pathname } = useLocation();

	// Open sidenav when mouse enter on mini sidenav
	const handleOnMouseEnter = () => {
		if (miniSidenav && !onMouseEnter) {
			setMiniSidenav(controllerDispatch, false);
			setOnMouseEnter(true);
		}
	};

	// Close sidenav when mouse leave mini sidenav
	const handleOnMouseLeave = () => {
		if (onMouseEnter) {
			setMiniSidenav(controllerDispatch, true);
			setOnMouseEnter(false);
		}
	};

	// Setting the dir attribute for the body element
	useEffect(() => {
		document.body.setAttribute("dir", direction);
	}, [direction]);

	// Setting page scroll to 0 when changing the route
	useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
	}, [pathname]);

	return (
		<ThemeProvider theme={darkMode ? themeDark : theme}>
			<CssBaseline />
			{layout === "dashboard" && isDataLoaded && (
				<Sidenav
					color={sidenavColor}
					brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
					brandName="MyAgentFinder"
					routes={Object.keys(Userdata).length > 0 ? Userdata.agent.is_influencer ? Userdata.agent.is_sales_split ? Userdata.agent.merged_influencer_agent? merged_routes :  sales_split_routes : cpl_routes : routes : blankroutes}
					onMouseEnter={handleOnMouseEnter}
					onMouseLeave={handleOnMouseLeave}
				/>
			)}
			<Routes>
				<Route path="/" element={isAuthenticated() ? <Navigate to="/dashboard" /> : <SignInBasic setUserdata={setUserdata} />} />
				<Route path="/login" exact element={isAuthenticated() ? <Navigate to="/dashboard" /> : <SignInBasic setUserdata={setUserdata} />} />
				<Route path="/agenthero" element={isAuthenticated() ? <Navigate to="/dashboard" /> : <AgentheroBasic setUserdata={setUserdata} />} />
				<Route path="/register" exact element={<SignUpCover />} />
				<Route path="/register/agenthero" exact element={<AgentHeroSignup />} />
				<Route path="/partners" exact element={<Partners/>}/>
				<Route path="/forgot" exact element={<Forgot />} />
				<Route path="/reset-password" exact element={<Update/>}/>
				<Route path="/agree" exact element={<Agree />} />
				<Route path="/maxtech-referral-agreement" exact element={<RemaxTerms />} />
				<Route path="/docs/terms-and-conditions" exact element={<Agree />} />
				<Route path="/docs/referral-agreement" exact element={<Agree />} />
				<Route path="/docs/referral-agreement.html" exact element={<Agree />} />
				<Route path="/contact" exact element={<Contact />} />
				<Route path="/reportduplicate" exact element={<Duplicate />} />

				<Route path="/contactus" exact element={
					<RequireAuth loginPath="/">
						<ContactUs />
					</RequireAuth>
				} />
				<Route path="/brandtoolkit" exact element={
					<RequireAuth loginPath="/">
						<BrandToolKit />
					</RequireAuth>
				} />
				<Route path="/portal/walkthrough" exact element={
						<RequireAuth loginPath="/">
								<PortalWalkThrough userdata={Userdata} />
						</RequireAuth>
				} />

				<Route path="/dashboard" exact element=
						{
								<RequireAuth loginPath='/'>
										<Analytics userdata={Userdata} />
								</RequireAuth>
						}>
				</Route>
				<Route path="/pages/profile/profile-overview" exact element=
						{
								<RequireAuth loginPath='/'>
										<ProfileOverview />
								</RequireAuth>
						}>
				</Route>
				<Route path="/pages/profile/all-projects" exact element=
						{
								<RequireAuth loginPath='/'>
										< Timeline />
								</RequireAuth>
						}>
				</Route>
				<Route path="/profile" exact element=
					{
						<RequireAuth loginPath='/'>
							{ isDataLoaded && < Userprofile /> }
						</RequireAuth>
					}>
				</Route>
				<Route
					path="/my-plan"
					exact
					element={
						<RequireAuth loginPath='/'>
							{isDataLoaded ? (
								Userdata?.agent?.is_influencer || Userdata?.agent?.is_canadian ? <NoAccessPage /> : <PlanPage />
							) : null}
						</RequireAuth>
					}
				/>
				<Route path="/plan-submitted" exact element=
					{
						<RequireAuth loginPath='/'>
							{ < PlanSubmitted /> }
						</RequireAuth>
					}>
				</Route>
				{ isDataLoaded && allData?.agent?.subscription_status !== default_data.subscription_status.canceled && allData?.agent?.subscription_plan !== default_data.subscription_plans.free_profile &&
					<Route path="/add-referral" exact element=
						{
							<RequireAuth loginPath='/'>
								< AddReferral />
							</RequireAuth>
						}>
				</Route>
				}
				<Route path="/referral/:index/:state" exact element=
						{
								<RequireAuth loginPath='/'>
										< DataTables userdata={Userdata} />
								</RequireAuth>
						}>
				</Route>
				<Route path="/out-bound/:index/:state" exact element=
						{
								<RequireAuth loginPath='/'>
										< DataTablesOutbound userdata={{}} />
								</RequireAuth>
						}>
				</Route>
				<Route path="/opp/:id" exact element=
						{
								<RequireAuth loginPath='/'>
										<Widgets userdata={Userdata} />
								</RequireAuth>
						}>
				</Route>
				<Route path="/outbound/:id" exact element=
						{
								<RequireAuth loginPath='/'>
										<Oppurtunity />
								</RequireAuth>
						}>
				</Route>
				<Route path={"/*"} element={<NotFound />}></Route>
				<Route path={"/referral"} exact element={<NotFound />}></Route>
				<Route path={"/opp"} exact element={<NotFound />}></Route>
			</Routes>
		</ThemeProvider>
	);
}
