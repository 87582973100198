import default_data from "values/default";

export const makeCompanyDetails = (data) => {
  const stateObj = default_data.states.find(state => state.abbr === data.other_state);
  let companydata = {};
  companydata.company_name = data.company_name ? data.company_name : '';//company_name
  companydata.broker_name = data.broker_name ? data.broker_name : '';
  companydata.broker_email = data.broker_email ? data.broker_email : '';
  companydata.broker_phone = data.broker_phone ? data.broker_phone : '';
  companydata.license_date = data.license_date ? data.license_date : '';
  companydata.main_phone = data.main_phone ? data.main_phone : '';
  companydata.license = data.license ? data.license : '';
  companydata.coordinator_email = data.coordinator_email ? data.coordinator_email : '';
  companydata.coordinator_phone = data.coordinator_phone ? data.coordinator_phone : '';
  companydata.coordinator_name = data.coordinator_name ? data.coordinator_name : '';
  companydata.office_assistant_email = data.office_assistant_email ? data.office_assistant_email : '';
  companydata.office_assistant_phone = data.office_assistant_phone ? data.office_assistant_phone : '';
  companydata.office_assistant_name = data.office_assistant_name ? data.office_assistant_name : '';
  companydata.other_street = data.other_street ? data.other_street : '';
  companydata.other_city = data.other_city ? data.other_city : '';
  companydata.other_state = stateObj ? stateObj.name : '';
  companydata.other_postalcode = data.other_postalcode ? data.other_postalcode : '';
  return companydata;
};